.block-related {
  position: relative;

  @extend .t1;
}

.block-related__content {
  text-align: center;

  @include bp('md') {
    text-align: left;
  }
}

.block-related__icon {
  height: 18rem;
  margin-top: -4rem;
  margin-bottom: -4rem;

  @include bp('md') {
    height: 32rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.block-related__pic,
.block-related__bg-color {
  .block-related__icon {
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: 0;
    color: $color-white;
  }
}

.block-related__bg-color {
  background-color: transparent;
  height: auto;
  position: relative;
  overflow: hidden;
  &::before {
    padding-top: 66.666666666666667%;
    display: block;
    content: '';
  }
}

.block-related__image-container {
  text-align: center;
  position: relative;
  margin: 0 -20px;
  width: auto;

  @include bp('md') {
    margin: 0 8px 0 0;
  }
}

.block-related__container {
  padding-top: 2rem;

  @include bp('md') {
    padding-top: 0;
    margin-left: 8px;
  }

  .link-list {
    vertical-align: middle;

    @include bp('md') {
      text-align: left;
    }
  }

  .link-list__item {
    & + .link-list__item {

      &:before {
        margin: 2rem auto;
        border-top-color: inherit;

        @include bp('md') {
          margin: 2rem 0;
        }
      }
    }

    .item-link {
      @extend .h3;
    }
  }
}

.block-related__tagline {
  margin-bottom: 0.9rem;
  display: inline-block;
}

.block-related__headline {
  @extend .h2;
  margin-bottom: 3.2rem;

  a,
  a:hover,
  a:focus {
    border: none;
  }
}


/* Brand Colors */
.context-booking {
  .block-related {
    .link-list__item {
      & + .link-list__item {
        &:before {
          border-top-color: $color-brand-booking;
        }
      }
    }
  }
}

.context-highlight {
  .block-related {
    .link-list__item {
      & + .link-list__item {
        &:before {
          border-top-color: $color-brand-highlight;
        }
      }
    }
  }
}

.context-poi {
  .block-related {
    .link-list__item {
      & + .link-list__item {
        &:before {
          border-top-color: $color-brand-poi;
        }
      }
    }
  }
}

.context-inspiration {
  .block-related {
    .link-list__item {
      & + .link-list__item {
        &:before {
          border-top-color: $color-brand-inspiration;
        }
      }
    }
  }
}

// ToDo: check if old color-context is still necessary
.block-related--booking {
  .block-related__bg-color {
    background-color: $color-brand-booking;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-booking;
      }
    }
  }

  .label--booking{
    background: $color-brand-booking;
    position:absolute;
    right:0;
    top:2rem;
    padding: 0.7rem 2rem 0.3rem;
  }


  .divider {
    @extend .divider--booking;
  }
}

.block-related--inspiration {
  .block-related__bg-color {
    background-color: $color-brand-inspiration;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-inspiration;
      }
    }
  }

  .label--booking{
    background: $color-brand-inspiration;
    color:#000000;
    position:absolute;
    right:0;
    top:2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--inspiration;
  }
}

.block-related--poi {
  .block-related__bg-color {
    background-color: $color-brand-poi;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-poi;
      }
    }
  }

 .label--booking{
   background: $color-brand-poi;
   position:absolute;
   right:0;
   top:2rem;
   padding: 0.7rem 2rem 0.3rem;
 }


  .divider {
    @extend .divider--poi;
  }
}

.block-related--highlight {
  .block-related__bg-color {
    background-color: $color-brand-highlight;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-highlight;
      }
    }
  }

  .label--booking{
    background: $color-brand-highlight;
    position:absolute;
    right:0;
    top:2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--highlight;
  }
}
