// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Kill the scroll on the body
.modal-open {
  overflow: hidden;
  height: 100% !important;
}

// Container that the modal scrolls within
.loadingoverlay {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    transition: all .2s linear;
    transform: translate(0, 100%);
  }
  &.show .modal-dialog { transform: translate(0, 0); }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  height: 100%;
  margin: $modal-dialog-margin;

  .modal-header {
    display: block;
    border-bottom: none;
    text-align: center;
  }

  .modal-title {
    font-weight: 700;
    font-size: 2.2rem;

    @include bp('md') {
      font-size: 2.4rem;
    }
  }
}

.modal--map {
  .modal-dialog {
    margin: 0;
    height: 100%;
    .modal-content {
      height: 100%;
    }
  }
  .modal-header {
    @include bp('md') {
      padding: 2rem 0rem 2rem;
    }
  }
}

.modal--booking {
  .modal-dialog {
    margin: 0;
    height: 100%;

    @include bp('md') {
      height: auto;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      top: auto;
      width: 500px;
      margin: 0 auto;
    }

    .modal-content {
      height: 100%;

      @include bp('md') {
        box-shadow: 0 0px 15px 0px #888888;
        border-bottom: 0;
      }
    }
  }
}

.modal--overlay {
  .modal-body {
    background-color: transparent;
    overflow-y: auto;

    @include bp('md') {
      overflow-y: inherit;
    }
  }

  .modal-dialog {
    padding: 0;
    margin: 0;

    @include bp('md') {
      height: auto;
      bottom: auto;
      right: auto;
      left: auto;
      top: auto;
      min-height: calc(100% - 30px);
      width: 810px;
      margin: 1.75rem auto;
      display: flex;
      align-items: center;

      &::before {
        height: calc(100vh - 300px);
      }
    }
  }

  .modal-content {
    height:100%;
    background-color: $color-umbra-bright;

    @include bp('md') {
      width: 100%;
      background-color: $color-white;
      box-shadow: 0 4px 15px 0px #888888;
    }
  }

  .modal-footer {
    background-color: $color-umbra-bright;
    border: none;

    @include bp('md') {
      background-color: $color-white;
    }
  }
}

.modal--calendar {
  .calendar-container {
    max-width: 580px;
    margin: 0 auto;
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $modal-content-bg;
  background-clip: padding-box;



  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $color-white;
  transition: opacity .2s linear;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: 0.8; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: center; // vertically center it
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: 2rem 2rem;
  border-bottom: 1px solid $color-umbra-dark;

  @include bp('md') {
    padding: 3rem 0rem 0rem;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1.5rem;

    .icon {
      font-size: 3rem;

      @include bp('md') {
        font-size: 4rem;
      }
    }
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: 1;
  font-size: 2.1rem;
  font-weight: 300;
  .icon {
    top: -0.2rem;
  }
  .icon--map-point {
    font-size: 3rem;
    display: inline-block;
    margin-left: -1rem;
  }


}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  flex-grow: 1;
  padding: 1rem 2rem 3rem;

  @include bp('md') {
    padding: 1rem 3rem 3rem;
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: center; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 3rem;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  // Easily place margin between footer elements
  > :not(:first-child) { margin-left: .25rem; }
  > :not(:last-child) { margin-right: .25rem; }

  @include bp('md') {
    padding: 0rem 2rem 3rem;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

