.tag-list {
  margin-top: 3rem;

  .tag {
    display: inline-block;
    background-color: $color-umbra-medium;
    color: $white;
    padding: 0.9rem 1rem 0.6rem 1rem;
    margin: 0 1.5rem 1.5rem 0;
    line-height: 1;
    @extend .s2;

    a {
      cursor: pointer;
      display: inline-block;
      margin-right: 0.7rem;
      color: $white;

      &:focus .icon {
        color: $color-umbra-dark;
      }
    }

    .icon {
      top: -0.25rem;
    }

    &.clearall {
      background-color: $color-umbra-bright;
      color: $color-umbra-medium;

      .icon {
        color: $color-umbra-medium;
      }
    }
  }
}
