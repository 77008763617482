.footer-global {
  margin-bottom: 4.0rem;

  @extend .t1;

  @include bp('md') {
    margin-bottom: 4.4rem;
  }

  .link,
  .content-box a {
    border-bottom: 1px solid $color-umbra-dark;
  }
}

.nav-footer-links {
  list-style: none;
  margin: 0;
  padding: 0;

  &.nav-footer-links--inline {
    .nav-footer-links__item {
      display: inline-block;
      margin: 0 0.6rem;
    }
  }
}

.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;


  @include bp('md') {
    justify-content: center;
  }
}

.social-links__item {
  margin: 1rem 1.5rem 0;

  @include bp('md') {
    margin: 1rem 1.5rem 0;
  }

  .social-link {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $color-umbra-medium;
    border: none !important;

    .icon {
      line-height: 4rem;
      top: 0;
      display: block;
      font-size: 3rem;
      color: $color-white;
    }

    &--facebook {
      background-color: #3B5798;
    }

    &--instagram {
      background-color: #000000;
    }

    &--youtube {
      background-color: #D8362A;
    }

    &--twitter {
      background-color: #55ACEE;
    }

    &--pinterest {
      background-color: #C8232C;
    }

    &--vimeo {
      background-color: #C8232C;
    }

    &--linkedin {
      background-color: #2867b2;
    }
  }
}

.footer-global__content {
  padding: 3rem 0;
  background-color: $color-umbra-bright;
}

.content-box {
  padding: 1rem 0 2rem;
  text-align: center;
}

.content-box__headline {
  color: $color-umbra-dark;
  line-height: $line-height-base;
  font-size: $font-size-base;
  font-weight: 700;
  margin-bottom: 0;
}

.footer-nav__list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-nav__item {
  width: 50%;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.footer-nav__link {
  color: $color-umbra-bright;
  text-decoration: none;
  display: block;

  &:hover {
    color: darken($color-umbra-bright, 50%);
  }
}

.footer-global__logos {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;

  @include bp('md') {
    text-align: left;
  }

  .logo-global {
    display: inline-block;

    a {
      margin: 0;
      height: 4.2rem;
    }

    &:first-child {
      margin-right: 3rem;

      @include bp('md') {
        margin-right: 4rem;
      }
    }
  }

  .row:last-child {
    align-items: flex-end;
  }
}

.logo-global--logo-muc-color {
  a {
    width: 10rem;
    background-position: left bottom;
  }
}

.logo-global--logo-muc-black {
  a {
    background-image: url('../images/logo-muc-black.svg');
    background-position: left bottom;
    width: 17rem;
  }
}

.copyright {
  @extend .s2;
  text-align: center;
  margin-bottom: 0;
  margin-top: 2rem;

  @include bp('md') {
    text-align: right;
    margin-top: 0;
  }
}

/*Browserhack IE 10 > */
@media screen and (min-width:0\0) {
  .logo-global--logo-muc-black a {
    background-position: left 0.6rem;
  }
}

.footer-global__toplink {
  background-color: $color-umbra-bright;
  text-align: center;
  padding: 2.5rem;
  font-weight: 400;

  @include bp('md') {
    padding: 3rem;
  }
}

.link-to-top {
  display: inline-block;

  .icon {
    &:before {
      top: -0.2rem;
      @include bp('md') {
        top: 0.3rem;
      }
    }


    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
  }

  .link-text {
    display: inline-block;
    vertical-align: middle;
  }
}
