.block-options {
  position: relative;
}

.block-options__item {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }

  @include bp('sm') {
    flex-direction: row;
  }

  .field-checkbox, .field-radiobox {
    flex: auto;
    margin-bottom: 0;

    @include bp('md') {
      flex: 1;
    }
  }

  .field-checkbox, .field-radiobox {
    small {
      padding-left: 5rem;
    }

    &.subtext-inline {
      @include bp('md') {
        label {
          vertical-align: inherit;
          display: inline-block;
        }

        small {
          padding-left: 0;
        }
      }
    }
  }

  small {
    display: inline-block;
    margin: 0;
  }
}

.block-options__link {
  align-self: flex-start;
  padding-left: 5rem;
  margin: 0.5rem 0 0.5rem 0;
  @extend .s2;
  color: $color-umbra-medium;

  @include bp('md') {
    margin: 0;
  }
}
