.block-share--block {
  @extend .clearfix;
  .block-share__button {
    float: right;
  }

  .dropdown-menu {
    right: 0;
    left: auto !important;
  }
}

.block-share--float {
  .block-share__button {
    float: right;
  }

  .dropdown-menu {
    right: 0;
    left: auto !important;
  }
}

.block-share--divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4rem 8rem;

  &:before,
  &:after {
    content: "";
    border-top: solid 2px $color-umbra-medium;
    flex-grow: 1;
    height: 0;
    display: block;
  }

  .dropdown-menu {
    left: -1.7rem;
    right: auto !important;
  }

  .block-share__content {
    margin: 0 3rem;
  }
}
