/* List Style unordered, ordered */
.list {
  padding-left: 0;

  span {
    color: $color-umbra-dark;
  }
}

.list--ordered {
  padding-left: 3.6rem;

  @include bp('sm') {
    padding-left: 6.6rem;
  }

  @include bp('md') {
    padding-left: 9rem;
  }

  li {
    text-indent: 1rem;
  }

  &.list--booking {
    li {
      color: $color-brand-booking;
    }
  }

  &.list--inspiration {
    li {
      color: $color-brand-inspiration;
    }
  }

  &.list--poi {
    li {
      color: $color-brand-poi;
    }
  }

  &.list--highlight {
    li {
      color: $color-brand-highlight;
    }
  }
}

.list--unordered {
  list-style: none;

  li {
    padding-left: 2rem;
    position: relative;
  }

  li:before {
    content: "\2013";
    margin-left: -2rem;
    display: inline-block;
    width: 2rem;
  }

  &.list--booking {
    li:before {
      color: $color-brand-booking;
    }
  }

  &.list--inspiration {
    li:before {
      color: $color-brand-inspiration;
    }
  }

  &.list--poi {
    li:before {
      color: $color-brand-poi;
    }
  }

  &.list--highlight {
    li:before {
      color: $color-brand-highlight;
    }
  }
}
