//
// Typography
//

body {
  font-family: $font-family-brand, Arial, sans-serif;
  font-size: $font-size-base;
  font-weight: 300;
  font-style: normal;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  // Go easy on the eyes and use something other than `#000` for text
  color: $body-color;
  // By default, `<body>` has no `background-color` so we set one as a best practice.
  background-color: $body-bg;

  @include bp('md') {
    font-size: 2rem;
    line-height: 1.8;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-weight: 400;
  font-style: normal;
  line-height: $headings-line-height;
  color: $headings-color;

  a {
    color: inherit;
    text-decoration: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid $color-umbra-dark;
    }
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  line-height: 1.14;
  font-weight: 700;

  @include bp('md') {
    font-size: 3.8rem;
    line-height: 1.2;
  }

  @include bp('xl') {
    font-size: 6rem;
    line-height: 1.2;
  }

  &.h1-small {
    font-size: 3.0rem;
    margin-bottom: 2rem;

    @include bp('md') {
      font-size: 3.4rem;
    }
  }
}

h2, .h2 {
  font-size: $font-size-h2;
  font-weight: 700;
  line-height: 1.16;
  margin-bottom: 2rem;

  @include bp('md') {
    font-size: 3rem;
  }

}

h3, .h3 {
  font-size: $font-size-h3;
  font-weight: 700;
  line-height: 1.11;
}

h4, .h4 {
  font-size: $font-size-h4;
  font-weight: 700;
  line-height: 1.22;
}

h5, .h5 {
  font-size: $font-size-h5;
  font-weight: 400;
  line-height: $line-height-base;

  @include bp('md') {
    font-size: 2rem;
  }
}

h6, .h6 {
  font-size: $font-size-h6;
  font-weight: 400;
  line-height: $line-height-base;
  margin: -4rem 0 4rem;
  text-align: right;

  @include bp('md') {
    margin: -6rem 0 4rem;
    padding-left: 50%;
  }
}

.b1 {
  font-size: $font-size-base;
  font-weight: 700;
  line-height: $line-height-base;

  @include bp('md') {
    font-size: 2.0rem;
  }
}

.b2 {
  font-size: $font-size-h4;
  font-weight: 700;
  line-height: $line-height-base;

  @include bp('md') {
    font-size: 1.8rem;
  }

  @include bp('lg') {
    font-size: 2.0rem;
  }
}

.a1 {
  font-size: 1.8;
  font-weight: 700;
  @include bp('md') {
    font-size: 2.2rem;
    line-height: 1.86;
  }
}

.a2 {
  font-size: $font-size-a1;

  @include bp('lg') {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.t1 {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.s2 {
  font-size: $font-size-s2;
  font-weight: 300;

  @include bp('md') {
    font-size: 1.7rem;
  }
}

.s4 {
  font-size: $font-size-s4;
  font-weight: 700;
}

small {
  @extend .s2;
}

.text-muted {
  color: $color-umbra-medium;
}

.list {
  margin-bottom: 1.8rem;
}
