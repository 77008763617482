.block-infobox {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  &.block-infobox--bg-color {
    background-color: $color-umbra-bright;
    padding: 2rem;

    @include bp('md') {
      padding: 3rem;
    }

    input,
    textarea,
    select {
      background-color: $color-white;
    }

    .block-infobox__label {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0.2rem;

      @include bp('md') {
        font-size: 16px;
      }
    }

    .block-infobox__headline {
      margin-bottom: 2.8rem;

      &,
      & label {
        @extend .h4;
      }
    }
  }

  &.block-infobox--bordered {
    @extend .block-bordered;
    padding: 2rem;

    @include bp('md') {
      padding: 3rem;
    }
  }

  small {
    margin-bottom: 3rem;
    display: block;
  }

  .field-checkbox {
    margin-bottom: 0;
  }

  .options-counter {
    padding-left: 5rem;
  }

  .field {
    label {
      font-weight: 400;
    }
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.block-infobox__headline {
  margin-bottom: 2.2rem;

  &,
  & label {
    @extend .h3;
  }
}

.block-infobox__icon {
  align-self: flex-end;

  a {
    &:hover, &:focus {
      outline: none;

      .icon {
        background-color: $color-umbra-dark;
        color: $color-white;
      }
    }
  }
}

.block-infobox__checkbox {
  width: 100%;
  margin: 3rem 0 2rem 0;
  display: flex;
  flex: auto;
  justify-content: flex-end;
  flex-flow: column wrap;

  .field {
    margin: 0;
  }
}


