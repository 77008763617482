.slider-intro {
  opacity: 0;
  transition: opacity 0.2s ease-in;

  margin-bottom: ($spacer-small-xs) !important;

  @include bp('md') {
    margin-bottom: ($spacer-small-md) !important;
  }

  .slick-arrow {
    z-index: 999;
    color: #fff;
    background-color: transparent;
    position: absolute;
    overflow: hidden;
    opacity: .5;
    top: 50%;
    border: none;
    width: 5rem;
    height: 8rem;
    vertical-align: middle;
    margin-top: -5rem;
    cursor: pointer;

    .icon {
      font-size: 8rem;
      color: #fff;
    }

    &.slick-prev {
      left: 0;

      //@include bp('md') {
      //  left: 1rem;
      //}
      //
      //@include bp('lg') {
      //  left: 2rem;
      //}

      .icon {
        margin-left: -2rem;
      }
    }

    &.slick-next {
      right: 0;

      //@include bp('md') {
      //  right: 1rem;
      //}
      //
      //@include bp('lg') {
      //  right: 0rem;
      //}

      .icon {
        margin-left: -2rem;
      }
    }

    &.slick-hidden {
      display: none;
    }
  }
}



/* Slider */

.slick-slider {
  position: relative;
  display: block;
  opacity: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-initialized {
  opacity: 1;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}




.slick-dots {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
  }

  button {
    color: transparent;
    width: 7px;
    height: 7px;
    margin: 20px 5px;
    line-height: 5px;
    font-size: 10px;
    border: none;
    padding: 0;
    border-radius: 50%;
    background: $color-umbra-medium;
    opacity: 0.5;
  }

  .slick-active {
    button {
      background: $color-umbra-medium;
      opacity: 1;
      width: 10px;
      height: 10px;
    }
  }
}
