.block-figure {
  margin: ($spacer-small-xs) 0;

  @include bp('md') {
    margin: ($spacer-small-md) 0;

    img {
      border-radius: 4px;
    }
  }
}

.figure-caption {
  margin-top: 1rem;
  font-weight: 300;
  font-style: italic;
  span {
    display: block;

    @include bp('sm') {
      margin-right: 0.5rem;
      display: inline-block;
    }
  }

  .text-muted {
    font-weight: 300;
    font-style: italic;
    font-size: 1.4rem;
    @include bp('md') {
      font-size: 1.6rem;
    }
    color: inherit;
  }
}
