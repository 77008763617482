.block-social-media {
  @include bp('md') {
    padding: 1.5rem 0 1.5rem 0;
  }

  .button--icon-only {
    background-color: transparent;
    color: $color-white;
    border: none;
    padding: 0;
    margin: 0 1rem 1rem 0;

    @include bp('md') {
      margin: 0 0.8rem 0 0;
    }

    .icon:before {
      font-size: 4rem;
      line-height: 100%;
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: darken($color-umbra-bright, 50%);
    }

    &:last-child {
      margin: 0;
    }
  }
}
