.block-order {
  position: relative;

  .list-group {
    width: 100%;
  }

  .list-label {
    @include bp('md') {
      max-width: 70%;
    }
  }
}
