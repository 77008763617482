.block-nav-tabs {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 5rem;

  @include bp('md') {
    height: 6.1rem;
  }

  .button-scroll {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3rem;
    border: 0;
    padding: 0;
    margin: 0;
    color: $color-umbra-medium;
    font-size: 3rem;

    &.hidden {
      display: none;
    }

    &:hover,
    &:focus {
      outline: none;
      color: $color-umbra-dark;
    }

    .icon {
      margin-top: -0.4rem;
    }

    &--left {
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
      left: 0;

      .icon {
        margin-left: -1.7rem;
      }
    }

    &--right {
      background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
      right: 0;

      .icon {
        margin-right: -1.5rem;
      }
    }
  }
}

.nav-tabs {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 0;
  padding: 0 0 2rem;
  list-style: none;
  display: flex;
  flex-direction: row;

  .nav-item {
    padding: 1rem 1.5rem;
    display: block;

    &.active,
    &:hover,
    &:focus {
      outline: none;
      border-bottom: 2px solid $color-umbra-medium;
    }
  }

  &--booking {
    .nav-item {
      &.active,
      &:hover,
      &:focus {
        border-bottom-color: $color-brand-booking;
      }
    }
  }

  &--inspiration {
    .nav-item {
      &.active,
      &:hover,
      &:focus {
        border-bottom-color: $color-brand-inspiration;
      }
    }
  }

  &--poi {
    .nav-item {
      &.active,
      &:hover,
      &:focus {
        border-bottom-color: $color-brand-poi;
      }
    }
  }

  &--highlight {
    .nav-item {
      &.active,
      &:hover,
      &:focus {
        border-bottom-color: $color-brand-highlight;
      }
    }
  }
}

.tab-content {
  margin-top: 1.9rem;
}

.tab-pane {
  display: none;

  &.active, &:active {
    display: block;
    opacity: 0;
    transition: all 300ms ease-in;
  }

  &.show {
    opacity: 1;
  }
}

