.block-apple-wallet {
  img {
    height: 7rem;
  }

  @include bp('lg') {
    display: none;
  }
}

.block-apple-wallet__headline {
  font-weight: 700;
  margin-bottom: 3rem;
}
