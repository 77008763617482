.block-confirmation {
  border: 2px solid $color-umbra-dark;
  padding: 2rem;

  @include bp('md') {
    padding: 3rem;
  }

  .button {
    width: 100%;

    @include bp('md') {
      width: auto;
    }
  }
}

.block-confirmation__headline {
  margin-bottom: 2rem;

  @include bp('md') {
    margin-bottom: 4rem;
  }

}

.block-confirmation__label {
  background: $color-white;
  color: $color-black;
  font-weight: 700;
  padding: 0;
}

.block-confirmation__text {
  margin: 3rem 0 3rem;

  @include bp('md') {
    margin: 4rem 0 6rem;
  }
}

.block-confirmation--booking {
  border-color: $color-brand-booking;

  .block-confirmation__headline {
    color: $color-brand-booking;
  }
}
