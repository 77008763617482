.embed-btn {

  &--container {
    margin: 1rem 0 1.8rem;
    display: block;
    position: relative;
  }
  &--link {
    display: inline-block;
    padding: 1rem 3.5rem;
    color: #fff;
    text-decoration: none;
  }

  &--text {
    p{
      margin-bottom:0;
    }
  }

  &--red {
    .embed-btn--link {
      background: #DB0031;
      border: 2px solid #DB0031;

      &:hover {
        background: transparent;
        color: #DB0031;
      }
    }
  }
  &--green {
    .embed-btn--link {
      background: #14A537;
      border: 2px solid #14A537;

      &:hover {
        background: transparent;
        color: #14A537;
      }
    }
  }
  &--blue {
    .embed-btn--link {
      background: #0096C8;
      border: 2px solid #0096C8;

      &:hover {
        background: transparent;
        color: #0096C8;
      }
    }
  }
  &--yellow {
    .embed-btn--link {
      background: #FAB900;
      border: 2px solid #FAB900;
      color: #2D2D28;


      &:hover {
        background: transparent;
        color: #FAB900;
      }
    }
  }
  &--black {
    .embed-btn--link {
      background: #2D2D28;
      border: 2px solid #2D2D28;

      &:hover {
        background: transparent;
        color: #2D2D28;
      }
    }
  }
}

.align-center {
  .embed-btn--container {
    > div {
      display: flex;
      justify-content: center;
    }
  }
}
.align-left {
  .embed-btn--container {
    > div {
      display: flex;
      justify-content: flex-start;
    }
  }
}
.align-right {
  .embed-btn--container {
    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
}
