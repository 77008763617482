.block-quicklinks {
  background-color: $color-umbra-bright;
  margin: 0 -20px;
  padding: 2rem;
  width: auto;

  @include bp('md') {
    display: none;
  }
}

.nav-quicklinks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-quicklinks__item {
  display: block;
  text-align: center;
}

.quicklink-link {
  display: inline-block;
  padding: 0.7rem;

  .icon {
    line-height: 2.3rem;
    font-size: 3rem;
    margin-right: 0.6rem;

    &--map-point {
      color: $color-red-heart;
    }
  }
}


.quicklink-text {
  @extend .link;
  font-weight: 400;
}
