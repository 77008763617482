.list-tile {
  @include make-row();
  list-style: none;
  padding: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.list-tile--slider {
  opacity: 0;

  .slick-arrow {
    background-color: transparent;
    position: absolute;
    top: 50%;
    z-index: 99;
    border: none;
    width: 3rem;
    height: 8rem;
    vertical-align: middle;
    margin-top: -6.5rem;
    cursor: pointer;
    overflow: hidden;

    .icon {
      background-color: transparent;
      font-size: 8rem;
      color: $color-black;
      margin-left: -3.0rem;
    }

    &.slick-prev {
      background-color: transparent;
      left: 0;
      margin-left: -3.5rem;
    }

    &.slick-next {
      background-color: transparent;
      right: 0;
      margin-right: -3.5rem;
    }

    &.slick-hidden {
      display: none;
    }

    &.slick-disabled {
      cursor: inherit;
      opacity: 0.3;
    }
  }

  &.slick-initialized{
    opacity: 1;
    transition: opacity .3s ease-out;
  }
}

.list-tile__item {
  @include make-col-ready();
  @include make-col(3);
  max-width: 100%;
}



//.scroller {
//  overflow-y: hidden;
//  -webkit-overflow-scrolling: touch;
//
//  .scroller-container {
//  }
//}
//
//.scroller .list-tile {
//  flex-wrap: nowrap;
//}
//
//.scroller .list-tile__item {
//  float: none;
//  white-space: normal;
//  vertical-align: top;
//}
//
//.list-tile {
//  @include make-row();
//  list-style: none;
//  padding: 0;
//}
//
//.list-tile__item {
//  @include make-col-ready();
//  @include make-col(12);
//
//  @include bp('sm') {
//    @include make-col(6);
//  }
//
//  @include bp('lg') {
//    @include make-col(3);
//  }
//
//}
