.button {
  color: #fff;
  background-color: $color-umbra-dark;
  font-weight: 400;
  display: inline-block;
  border-radius: 0;
  text-align: center;
  user-select: none;
  border: 2px solid $color-umbra-dark;
  position: relative;
  line-height: normal;
  padding: .7rem 3.2rem .5rem;
  transition: all .2s ease-in-out;
  text-decoration: none;

  .icon--arrow-left,
  .icon--arrow-right,
  .icon--arrow-up,
  .icon--arrow-down {
    font-size: 2.5rem;
    margin: 0 0 0 -0rem;
    line-height: 0;
  }

  &:hover {
    color: $color-white;
    border-color: $color-umbra-medium;
    background-color: $color-umbra-medium;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.button--ghost {
  color: $color-umbra-dark;
  border-color: $color-umbra-dark;
  background: transparent;
}

.button--ghost-inverse {
  border-color: $color-white;
  background: transparent;
  &:hover {
    border-color: $color-black;
    background-color: $color-black;
  }
}

.button--booking {
  border-color: $color-brand-booking;
  background-color: $color-brand-booking;
}

.button--red-heart {
  border-color: $color-red-heart;
  background-color: $color-red-heart;
}

.button--blue, .button--poi {
  border-color: $color-brand-poi;
  background-color: $color-brand-poi;
}

.button--icon-only {
  font-weight: 300;
  background-color: transparent;
  border: none;

  color: $color-umbra-dark;
  &:hover {
    background-color: transparent;
    color: $color-umbra-dark;
  }
}

.button--circular {
  color: $color-umbra-dark;
  background-color: $color-white;
  padding: 0;
  border: .2rem solid $color-umbra-dark;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  transition: color 0.2s ease;

  .icon {
    font-size: 2.3rem;
    top: 0;

    @include bp('md') {
      font-size: 3.3rem;
    }
  }

  @include bp('md') {
    height: 5rem;
    width: 5rem;
  }

  &:hover, &:focus {
    background-color: $color-umbra-dark;
    color: $color-white;
  }

  &:focus {
    outline: none;
  }
}

.button-video-controls {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: $color-white;
  width: 3rem;
  height: 3rem;
  display: block;

  .icon {
    vertical-align: middle;
    display: block;
    font-size: 3rem;
    line-height: 3rem;
  }
}

.button-video-controls--play {
  .icon {
    @extend .icon--play;
  }
}

.button-video-controls--pause {
  .icon {
    @extend .icon--pause;
  }
}

.button--toggle-red-heart {
  background-color: transparent;
  border-color: $color-red-heart;
  color: $color-red-heart;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;

  .icon {
    float: right;
    font-size: 3rem;
    line-height: 2.3rem;
  }
}
