.block-filter {
  margin-top: -5rem !important;

  .dropdown {
    .dropdown-menu {
      background: #fff;
      position: absolute;
      visibility: hidden;
      z-index: 9999;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      overflow: scroll;
      opacity: 0;
      transition: all 0.2s ease-out;

      @include bp('md') {
        height: calc(100vh - 10rem);
        top: 10rem;
        padding: 3rem 0;
      }

      .button--close {
        position: absolute;
        right: 0;
        top: 0;

        @include bp('md') {
          display: none;
        }
      }
    }

    &.show {
      .dropdown-menu {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .trigger-holder {
    width: 100%;
    padding: 2rem 0 1.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 550px;
    margin: 0 auto;

    li {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      text-align: center;
      width: 33%;

      @include bp('sm') {
        padding: 0 4rem;
      }

      @include bp('md') {
        padding: 0 6rem;
      }

      @include bp('lg') {
        padding: 0 8rem;
      }

      .button {
        display: flex;
        color: $color-umbra-dark;
        text-decoration: none;
        transition: color 0.2s ease;
        background: none;
        border: none;
        padding: 0;
        flex-direction: column;
        font-weight: 300;
        font-size: 1.8rem;

        &[aria-expanded="true"], &:hover, &:focus {
          color: $color-brand-booking;
          background: none;

          .button, .icon {
            color: $color-brand-booking;
            border-color: $color-brand-booking;
          }
        }

        .icon {
          font-size: 3rem;
          margin-bottom: 0.5rem;
        }

        .icon, span {
          align-self: center;
        }

        span {
          border-bottom: 1px solid $color-umbra-dark;
        }
      }
    }
  }

  .filter-panel {
    padding-top: 0rem;

    @include bp('md') {
      padding-top: 3.5rem;
      margin: 0 10rem;
    }
  }

  .range-slider-field {
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-umbra-medium;

    label {
      margin-bottom: 2rem;
    }
  }

  .category-field {
    & > div {
      label {
        float: left;
      }
    }

    .rating-container {
      display: inline-block;
    }

    small {
      color: $color-umbra-medium;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }

  .range-slider-field {
    padding-bottom: 7rem;
    margin-bottom: 0;

    label {
      margin-bottom: 7rem;
    }

    &.map {
      label {
        margin-bottom: 3rem;
      }

      & > p {
        margin-bottom: 9rem;
      }
    }
  }

  .card {
    border-bottom: 1px solid $color-umbra-medium;
  }

  .card-header {
    p {
      margin: 0;
    }

    a {
      text-decoration: none;
      font-weight: 300;
      display: block;
      padding: 1rem 0;
      border: none;

      &:hover,
      &:focus {
        background-color: transparent;
        color: $color-umbra-dark;
      }

      @include bp('md') {
        padding: 0.7rem 2rem 0.7rem 0;
      }

      &.collapsed {
        .icon {
          @extend .icon--arrow-down;
        }
      }

      .icon {
        float: right;
        font-size: 3rem;
        top: -0.25rem;
      }
    }
  }

  .card-block {
    background-color: $color-umbra-bright;
    padding: 2rem;
    margin-bottom: 2rem;

    @include bp('md') {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-gap: 0 20px;
      padding: 2.5rem 3rem;

    }

    .field-checkbox {
      &:nth-child(n+5) {
        display: none;
      }

      &.last-visible-div {
        margin-bottom: 0;
      }
    }

    .show-more {
      @include bp('md') {
        grid-column: 1 / 3;
        text-align: center;
      }
    }

    .category-field {
      margin-bottom: 0;
    }
  }

  .card-block.show-all {
    .field-checkbox {
      &:nth-child(n+5) {
        display: block;
      }
    }
  }

  .field-checkbox {

    @include bp('md') {
      margin-bottom: 0.2rem;
    }

    label {
      font-weight: 300;
    }
  }
}

.block-filter__button {
  text-align: right;

  .button {
    width: 100%;

    @include bp('sm') {
      width: auto;
    }
  }
}
