.abstract {
  @extend .a1;

  @include bp('sm-max') {
    text-align: center;
  }

  font-weight: 700;
  .icon {
    top: -0.15rem;
  }
}

.abstract--inspiration {
  .icon {
    color: $color-brand-inspiration;
  }
}

.abstract--poi {
  .icon {
    color: $color-brand-poi;
  }
}

.abstract--highlight {
  .icon {
    color: $color-brand-highlight;
  }
}

.abstract--booking {
  .icon {
    color: $color-brand-booking;
  }
}
