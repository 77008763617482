// Short Map
#maps-embedded-container {
  position: relative;
  width: 100%;
}

#map-embedded {
  border: 1px solid #FFF;
  width: 100%;
  &::before {
    padding-top: 100%;
    display: block;
    content: '';

    @include bp('md') {
      padding-top: 46.875%;
    }
  }
}


#map-embedded.fullscreen {
  position: fixed;
  width:100%;
  height: 100%;
}

.details-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  overflow: auto;
  background-color: #FFF;
  margin: auto;
  padding: 2rem;
  animation: fadein .5s;
  -moz-animation: fadein .5s;
  -webkit-animation: fadein .5s;
  letter-spacing: 0;

  @include bp('md') {
    width: 576px;
    font-size: 1.8rem;
    border-top: none;
  }
}

.details-window__header {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;

  .header-icon {
    display: none;
    position: relative;
    margin-right: 1.2rem;
    width: 2.5rem;

    @include bp('md') {
      display: block;
      width: 3rem;
    }

    .header-label {
      position: absolute;
      color: $color-white;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.9;
      display: inline-block;
      width: 100%;

      @include bp('md') {
        font-size: $font-size-base;
        line-height: 2;
      }
    }
  }

  .header-title {
    @extend .b1;
    flex: 1;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .button {
    color: inherit;
    padding: 0;
    margin-left: .5rem;

    &:hover {
      background: none;
    }

    .icon {
      font-size: 3rem;
    }
  }

  .button--toggle {
    .icon {
      font-size: 2.5rem;
    }
  }
}

.details-window__footer {
  padding-top: 1rem;
  margin-top: 2rem;

  .footer-item {
    .icon {
      line-height: 2.3rem;
      font-size: 2rem;
      left: -0.5rem;

      &--map-point {
        color: $color-red-heart;
        font-size: 2.5rem
      }
    }

    span {
      @extend .link;
      font-weight: 400;
    }
  }

}

.details-window--fullmap {
  position: fixed;
  bottom:0;
  left: 0;
  right: 0;
  top: auto;
  transform: none;
  margin: 0rem;
  width: 100%;
  max-width: 100%;
  border-top: 1px solid $color-umbra-dark;

  @include bp('md') {
    width: 475px;
    left: 0;
    right: auto;
    margin: 5rem;
    border-top: none;
  }

  .details-window__content {
    .icon {
      display: block;
      left: -0.5rem;
      font-size: 2.3rem;
      color: $color-brand-poi;
      margin-right: 0.5rem;
    }
  }
}

.details-window__content {
  address {
    margin-bottom: 0.5rem;
  }

  a {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}
// END Short Map




// Big Map
#map-full-module-container {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#map-full {
  width: 100%;
  height: 100%;
}

#map-around-me-module-container {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#map-around-me {
  width: 100%;
  height: 100%;
}

.marker-details-flyout {
  background-color: #FFF;
  border: 1px solid #f2f2f2;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  padding: 20px;
  position: fixed;
  width: 100%;
  @include bp('md') {
    width: 50%;
    margin: 5% 1%;
  }
  @include bp('lg') {
    width: 40%;
    margin: 5% 1%;
  }
  @include bp('xl') {
    width: 30%;
    margin: 1% 1%;
  }
  .details-flyout-top {
    margin-bottom: 3rem;
    .details-flyout-image {
      height: auto;
      width: 100%;
      float: left;
    }
    .details-flyout-toggle {
      float: right;
    }
    .details-flyout-text-container {
      display: grid;
      .details-flyout-title {
        float: left;
        margin-left: 10px;
      }
      .details-flyout-is-open {
        font-size: 12px;
        margin-left: 10px;
      }
      .details-flyout-distance {
        font-size: 12px;
        margin-left: 10px;

      }
    }
    .details-flyout-toggle-icon {
      height: 32px;
      width: 32px;
      float: right;
      font-size: 2rem;
      color: $color-umbra-medium;
    }
    .details-flyout-close-icon {
      height: 32px;
      width: 32px;
      float: right;
      font-size: 2rem;
      color: $color-umbra-medium;
    }
  }
  .details-flyout-bottom {
    border-top: 2px solid $color-umbra-bright;
    .details-flyout-bottom-item {
      display: grid;
      margin-top: 6%;
      text-align: center;
      float: left;
      width: 33%;
      .details-flyout-bottom-item-icon {
        color: $color-blue-isar;
        font-size: 32px;
        margin-bottom: 20px;
      }
    }
  }
}

.maps-filters {
  background-color: #FFF;
  top: 0;
  left: 0;
  margin: 0;
  position: absolute;
  z-index: 9999;
  right: 0;
  width: auto;
  border-bottom: 1px solid $color-umbra-dark;
  font-size: 1.8rem;

  .field-checkbox {
    input {
      opacity: 0;
      position: absolute;
      left: -99999px
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: 3.5rem;

      &:before,
      &:after {
        position: absolute;
        content: "";
        display: inline-block;
      }

      &:before{
        height: 2rem !important;
        width: 2rem !important;
        border: 1px solid $color-umbra-medium;
        background-color: $color-white;
        left: 0;
        top: 0.3rem;

        @include bp('md') {
          height: 3rem;
          width: 3rem;
        }
      }

      &:after {
        @extend .icon;
        color: $color-green-nature;
        font-size: 1.8rem;
        // px here instead of rem. fix IE11 Bug
        line-height: 20px;
        height: 2rem;
        width: 2rem;
        vertical-align: middle;
        text-align: center;
        left: 0;
        top: 0.3rem;
      }
    }
  }


  @include bp('md') {
    right: auto;
    margin: 5rem;
    width: 475px;
    border-bottom: none;
  }

  .maps-filters-top {
    padding: 0.7rem 1.8rem;


    .maps-filters-title {

      .maps-filters-title-icon {
        height: 32px;
        width: 32px;
        vertical-align: middle;
      }
    }
    .maps-filters-toggle-icon-container {
      float: right;

      .maps-filters-toggle-icon {
        font-size: 2.6rem;
      }
    }
  }
  .maps-filters-bottom {
    background-color: #FFF;
    padding: 2rem 0 0 2rem;
    & .field-checkbox, label {
      color: $color-umbra-dark;
    }

    @include bp('md') {
      border-bottom: none;
    }
  }
}

[type="checkbox"] {
  float: left;
  position: absolute;
}

.filter-checkbox {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.filter-checkbox-label {
  color: #000;
  margin-top: -5px;
  margin-left: 25px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.position-error-overlay-container {
  background-color: #FFF;
  bottom: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  max-width: 100%;
  overflow: auto;
  padding: 2rem;
  top: 0;
  width: 90%;
  text-align: center;
  position: relative;

  @include bp('md') {
    width: 600px;
    padding: 3rem;

  }
  .overlay-text {
    vertical-align: sub;
  }

  .close-icon {
    display: none;
    position: absolute;
    top: 0;

    right: 0;
  }
}

.overlay-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-white, 0.7);
}

.fade-enter-active,
.fadeslow-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity .5s;
}

.fadeslow-leave-active {
  transition: opacity 2.5s;
}

.fade-enter, .fade-leave-to,
.fadeslow-enter, .fadeslow-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: transform 0.26s ease;
}

.slide-leave-active {
  transition: transform 0.26s ease;
}

.slide-enter, .slide-leave-to {
  transform: scaleY(0);
}

.btn-set-users-location {
  background-image: url('../images/icons/localized.svg');
  background-color: #FFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  cursor: pointer;
  margin-bottom: 2rem;
  margin-right: 2rem;
  height: 5rem;
  width: 5rem;
  border: 0px solid #FFF;
  border-radius: 100%;
}

.btn-open-full-map {
  background-image: url('../images/map-full-screen.svg');
  background-color: #FFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  cursor: pointer;
  margin-bottom: 22px;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border: 0px solid #FFF;
  border-radius: 100%;
}

.map-modal-header-icon {
  font-size: 2em;
}

.button-modal-close {
  background-color: #FFF;
  border: none;
  cursor: pointer;
}

.modal-header-icon-close {
  font-size: 1.5em;
  color: #000;
  &:hover {
    color: #81817E;
  }
}

.map-marker {
  z-index: 220;
}
// End Big Map

.disabled-link {
  opacity: 0.5;
}

.pulsing {
  animation-name: pulsing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes pulsing {
  0% {
    background-color: $color-white;
  }
  50% {
    background-color: $color-blue-isar;
  }
  100% {
    background-color: $color-white;
  }
}
