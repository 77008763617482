.autocomplete-suggestions-container {
  position: relative;
}

.autocomplete-suggestions {
  @extend .b1;
  margin: -3rem 0 0;
  padding: 1rem 0;
  background: $color-white;
  overflow: auto;
  font-weight: 300;
  color: $color-umbra-medium;
  width: 100% !important;

  & strong {
    font-weight: 300;
    color: $color-umbra-dark;
  }
}

.autocomplete-suggestion {
  padding: 0.5rem 4rem;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.autocomplete-selected {
  background: $color-umbra-bright;
}

.autocomplete-group {
  padding: 2px 5px;

  & strong {
    display: block;
    border-bottom: 1px solid $color-black;
  }
}
.list-style-buttons {
  float: right;

  .switcher {
    &:hover {
      color: $color-green-nature-structure;
    }

    &.active {
      border-bottom: 2px solid $color-green-nature-structure;
      margin-bottom: 1rem;
    }
  }
}
