.teaser__mucCard {
  margin-bottom: 5rem;

  @media only screen and (max-width: 821px) {
    padding: 1rem;
  }

  padding: 10px 20px;

  @include bp('sm') {
    padding: 30px 40px;
  }

  //Define ColorPatterns with classes START

  &.pattern-dot {
    &--blue, &--green, &--red, &--yellow {
      background-repeat: repeat;
      background-size: 10rem 10rem;
      //Texte
      .teaser__mucCard__text {
        color: #fff;
        p {
          margin: 0;
          padding: 0;
        }
      }
      .teaser__mucCard__benefits__text {
        color: #fff;

        p {
          margin: 0;
          padding: 0;
          line-height: 1.5;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }
      }

      //BTN
      .teaser__mucCard__btn {
        display: flex;

        @media only screen and (max-width: 821px) {
          margin-bottom: 20px;
        }

        a {
          color: #fff;

          &:hover {
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }
    &--blue {
      background-image: url('../images/pattern-dot-blue.svg');

      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #0096C8;
          &:hover {
            background-color: #0096C8;
          }
        }
      }
    }
    &--green {
      background-image: url('../images/pattern-dot-green.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #14A537;
          &:hover {
            background-color: #14A537;
          }
        }
      }
    }
    &--red {
      background-image: url('../images/pattern-dot-red.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #DB0131;
          &:hover {
            background-color: #DB0131;
          }
        }
      }
    }
    &--yellow {
      background-image: url('../images/pattern-dot-yellow.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #FAB900;
          &:hover {
            background-color: #FAB900;
          }
        }
      }
    }
  }

  &.pattern-heart {
    &--blue, &--green, &--red, &--yellow {
      background-repeat: repeat;
      background-size: 10rem 10rem;
      //Texte
      .teaser__mucCard__text {
        color: #fff;
        p {
          margin: 0;
          padding: 0;
        }
      }
      .teaser__mucCard__benefits__text {
        color: #fff;

        p {
          margin: 0;
          padding: 0;
        }
      }

      //BTN
      .teaser__mucCard__btn {

        a {
          color: #fff;

          &:hover {
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }
    &--blue {
      background-image: url('../images/pattern-heart-blue.svg');

      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #0096C8;
          &:hover {
            background-color: #0096C8;
          }
        }
      }
    }
    &--green {
      background-image: url('../images/pattern-heart-green.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #14A537;
          &:hover {
            background-color: #14A537;
          }
        }
      }
    }
    &--red {
      background-image: url('../images/pattern-heart-red.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #DB0131;
          &:hover {
            background-color: #DB0131;
          }
        }
      }
    }
    &--yellow {
      background-image: url('../images/pattern-heart-yellow.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #FAB900;
          &:hover {
            background-color: #FAB900;
          }
        }
      }
    }
  }

  &.pattern-line {
    &--blue, &--green, &--red, &--yellow {
      background-repeat: repeat;
      background-size: 10rem 10rem;
      //Texte
      .teaser__mucCard__text {
        color: #fff;
        p {
          margin: 0;
          padding: 0;
        }
      }
      .teaser__mucCard__benefits__text {
        color: #fff;

        p {
          margin: 0;
          padding: 0;
        }
      }

      //BTN
      .teaser__mucCard__btn {

        a {
          color: #fff;

          &:hover {
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }
    &--blue {
      background-image: url('../images/pattern-line-blue.svg');

      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #0096C8;
          &:hover {
            background-color: #0096C8;
          }
        }
      }
    }
    &--green {
      background-image: url('../images/pattern-line-green.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #14A537;
          &:hover {
            background-color: #14A537;
          }
        }
      }
    }
    &--red {
      background-image: url('../images/pattern-line-red.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #DB0131;
          &:hover {
            background-color: #DB0131;
          }
        }
      }
    }
    &--yellow {
      background-image: url('../images/pattern-line-yellow.svg');
      //BTN
      .teaser__mucCard__btn {
        a {
          background-color: #FAB900;
          &:hover {
            background-color: #FAB900;
          }
        }
      }
    }
  }

  //Define ColorPatterns with classes STOP

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 821px) {
      flex-direction: column;
      flex-wrap: wrap;
    }

    &__outer {}
    &__inner {}
  }

  &__image {
    width: 30%;
    max-width: 300px;

    @media only screen and (max-width: 821px) {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__text {
    text-align: left;
    margin-bottom: 20px;

    p > strong {
      font-size: 40px;
      line-height: 45px;

      @media only screen and (max-width: 821px) {
        font-size: 30px;
      }
    }

    p {
      @media only screen and (max-width: 821px) {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 821px) {
      text-align: center;
    }


    &__container {
      width: 40%;
      padding: 0 2rem;

      @media only screen and (max-width: 821px) {
        width: 100%;
      }

      //      background: orange;
    }
  }

  &__btn {
    display: flex;

    a {
      //      background: aqua;
      text-decoration: none;
      color: #010101;
      border: 2px solid #fff;
      padding: 10px 20px 10px 15px;
      //display: inline-flex;
      //flex-direction: column;
      //align-items: flex-start;

      @media only screen and (max-width: 821px) {
        width: 100%;
        margin: 0 auto;
        max-width: 300px;
        //display: inline-flex;
        //flex-direction: column;
        //align-items: center;
        //padding: 5px 15px 5px 10px;
      }

      span {
        font-weight: 700;
        font-size: 2rem;

        &.btn-text {
          //font-size: 75%;
          text-align: left;
          //width: 100%;
          //padding-right: 20px;
          //position: relative;

          @media only screen and (max-width: 821px) {
            margin-bottom: 1rem;
          }
        }
        &.price {
          //position: relative;
          display: inline;
          //padding-right: 20px;
          width: 20px;

          &:after {
            content: '';
            font-family: "lhm-icons";
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            speak: none;
            text-decoration: none;
            text-transform: none;
            vertical-align: middle;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
          }

          sub {
            bottom: 0;
          }
        }
      }
    }
  }

  &__benefits {
    width: 30%;

    //    &:nth-child(1){background: red;}
    //    &:nth-child(2){background: green;}
    //    &:nth-child(3){background: blue;}


    &__container__outer {
      width: 40%;
      //border-left: 1px solid #fff;
      //padding-left: 15px;

      @media only screen and (max-width: 821px) {
        display: none;
        //border: 0 none;
        //padding-left: 0;
      }
    }
    &__container__inner {
      display: flex;
      flex-direction: column;
      //flex-wrap: nowrap;
      //justify-content: space-between;

      .image-row, .desc-row, .headline-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        div {
          width: 30%;
        }
      }

    }
    &__img {
      margin-bottom: 20px;

      img {
        width: 100%;
        max-width: 90px;
        margin: 0 auto;
        display: block;
      }
    }
    &__text {
      font-size: 18px;
      line-height: 21px;
      text-align: center;

      a {
        color: #fff;
      }
    }
  }
}
