.hint {
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;

  .icon {
    font-size: 3.5rem;
    margin-right: 1rem;
    align-self: center;
    color: $color-umbra-dark;
    top: 0;
    flex: 0 1 auto;
    display: inline-block;
  }

  span {
    font-size: 1.2rem;
    line-height: 2.1rem;
    color: $color-umbra-dark;
    font-weight: 300;
    flex: 1 1 auto;
    display: inline-block;
  }

}
