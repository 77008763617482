.block-payee {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .price-list {
    width: 100%;

    .list-group__item {
      @extend .clearfix;

      &:last-child {
        margin-top: 5rem;
      }
    }

    .price-list-label {
      float: none;
      @extend .b1;

      div:nth-child(2) {
        margin-top: 0.2rem;
        font-weight: 400;
        font-style: italic;        color: $color-umbra-medium;
      }

      @include bp('sm') {
        max-width: 80%;
        float: left;
      }
    }

    .price-list-price {
      @extend .b1;
      float: none;
      text-align: left;

      @include bp('sm') {
        float: right;
        text-align: right;
      }
    }

    .list-label {
      @include bp('sm') {
        max-width: 70%;
      }
    }
  }
}

.block-payee__checkbox {
  width: 100%;
  margin: 3rem 0 2rem 0;

  .field {
    margin: 0;
  }
}
