
@font-face {
  font-family: "lhm-icons";
  src: url('../fonts/lhm-icons.eot');
  src: url('../fonts/lhm-icons.eot?#iefix') format('eot'),
  url('../fonts/lhm-icons.woff2') format('woff2'),
  url('../fonts/lhm-icons.woff') format('woff'),
  url('../fonts/lhm-icons.ttf') format('truetype'),
  url('../fonts/lhm-icons.svg#lhm-icons') format('svg');
}

@mixin icon-styles {
  font-family: "lhm-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  position: relative;
  top: -0.1rem;
}

%icon {
  @include icon-styles;
}

.icon {
  @include icon-styles;
}


@function icon--char($filename) {
  $char: "";

  @if $filename == aloisius {
  $char: "\E001";
}
  @if $filename == arrow-down-f {
  $char: "\E002";
}
  @if $filename == arrow-down {
  $char: "\E003";
}
  @if $filename == arrow-left-f {
  $char: "\E004";
}
  @if $filename == arrow-left {
  $char: "\E005";
}
  @if $filename == arrow-right-f {
  $char: "\E006";
}
  @if $filename == arrow-right {
  $char: "\E007";
}
  @if $filename == arrow-up-f {
  $char: "\E008";
}
  @if $filename == arrow-up {
  $char: "\E009";
}
  @if $filename == article-f {
  $char: "\E00A";
}
  @if $filename == article {
  $char: "\E00B";
}
  @if $filename == attention {
  $char: "\E00C";
}
  @if $filename == b-distance {
  $char: "\E00D";
}
  @if $filename == b-rating-eight {
  $char: "\E00E";
}
  @if $filename == b-rating {
  $char: "\E00F";
}
  @if $filename == bookmark-f {
  $char: "\E010";
}
  @if $filename == bookmark {
  $char: "\E011";
}
  @if $filename == bubble {
  $char: "\E012";
}
  @if $filename == call-f {
  $char: "\E013";
}
  @if $filename == call {
  $char: "\E014";
}
  @if $filename == check-progress {
  $char: "\E015";
}
  @if $filename == circle-f {
  $char: "\E016";
}
  @if $filename == circle-number-f {
  $char: "\E017";
}
  @if $filename == close-f {
  $char: "\E018";
}
  @if $filename == close {
  $char: "\E019";
}
  @if $filename == data-security {
  $char: "\E01A";
}
  @if $filename == date-f {
  $char: "\E01B";
}
  @if $filename == date {
  $char: "\E01C";
}
  @if $filename == distance {
  $char: "\E01D";
}
  @if $filename == double-slash {
  $char: "\E01E";
}
  @if $filename == edit-f {
  $char: "\E01F";
}
  @if $filename == edit {
  $char: "\E020";
}
  @if $filename == enlarge-f {
  $char: "\E021";
}
  @if $filename == filter-f {
  $char: "\E022";
}
  @if $filename == filter {
  $char: "\E023";
}
  @if $filename == globe {
  $char: "\E024";
}
  @if $filename == heart-circle-f {
  $char: "\E025";
}
  @if $filename == heart-f {
  $char: "\E026";
}
  @if $filename == heart {
  $char: "\E027";
}
  @if $filename == hotel-f {
  $char: "\E028";
}
  @if $filename == hotel {
  $char: "\E029";
}
  @if $filename == leave-f {
  $char: "\E02A";
}
  @if $filename == leave {
  $char: "\E02B";
}
  @if $filename == localized-not {
  $char: "\E02C";
}
  @if $filename == localized {
  $char: "\E02D";
}
  @if $filename == mail-f {
  $char: "\E02E";
}
  @if $filename == mail {
  $char: "\E02F";
}
  @if $filename == map-point-f {
  $char: "\E030";
}
  @if $filename == map-point {
  $char: "\E031";
}
  @if $filename == menue-f {
  $char: "\E032";
}
  @if $filename == menue {
  $char: "\E033";
}
  @if $filename == mountain {
  $char: "\E034";
}
  @if $filename == pause {
  $char: "\E035";
}
  @if $filename == play {
  $char: "\E036";
}
  @if $filename == public-transport-f {
  $char: "\E037";
}
  @if $filename == public-transport {
  $char: "\E038";
}
  @if $filename == rating-outline {
  $char: "\E039";
}
  @if $filename == room-doublebed {
  $char: "\E03A";
}
  @if $filename == room-person {
  $char: "\E03B";
}
  @if $filename == s-facebook-msg {
  $char: "\E03C";
}
  @if $filename == s-facebook {
  $char: "\E03D";
}
  @if $filename == s-instagram {
  $char: "\E03E";
}
  @if $filename == s-linkedin {
  $char: "\E03F";
}
  @if $filename == s-mail {
  $char: "\E040";
}
  @if $filename == s-pinterest {
  $char: "\E041";
}
  @if $filename == s-twitter {
  $char: "\E042";
}
  @if $filename == s-vimeo {
  $char: "\E043";
}
  @if $filename == s-whatsapp {
  $char: "\E044";
}
  @if $filename == s-youtube {
  $char: "\E045";
}
  @if $filename == search-arrow {
  $char: "\E046";
}
  @if $filename == search-f {
  $char: "\E047";
}
  @if $filename == search {
  $char: "\E048";
}
  @if $filename == share-f {
  $char: "\E049";
}
  @if $filename == share {
  $char: "\E04A";
}
  @if $filename == shopping-cart-f {
  $char: "\E04B";
}
  @if $filename == shopping-cart {
  $char: "\E04C";
}
  @if $filename == show-less-f {
  $char: "\E04D";
}
  @if $filename == show-less {
  $char: "\E04E";
}
  @if $filename == show-more-f {
  $char: "\E04F";
}
  @if $filename == show-more {
  $char: "\E050";
}
  @if $filename == slash {
  $char: "\E051";
}
  @if $filename == slope-f {
  $char: "\E052";
}
  @if $filename == slope {
  $char: "\E053";
}
  @if $filename == sort-f {
  $char: "\E054";
}
  @if $filename == sort {
  $char: "\E055";
}
  @if $filename == time-f {
  $char: "\E056";
}
  @if $filename == time {
  $char: "\E057";
}
  @if $filename == toggle-list {
  $char: "\E058";
}
  @if $filename == toggle-tiles {
  $char: "\E059";
}
  @if $filename == touristinfo-f {
  $char: "\E05A";
}
  @if $filename == touristinfo {
  $char: "\E05B";
}
  @if $filename == trashcan {
  $char: "\E05C";
}
  @if $filename == w-broken-clouds {
  $char: "\E05D";
}
  @if $filename == w-clear-sky {
  $char: "\E05E";
}
  @if $filename == w-few-clouds {
  $char: "\E05F";
}
  @if $filename == w-mist {
  $char: "\E060";
}
  @if $filename == w-rain {
  $char: "\E061";
}
  @if $filename == w-scattered-clouds {
  $char: "\E062";
}
  @if $filename == w-shower-rain {
  $char: "\E063";
}
  @if $filename == w-snow {
  $char: "\E064";
}
  @if $filename == w-thunderstorm {
  $char: "\E065";
}
  @if $filename == webcam-2 {
  $char: "\E066";
}
  @if $filename == webcam-3 {
  $char: "\E067";
}
  @if $filename == webcam {
  $char: "\E068";
}
  @if $filename == wlan-f {
  $char: "\E069";
}
  @if $filename == wlan {
  $char: "\E06A";
}
  @if $filename == print {
  $char: "\E06B";
}
  @if $filename == sharer {
    $char: "\E06C";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {

  content: icon--char($filename);
}
}

.icon--aloisius {
  @include icon(aloisius);
}
.icon--arrow-down-f {
  @include icon(arrow-down-f);
}
.icon--arrow-down {
  @include icon(arrow-down);
}
.icon--arrow-left-f {
  @include icon(arrow-left-f);
}
.icon--arrow-left {
  @include icon(arrow-left);
}
.icon--arrow-right-f {
  @include icon(arrow-right-f);
}
.icon--arrow-right {
  @include icon(arrow-right);
}
.icon--arrow-up-f {
  @include icon(arrow-up-f);
}
.icon--arrow-up {
  @include icon(arrow-up);
}
.icon--article-f {
  @include icon(article-f);
}
.icon--article {
  @include icon(article);
}
.icon--attention {
  @include icon(attention);
}
.icon--b-distance {
  @include icon(b-distance);
}
.icon--b-rating-eight {
  @include icon(b-rating-eight);
}
.icon--b-rating {
  @include icon(b-rating);
}
.icon--bookmark-f {
  @include icon(bookmark-f);
}
.icon--bookmark {
  @include icon(bookmark);
}
.icon--bubble {
  @include icon(bubble);
}
.icon--call-f {
  @include icon(call-f);
}
.icon--call {
  @include icon(call);
}
.icon--check-progress {
  @include icon(check-progress);
}
.icon--circle-f {
  @include icon(circle-f);
}
.icon--circle-number-f {
  @include icon(circle-number-f);
}
.icon--close-f {
  @include icon(close-f);
}
.icon--close {
  @include icon(close);
}
.icon--data-security {
  @include icon(data-security);
}
.icon--date-f {
  @include icon(date-f);
}
.icon--date {
  @include icon(date);
}
.icon--distance {
  @include icon(distance);
}
.icon--double-slash {
  @include icon(double-slash);
}
.icon--edit-f {
  @include icon(edit-f);
}
.icon--edit {
  @include icon(edit);
}
.icon--enlarge-f {
  @include icon(enlarge-f);
}
.icon--filter-f {
  @include icon(filter-f);
}
.icon--filter {
  @include icon(filter);
}
.icon--globe {
  @include icon(globe);
}
.icon--heart-circle-f {
  @include icon(heart-circle-f);
}
.icon--heart-f {
  @include icon(heart-f);
}
.icon--heart {
  @include icon(heart);
}
.icon--hotel-f {
  @include icon(hotel-f);
}
.icon--hotel {
  @include icon(hotel);
}
.icon--leave-f {
  @include icon(leave-f);
}
.icon--leave {
  @include icon(leave);
}
.icon--localized-not {
  @include icon(localized-not);
}
.icon--localized {
  @include icon(localized);
}
.icon--mail-f {
  @include icon(mail-f);
}
.icon--mail {
  @include icon(mail);
}
.icon--map-point-f {
  @include icon(map-point-f);
}
.icon--map-point {
  @include icon(map-point);
}
.icon--menue-f {
  @include icon(menue-f);
}
.icon--menue {
  @include icon(menue);
}
.icon--mountain {
  @include icon(mountain);
}
.icon--pause {
  @include icon(pause);
}
.icon--play {
  @include icon(play);
}
.icon--public-transport-f {
  @include icon(public-transport-f);
}
.icon--public-transport {
  @include icon(public-transport);
}
.icon--rating-outline {
  @include icon(rating-outline);
}
.icon--room-doublebed {
  @include icon(room-doublebed);
}
.icon--room-person {
  @include icon(room-person);
}
.icon--s-facebook-msg {
  @include icon(s-facebook-msg);
}
.icon--s-facebook {
  @include icon(s-facebook);
}
.icon--s-instagram {
  @include icon(s-instagram);
}
.icon--s-linkedin {
  @include icon(s-linkedin);
}
.icon--s-mail {
  @include icon(s-mail);
}
.icon--s-pinterest {
  @include icon(s-pinterest);
}
.icon--s-twitter {
  @include icon(s-twitter);
}
.icon--s-vimeo {
  @include icon(s-vimeo);
}
.icon--s-whatsapp {
  @include icon(s-whatsapp);
}
.icon--s-youtube {
  @include icon(s-youtube);
}
.icon--search-arrow {
  @include icon(search-arrow);
}
.icon--search-f {
  @include icon(search-f);
}
.icon--search {
  @include icon(search);
}
.icon--share-f {
  @include icon(share-f);
}
.icon--share {
  @include icon(share);
}
.icon--shopping-cart-f {
  @include icon(shopping-cart-f);
}
.icon--shopping-cart {
  @include icon(shopping-cart);
}
.icon--show-less-f {
  @include icon(show-less-f);
}
.icon--show-less {
  @include icon(show-less);
}
.icon--show-more-f {
  @include icon(show-more-f);
}
.icon--show-more {
  @include icon(show-more);
}
.icon--slash {
  @include icon(slash);
}
.icon--slope-f {
  @include icon(slope-f);
}
.icon--slope {
  @include icon(slope);
}
.icon--sort-f {
  @include icon(sort-f);
}
.icon--sort {
  @include icon(sort);
}
.icon--time-f {
  @include icon(time-f);
}
.icon--time {
  @include icon(time);
}
.icon--toggle-list {
  @include icon(toggle-list);
}
.icon--toggle-tiles {
  @include icon(toggle-tiles);
}
.icon--touristinfo-f {
  @include icon(touristinfo-f);
}
.icon--touristinfo {
  @include icon(touristinfo);
}
.icon--trashcan {
  @include icon(trashcan);
}
.icon--w-broken-clouds {
  @include icon(w-broken-clouds);
}
.icon--w-clear-sky {
  @include icon(w-clear-sky);
}
.icon--w-few-clouds {
  @include icon(w-few-clouds);
}
.icon--w-mist {
  @include icon(w-mist);
}
.icon--w-rain {
  @include icon(w-rain);
}
.icon--w-scattered-clouds {
  @include icon(w-scattered-clouds);
}
.icon--w-shower-rain {
  @include icon(w-shower-rain);
}
.icon--w-snow {
  @include icon(w-snow);
}
.icon--w-thunderstorm {
  @include icon(w-thunderstorm);
}
.icon--webcam-2 {
  @include icon(webcam-2);
}
.icon--webcam-3 {
  @include icon(webcam-3);
}
.icon--webcam {
  @include icon(webcam);
}
.icon--wlan-f {
  @include icon(wlan-f);
}
.icon--wlan {
  @include icon(wlan);
}
.icon--print {
  @include icon(print);
}
.icon--sharer {
  @include icon(sharer);
}
