.block-shopping-cart-add-item {
  .field-number-increment {
    margin-top: 2rem;
    label {
      margin-right: 2rem;
    }
    input {
      border: none;
      width: 2.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      text-align: center;
      box-sizing: content-box;
    }
  }
  .pull-right {
    text-align: right;
  }
}

.block-shopping-cart-add-item__image {
  position: relative;
  .label {
    position: absolute;
    right: 0;
    top: 2rem;
  }
}
