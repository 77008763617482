.teaser-tile {
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  &::before {
    //padding-top: 66.666666666666667%;
    padding-top: 80%;
    display: block;
    content: "";
  }

  //background: $color-umbra-dark;

  &.teaser-tile-bordered {
    overflow: visible;
    background: none;

    &::before {
      display: none;
    }

    .label {
      display: none;
    }

    .teaser-tile__content {
      position: relative;
      display: block;

      &::before {
        padding-top: 66.666666666666667%;
        display: block;
        content: "";
      }
    }

    .teaser-tile__headline {
      text-align: center;
      position: absolute;
      color: $color-white;
      bottom: auto;
      top: 0;
      left: 0;
      right: 0;
      padding: 1.2rem 1.8rem;
      margin: 0;
      background: linear-gradient(rgba($black, 0.8), rgba($black, 0));
    }

    .teaser-tile__content-overlapped {
      display: block;
      z-index: 1;
      width: calc(100% - 40px);
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      margin: -40px auto 0;
      position: relative;
      font-size: 15px;

      .inner {
        padding: 1em;

        height: 240px;
        overflow-x: auto;
        overflow-y: scroll;

        scrollbar-color: #cbcbcb #ffffff;
        scrollbar-width: thin;
        scrollbar-gutter: stable;

        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d9d9d9;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #cbcbcb;
        }

        p {
          margin-bottom: 1rem;
          line-height: 2.2rem;
        }
      }

      .label {
        display: block;
        padding: 0;
        background-color: transparent;
      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          font-size: 15px;
          //padding-bottom: 20px;
          padding-left: 2em;
          position: relative;

          &:before {
            -webkit-font-smoothing: antialiased;
            @extend .icon--double-slash;
            font-family: "lhm-icons";
            left: 0;
            position: absolute;
            top: 0;
          }
        }
      }

      .teaser-tile__content__button {
        align-items: center;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        min-height: 65px;

        .block-topic__button-label {
          display: inline-block;
          font-size: 25px;
          font-weight: bold;
        }

        .link {
          color: $white;
          font-weight: $font-weight-bold;
          font-size: 15px;
          padding: 0;
          border: none;

          .icon {
            display: none;
          }

          &:before {
            @extend .icon--arrow-right;
            font-family: "lhm-icons";
            -webkit-font-smoothing: antialiased;
          }
        }
      }
    }

    &.teaser-tile--poi {
      .teaser-tile__content-overlapped {
        border-color: $color-brand-poi;

        .teaser-tile__content__button {
          background-color: $color-brand-poi;
        }

        ul li:before {
          color: $color-brand-poi;
        }
      }

      .teaser-tile__content {
        .teaser-tile__transitionbox {
          background: none;

          .teaser-tile__description {
            //opacity: 0;
            display: none;

            .ezrichtext-field {
              display: none;
            }
          }
        }
      }

      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    .teaser-tile__content {
      .teaser-tile__transitionbox {
        .teaser-tile__headline {
          background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
          padding-bottom: 1.2rem;
        }
      }
    }
  }
}

.teaser-tile__content-overlapped {
  display: none;
}

.teaser-tile__background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;

  picture * {
    height: 100%;
  }

  img {
    height: 100%;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.teaser-tile__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .teaser-tile__transitionbox {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));

    .teaser-tile__headline {
      padding-bottom: 0;
      background: none;
    }
  }

  .teaser-tile__headline {
    position: relative;
  }

  .teaser-tile__description {
    opacity: 0;
    max-height: 0;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
    padding: 10px 1.8rem 1.2rem;

    @include bp("md") {
      padding: 10px 2rem 1.5rem;
    }
    h4 {
      font-size: 2.1rem;
      color: $color-white;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.45rem;
      color: #fff;
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  &:hover {
    .teaser-tile__transitionbox {
    }

    .teaser-tile__headline {
    }

    .teaser-tile__description {
      opacity: 1;
      max-height: 350px;
    }
  }

  &:focus {
    .teaser-tile__headline {
      text-decoration: underline;
    }
  }

  .icon,
  .icon-image {
    top: 1.5rem;
    left: 1.5rem;

    @include bp("xl") {
      top: 2rem;
      left: 2rem;
    }
  }

  .icon-image {
    position: relative;
    display: block;
    width: 4rem;
    height: 4rem;

    img {
      width: 100%;
      height: auto;
    }

    @include bp("md") {
      width: 5rem;
      height: 5rem;
    }
  }

  .label {
    position: absolute;
    right: 0;
    top: 1.5rem;
    font-size: 3.6vw;

    @include bp("sm") {
      font-size: 2vw;
      padding: 0.7rem 1rem 0.3rem;
    }

    @include bp("xl") {
      font-size: 1.3vw;
      top: 2rem;
    }

    @media (min-width: 1600px) {
      font-size: inherit;
    }
  }
}

.teaser-tile__headline {
  font-size: 2.2rem;
  line-height: 1.2;
  position: absolute;
  color: $color-white;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.2rem 1.8rem;
  margin: 0;
  background: linear-gradient(rgba($black, 0), rgba($black, 0.8));

  @include bp("md") {
    font-size: 2.4rem;
    line-height: 1.125;
    padding: 1.5rem 2rem;
  }

  //@include bp('lg') {
  //  padding-right: 50%;
  //}
}

.embed-teaser-tile__headline {
  font-size: 1.2rem;
  line-height: 1.2;
  position: absolute;
  color: $color-white;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.2rem 1.8rem;
  margin: 0;
  background: linear-gradient(rgba($black, 0), rgba($black, 0.8));

  @include bp("md") {
    font-size: 1.4rem;
    line-height: 1.125;
    padding: 1.5rem 2rem;
  }

  //@include bp('lg') {
  //  padding-right: 50%;
  //}
}
/* 
CSS-Code für Js-Implementation von Klicken für öffnen der Beschreibung und erst dann weiterleiten auf die Seite
@media screen and (max-width: 769px) {
  .teaser-tile__content {
    pointer-events: none;
  }
  .teaser-tile__content:hover {
  display: none;
  }

  .teaser-tile__description-mobile {
    display: none;
   }  
   .teaser-tile__headline-padding{
    margin-bottom: 10px;
   }

   .teaser-tile__description {
    opacity:1 !important;
    max-height: 350px !important;
    h4{
      font-size: 1.6rem !important;
      margin-bottom: 0 !important;
      margin-top: -10px;
    }
    &:hover{

    }
   }
.teaser-tile__headline{
  font-size: 2rem;
  line-height: 1.1;
}
.teaser-tile__content {
  .ezrichtext-field{
    line-height: 1.3;
    p{
      font-size: 1.4rem;
    }
  }
}

  }



.teaser-tile__content-onclick {
  display: block;
}


.teaser-tile__description-visible{
  opacity: 1;
    max-height: 350px;
}
 */

/* Dachzeile über Überschrift: */
.teaser-tile__subheadline {
  font-size: 1.7rem;
  color: #fff;
  margin-bottom: -1rem;
  margin-left: 2rem;
  font-weight: 500;
  padding-top: 3rem;
}

@media screen and (max-width: 769px) {
  .teaser-tile__subheadline {
    font-size: 1.5rem;
  }
  .teaser-tile__subheadline {
    margin-left: 1.8rem;
  }
}
