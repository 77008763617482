.trust-you {


  &--container {
    margin: 3.5rem 0;
  }
  &--wrapper {}
  &--iframe {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    iframe {
      pointer-events: none;

      > * {
        pointer-events: none;
      }
    }

    &--btn {
      height: 60px;
      padding: 10px;
      border: 1px solid $color-brand-poi;

      pointer-events: all;

      iframe {
        pointer-events: none;

        > * {
          pointer-events: none;
        }
      }
    }
  }

  &--btn {
    border-bottom: 1px solid;
    border-color: $color-brand-poi; //TODO: context specific color
    transition: all 0.8s ease;
    background-color: transparent;
    padding: 0 0 0.3rem 0;

    &:hover {
      color: $color-brand-poi;
    }

    &--wrapper {}
  }


  &--layer {
    //margin: 5rem 0;
    padding: 2rem;
    border: 1px solid $color-brand-poi;
    background: #fff;

    height: calc(100vh - 4rem);
    width: 65%;
    margin: 2rem auto 0;

    overflow-y: scroll;

    scrollbar-color: #cbcbcb #ffffff;
    scrollbar-width: thin;
    scrollbar-gutter: stable;

    @include bp('md-max') {
      width: 80%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #cbcbcb;
    }

    iframe {
      //height: 100%;
    }

    &--outer {
      display: none;
      position: fixed;
      background: rgba(255, 255, 255, .75);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 1000;
    }

    &--close {
      position: absolute;
      right: 0;
      font-size: 3.5rem;

      @include bp('md-max') {
        padding: 10px;
        font-size: 2.5rem;
      }
    }
  }
}

//overwrite small TY-iframe in image
// slider
.widget-trustscore {
  .trust-you--iframe--btn {
    height: 30px;
    display: block;
    padding: 0;
    border: 0;
  }
}


.trust-you--iframe--btn--list {
  pointer-events: all;

  iframe {
    pointer-events: none;

    > * {
      pointer-events: none;
    }
  }
}

.trust-you--layer--outer--list {
  display: none;
  position: fixed;
  background: rgba(255, 255, 255, .75);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.trust-you--layer--close--list {
  position: absolute;
  right: 0;
  font-size: 3.5rem;

  @include bp('md-max') {
    padding: 10px;
    font-size: 2.5rem;
  }
}
