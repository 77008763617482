.block-topic {
  position: relative;

  @extend .t1;

  &.block-topic-bordered {
    .block-topic__image-container {
      height: 100%;
      margin: 0 auto;

      overflow: hidden;
      border-radius: 4px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .label {
        display: none;
      }
    }

    .block-topic__container {
      border-style: solid;
      border-width: 1px;
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-down(sm) {
        transform: translateY(-40px);
        background-color: $white;
        margin: 0 auto;
        width: calc(100% - 40px);
      }

      a:not(.link) {
        flex: 1;
        padding: 1em;
      }

      .link {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 15px;
        padding: 0;
        border: none;

        &:before {
          @extend .icon--arrow-right;
          font-family: "lhm-icons";
          -webkit-font-smoothing: antialiased;
        }
      }

      h3 {
        font-size: 2.2rem;
      }

      h1,
      h2,
      h3,
      h4 {
        text-align: center;
        margin-bottom: 1em;
      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          font-size: 15px;
          padding-bottom: 20px;
          padding-left: 2em;
          position: relative;

          &:before {
            -webkit-font-smoothing: antialiased;
            @extend .icon--double-slash;
            font-family: "lhm-icons";
            left: 0;
            position: absolute;
            top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .ezrichtext-field {
        p {
          font-size: 1.5rem;
        }
      }

      .block-topic__button {
        align-items: center;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;

        .block-topic__button-label {
          display: inline-block;
          font-size: 25px;
          font-weight: bold;
        }
      }

      .block-topic__tagline {
        display: none;
      }
    }
  }
}

.block-topic__content {
  text-align: center;

  @include bp("md") {
    text-align: left;
  }

  .block-topic__image-container {
    @include bp("md") {
      overflow: hidden;
      border-radius: 4px;
    }
    img {
      transition: transform 0.275s ease;
    }
  }

  .icon--search-arrow {
    transition: right 0.8s ease;
  }

  &:hover {
    .block-topic__image-container {
      overflow: hidden;
      img {
        transform: scale(1.05);
      }
    }

    .icon--search-arrow {
      right: -1rem;
    }

    .link--arrow {
      transition: color 0.8s ease, padding 0.425s linear !important;
      padding-right: 1rem;
    }

    .link--inspiration {
      color: $color-brand-inspiration;
    }
    .link--booking {
      color: $color-brand-booking;
    }
    .link--poi {
      color: $color-brand-poi;
    }
    .link--highlight {
      color: $color-brand-highlight;
    }
  }
}

.block-topic__icon {
  height: 18rem;
  margin-top: -4rem;
  margin-bottom: -4rem;

  @include bp("md") {
    height: 32rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.block-topic__pic,
.block-topic__bg-color {
  .block-topic__icon {
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: 0;
    color: $color-white;
  }
}

.block-topic__bg-color {
  background-color: transparent;
  height: auto;
  position: relative;
  overflow: hidden;

  &::before {
    padding-top: 66.666666666666667%;
    display: block;
    content: "";
  }
}

.block-topic__image-container {
  text-align: center;
  position: relative;
  margin: 0 -20px;
  width: auto;

  @include bp("md") {
    margin: 0 8px 0 0;
  }
}

.block-topic__container {
  padding-top: 2rem;
  border-radius: 4px;

  @include bp("md") {
    padding-top: 0;
    margin-left: 8px;
  }

  .link-list {
    vertical-align: middle;

    @include bp("md") {
      text-align: left;
    }
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        margin: 2rem auto;
        border-top-color: inherit;

        @include bp("md") {
          margin: 2rem 0;
        }
      }
    }

    .item-link {
      @extend .h2;
    }
  }
}

.block-topic__tagline {
  margin-bottom: 0.9rem;
  display: inline-block;
}

.block-topic__headline {
  @extend .h2;
  margin-bottom: 1.2rem;

  a,
  a:hover,
  a:focus {
    border: none;
  }
}

.block-topic__button {
  .block-topic__button-label {
    display: none;
  }
}

/* Brand Colors */

.block-topic--booking {
  &.block-topic-bordered {
    .block-topic__container {
      border-color: $color-brand-booking;

      ul li:before {
        color: $color-brand-booking;
      }

      .block-topic__button {
        background-color: $color-brand-booking;
      }
    }
  }

  .block-topic__bg-color {
    background-color: $color-brand-booking;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-booking;
      }
    }
  }

  .label--booking {
    background: $color-brand-booking;
    position: absolute;
    right: 0;
    top: 2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--booking;
  }
}

.block-topic--inspiration {
  &.block-topic-bordered {
    .block-topic__container {
      border-color: $color-brand-inspiration;

      ul li:before {
        color: $color-brand-inspiration;
      }

      .block-topic__button {
        background-color: $color-brand-inspiration;
      }
    }
  }

  .block-topic__bg-color {
    background-color: $color-brand-inspiration;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-inspiration;
      }
    }
  }

  .label--booking {
    background: $color-brand-inspiration;
    color: #000000;
    position: absolute;
    right: 0;
    top: 2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--inspiration;
  }
}

.block-topic--poi {
  &.block-topic-bordered {
    .block-topic__container {
      border-color: $color-brand-poi;

      ul li:before {
        color: $color-brand-poi;
      }

      .block-topic__button {
        background-color: $color-brand-poi;
      }
    }
  }

  .block-topic__bg-color {
    background-color: $color-brand-poi;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-poi;
      }
    }
  }

  .label--booking {
    background: $color-brand-poi;
    position: absolute;
    right: 0;
    top: 2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--poi;
  }
}

.block-topic--highlight {
  &.block-topic-bordered {
    .block-topic__container {
      border-color: $color-brand-highlight;

      ul li:before {
        color: $color-brand-highlight;
      }

      .block-topic__button {
        background-color: $color-brand-highlight;
      }
    }
  }

  .block-topic__bg-color {
    background-color: $color-brand-highlight;
  }

  .link-list__item {
    & + .link-list__item {
      &:before {
        border-top-color: $color-brand-highlight;
      }
    }
  }

  .label--booking {
    background: $color-brand-highlight;
    position: absolute;
    right: 0;
    top: 2rem;
    padding: 0.7rem 2rem 0.3rem;
  }

  .divider {
    @extend .divider--highlight;
  }
}

@media (max-width: 769px) {
  .block-topic__content {
    text-align: center;

    .icon--search-arrow {
      transition: right 0.8s ease;
    }

    &:hover {
      .block-topic__image-container {
        overflow: hidden;
        img {
          transform: unset;
        }
      }
    }
  }
}
