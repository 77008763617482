// Mixins
@import "scss/mixins/breakpoint";
@import "scss/mixins/mixins";

// Global
@import "scss/iconfont";
@import "scss/webfont";
//@import "scss/sprite";
@import "scss/bootstrap/_variables";
@import "scss/variables";
@import "scss/bootstrap/bootstrap-grid";
@import "scss/bootstrap/modal";
@import "scss/normalize";
@import "scss/reboot";
@import "scss/type";
@import "scss/globals";

// Vendor / Plugins
@import "scss/vendor/pikaday";
@import "scss/vendor/star-rating";
@import "scss/vendor/rating-theme";
@import "scss/vendor/nouislider";
@import "scss/vendor/userlike";

// Pattern Styles

// Atoms
@import "scss/patterns/atoms/buttons";
@import "scss/patterns/atoms/embed-btns";
@import "scss/patterns/atoms/advents-calender";

// Molecules
@import "scss/patterns/molecules/dividers/dividers";
@import "scss/patterns/molecules/blocks/block-entry-meta";
@import "scss/patterns/molecules/blocks/block-social-media";
@import "scss/patterns/molecules/blocks/block-date-filter";
@import "scss/patterns/molecules/blocks/block-filter";
@import "scss/patterns/molecules/blocks/contact-info";
@import "scss/patterns/molecules/blocks/block-confirmation";
@import "scss/patterns/molecules/blocks/block-apple-wallet";
@import "scss/patterns/molecules/forms/forms";
@import "scss/patterns/molecules/forms/autocomplete";
@import "scss/patterns/molecules/blocks/block-offer";
@import "scss/patterns/molecules/forms/reservation";
@import "scss/patterns/molecules/text/blockquote";
@import "scss/patterns/molecules/blocks/block-story";
@import "scss/patterns/molecules/blocks/block-list-teaser";
@import "scss/patterns/molecules/blocks/block-topic";
@import "scss/patterns/molecules/blocks/block-related";
@import "scss/patterns/molecules/blocks/block-information";
@import "scss/patterns/molecules/blocks/block-show-more";
@import "scss/patterns/molecules/blocks/block-sorting";
@import "scss/patterns/molecules/blocks/block-shopping-cart-add-item";
@import "scss/patterns/molecules/text/abstract";
@import "scss/patterns/molecules/text/hint";
@import "scss/patterns/molecules/text/lists";
@import "scss/patterns/molecules/text/tag";
@import "scss/patterns/molecules/media/figure-caption";
@import "scss/patterns/molecules/navigation/nav-breadcrumb";
@import "scss/patterns/molecules/navigation/nav-quicklinks";
@import "scss/patterns/molecules/booking-process/block-infobox";
@import "scss/patterns/molecules/booking-process/block-order";
@import "scss/patterns/molecules/booking-process/block-options";
@import "scss/patterns/molecules/booking-process/block-payee";
@import "scss/patterns/molecules/booking-process/block-booking-footer";
@import "scss/patterns/molecules/booking-process/block-progress-steps";
@import "scss/patterns/molecules/tabs/nav-tabs";
@import "scss/patterns/molecules/maps/maps";
@import "scss/patterns/molecules/navigation/nav-direct-access";
@import "scss/patterns/molecules/navigation/nav-matrix-entry";
@import "scss/patterns/molecules/blocks/block-intro";
@import "scss/patterns/molecules/blocks/block-image-gallery";
@import "scss/patterns/molecules/media/gallery-overlay";
@import "scss/patterns/molecules/teasers/teaser-tile";
@import "scss/patterns/molecules/share/menu-share";
@import "scss/patterns/molecules/share/article-share";
@import "scss/patterns/molecules/blocks/block-share";
@import "scss/patterns/molecules/teasers/teaser-cat";
@import "scss/patterns/molecules/teasers/teaser-mucCard";
@import "scss/patterns/molecules/sections/convention";
@import "scss/patterns/molecules/sections/accommodations/trust-you-iframe";

//Embed
@import "scss/patterns/molecules/embed/video";
@import "scss/patterns/molecules/embed/teaser";
@import "scss/patterns/molecules/embed/faq";

// Organisms
@import "scss/patterns/organisms/global/header";
@import "scss/patterns/organisms/global/footer";
@import "scss/patterns/organisms/lists/list-tile";
@import "scss/patterns/organisms/lists/list-teaser-list";
@import "scss/patterns/organisms/lists/slider-intro";
@import "scss/patterns/organisms/booking-process/list-infoboxes";

// Backend
@import "scss/backend-preview";

html, * {
  scroll-behavior: smooth;
}
