.block-progress-steps {
  position: relative;
  padding: 2rem 0 4rem;
  max-width: 280px;
  margin: auto;

  @include bp('md') {
    max-width: 550px;
    padding: 2rem 0;
  }

  .list-group {
    margin-bottom: 0;
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.list-progress-steps {
  display: flex;

  .list-group__item {
    position: relative;
    margin: 0;
    justify-content: center;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 50%;

    &:after {
      content: "";
      flex-grow: 1;
      height: 3.4rem;
      margin: 0 1.5rem 0 2.5rem;
      background: url('../images/arrow-progress.svg') no-repeat right center;
      background-size: auto 1.8rem;
      opacity: 0.5;
      display: block;

      @include bp('md') {
        margin: 0 1rem 0 1rem;
      }
    }

    &:last-child {
      width: auto;

      &:after {
        display: none;
      }
    }

    .progress-step {
      border-radius: 50%;
      color: $color-white;
      background-color: rgba($color-umbra-medium, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.4rem;
      height: 3.4rem;
    }

    &.list-group__item--ok {
      .progress-step {
        color: $color-white;
        background-color: $color-green-nature;

        .icon {
          color: $color-white;
          font-size: 3rem;
          top: 0;
        }
      }

      .progress-step-label {
        color: $color-umbra-dark;
      }

      &:after {
        opacity: 1;
      }
    }

    &.list-group__item--active {
      .progress-step {
        background-color: $color-red-heart;
        color: $color-white;
        font-weight: 300;
      }

      .progress-step-label {
        color: $color-umbra-dark;
        font-weight: 700;
      }
    }
  }
  .progress-step-label {
    width: 100px;
    display: block;
    font-weight: 300;
    font-size: 1.8rem;
    color: rgba($color-umbra-medium, 0.3);
    left: 0;
    right: 0;
    margin-left: -33px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    top: 0;
    margin-top: 4rem;
    text-align: center;

    @include bp('md') {
      width: auto;
      text-overflow: inherit;
      word-break: normal;
      position: relative;
      margin-top: 0;
      margin-left: 1rem;
      text-align: left;
    }
  }
}
