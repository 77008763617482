.quote, blockquote {
  text-align: center;

  margin: ($spacer-small-xs) 0;
  @include bp('md') {
    margin: ($spacer-small-md) 0;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 6rem;
    margin: auto;
    height: 2px;
    background-color: $color-umbra-dark;

    @include bp('md') {
      width: 14rem;
    }
  }

  &:before {
    margin-bottom: 2rem;
    @include bp('md') {
      margin-bottom: 3rem;
    }
  }
  &:after {
    margin-top: 2rem;
    @include bp('md') {
      margin-top: 3rem;
    }
  }
}

.quote__text, blockquote p {
  font-size: 2.2rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.8;

  @include bp('md') {
    font-size: 2.6rem;
  }
}

.quote__footer {
  margin-top: 1rem;
  font-weight: 300;
  font-style: italic;
}


.quote--inspiration, .context-inspiration blockquote {
  color: $color-brand-inspiration;

  &:before,
  &:after {
    background-color: $color-brand-inspiration;
  }

  a {
    color: $color-brand-inspiration;

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }
}

.quote--poi, .context-poi blockquote {
  color: $color-brand-poi;

  &:before,
  &:after {
    background-color: $color-brand-poi;
  }

  a {
    color: $color-brand-poi;

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }
}

.quote--highlight, .context-highlight blockquote {
  color: $color-brand-highlight;

  &:before,
  &:after {
    background-color: $color-brand-highlight;
  }

  a {
    color: $color-brand-highlight;

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }
}

.quote--booking, .context-booking blockquote {
  color: $color-brand-booking;

  &:before,
  &:after {
    background-color: $color-brand-booking;
  }

  a {
    color: $color-brand-booking;

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }
}
