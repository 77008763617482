.block-presenter {
  overflow: hidden;
  &::before {
    padding-top: 150%;
    display: block;
    content: '';

    @include bp('sm') {
      padding-top: 66.666%;
    }

    @include bp('md') {
      padding-top: 46.875%;
    }
  }
  width: 100%;
  position: relative;

  .label {
    right: 0;
    position: absolute;
    top: 20px;
    font-size: 2.5rem;

    @include bp('md') {
      top: 40px;
      font-size: 1.8rem;
    }
  }


  .presenter-video {
    video {
      display: none;
      width: 265%;
      margin-left: -80%;

      @include bp('sm') {
        width: 119%;
        margin-left: -10%;
      }

      @include bp('lg') {
        width: 100%;
        margin-left: 0;
      }
    }

    picture {
      @include bp('xl') {
        display: none;
      }
    }
    &.showVideo {
      video {
        display: block;

        &.video-fluid-desktop {
          display: none;
        }

        &.video-fluid-mobile {
          display: block;
          width: 150%;
          margin-left: -25%;
        }
      }

      @include bp('sm') {
        video {
          &.video-fluid-desktop {
            display: block;
          }

          &.video-fluid-mobile {
            display: none;
          }
        }
      }
      picture {
        display: none;
      }
    }
  }

  @extend .t1;
}

.block-presenter__icon {
  position: absolute;
  top: 2rem;
  left: 2rem;

  @include bp('md') {
    top: 4rem;
    left: 4rem;
  }

  @include bp('lg') {
    left: 6rem;
  }
}

.block-presenter__background {
  background-color: $color-umbra-dark;
  .content-parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  picture {
    display: block;
  }
}

.block-presenter--intro {
  picture {
    @include bp('md') {
      transform:translate3d(0px, -15%, 0px);
    }
  }

  .block-presenter__container {
    @include bp('xs-only') {
      padding: 4rem 2rem 7rem;
    }
  }
}

.block-presenter__container {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 4rem 2rem 4rem;
  background: linear-gradient(rgba($black,0), rgba($black,0.8));

  @include bp('sm') {
    padding: 3rem 4rem;
  }

  @include bp('lg') {
    padding: 4rem 7rem;
  }

  @include bp('xl') {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 0 60px;
    text-align: left;
    background: none;
  }

  &.container-centered {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 3rem;
  }
}

.block-presenter__content {
  padding-top: 120px;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  color: $white;
  //text-shadow: 0 0 50px rgba($color-black, 1);

  @include bp('xl') {
    background: linear-gradient(to right, rgba($black,0.6) 0%, rgba($black,0) 60%);
  }

  &.content-right {
    .block-presenter__container {
      @include bp('xl') {
        text-align: right;
      }
    }
    @include bp('xl') {
      background: linear-gradient(to left, rgba($black,0.6) 0%, rgba($black,0) 60%);
    }
  }
}

.block-presenter__headline {
    margin-bottom: 1.5rem;
}

.block-presenter__tagline {
  @include bp('md') {
    display: block;
    margin-bottom: 1rem;
  }
}

.block-presenter__lead {

  @include bp('xl') {
    display: block;
    margin-bottom: 3rem;
  }
}

.block-presenter {
  a {
    .block-presenter__headline,
    .block-presenter__tagline,
    .block-presenter__lead,
    p {
      color: $color-white !important;
    }
  }

  .block-presenter__price-badge--highlight{
    background: $color-brand-highlight;
  }

  .block-presenter__price-badge--booking{
    background: $color-brand-booking;
  }

  .block-presenter__price-badge--poi{
    background: $color-brand-poi;
  }

  .block-presenter__price-badge--inspiration{
    background: $color-brand-inspiration;
    color:#000000;
  }
}

