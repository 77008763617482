.contact-info {
  .icon {
    font-size: 2.3rem;
    margin-right: 0.5rem;
  }

  .block-embedded {
    margin: 0 0 2rem;
  }

  strong {
    @extend .b1;
    font-weight: 400;
  }
}

.contact-info__label {
  margin-bottom: 1rem;
  display: inline-block;
  font-weight: 700;
}

.contact-info--booking {
  .icon {
    color: $color-brand-booking;
  }
}

.contact-info__content {
  @include bp('md') {
    margin-bottom: 0;
  }

  a {
    word-break: break-all;
  }
}

.contact-info__content--last-item {
  margin-bottom: 0;
}
