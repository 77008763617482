.block-booking-footer {
}

.block-booking-footer__content {
  @extend .clearfix;
}

.block-booking-footer__label {
  float: none;
  width: 100%;
  @extend .b1;
  font-weight: 700;

  @include bp('sm') {
    float: left;
    width: auto;
  }

  @include bp('sm') {
    max-width: 80%;
  }

  div:nth-child(2) {
    font-weight: 400;
  }
}

.block-booking-footer__price {
  @extend .h2;
  font-weight: 300;
  color: $color-brand-booking;
  float: none;
  text-align: left;

  @include bp('sm') {
    float: right;
    text-align: right;
  }
}

.block-booking-footer__button {
  text-align: left;
  width: 100%;
  margin-top: 5rem;

  .button {
    width: 100%;
  }

  @include bp('sm') {
    text-align: right;

    .button {
      width: auto;
    }
  }
}
