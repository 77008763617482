.sorting-block {
  ul {
    padding: 0;
    margin: 3rem 0 0;
    @include bp('md') {
      margin: 0;
    }
  }

  li {
    display: block;
    box-sizing: border-box;

    a {
      display: block;
      text-align: center;
      padding: 1.5rem 0;
      font-size: 2rem;
      font-weight: 300;

      @include bp('md') {
        padding: 1rem 0;
      }

      &.active {
        font-weight: 700;

        &:after {
          @extend .icon;
          content: icon--char(check-progress);
          display: inline-block;
          font-size: 2.5rem;
          width: 3rem;
          margin-right: -3rem;
        }
      }
    }
  }

  .icon--map-point {
    font-size: 2.5rem;
    top: -0.3rem;
  }
}
