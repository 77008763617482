.section--convention {

  .convention {
    border: 1px solid grey;
    padding-bottom: 0!important;
    margin-bottom: 2rem!important;

    &:hover {
      .convention--image {
        img {
          transform: scale(1.15);
        }
      }
    }

    @include bp('md') {
      margin-bottom: 2.5rem;
    }

    // Filter
    &--filter {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      margin-bottom: 5rem;
      padding-bottom: 3rem;

      .field {
        width: 49%;
      }
    }

    // Layout
    &--inner {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      @include bp('lg') {
        flex-direction: row;
      }
    }

    &--left, &--right {
      position: relative;
    }

    &--left {
      width: 100%;
      height: 250px;

      @include bp('lg') {
        width: 40%;
        height: unset;
      }
    }
    &--right {
      width: 100%;
      padding: 1rem 2rem;

      @include bp('lg') {
        width: 60%;
      }

      p {
        text-align: left;
      }
    }


    // Convention Image
    &--image {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        transition: all 3s ease-in;
      }
    }

    // Convention Logo
    &--logo {
      width: 75px;
      height: 75px;
      margin-left: 15px;

      img {
        width: 100%;
        height: 100%;
        object-position: left;
        object-fit: contain;
      }
    }

    // Convention Type
    &--type {
      position: absolute;
      bottom: 15px;
      left: 15px;
      padding: 10px;
      max-width: 90%;

      background-color: rgba(255,255,255,.75);
      color: $color-black;
      border-bottom: 1px solid $color-brand-poi;
      &--container {}
      &--text {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        height: 30px;
        line-height: 30px;
      }
    }

    //Date
    &--date {
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 10px;
      max-width: 90%;

      background-color: rgba(255,255,255,.75);
      color: $color-black;
      border-bottom: 1px solid $color-brand-poi;
      &--container {}
      &--text {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        height: 30px;
        line-height: 30px;
      }
    }

    &--info {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem;

      .block-list-teaser__headline {
        margin: 0;
      }

      &--text {
        p {
          //margin-bottom: 0;

          .read-more {
            border-color: $color-brand-poi;

            &:hover {
              color: $color-brand-poi;
            }
          }

          .more-text {
            display: none;
          }
        }
      }
    }

    // Facts
    &--facts {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      p, i, a {
        margin: 0;
        padding: 0;
      }

      a {
        width: 100%;
        overflow: hidden;
        max-width: 175px;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }
      }

      i {
        margin-right: 15px;
        font-size: 3rem;
      }

      &--container {
        margin-bottom: 1rem;
        width: 100%;

        @include bp('md') {
          width: 50%;
        }

        &--outer {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

  }
}
.personal-information {
  margin-top: 3rem;

  @include bp('md') {
    margin-top: 6rem;
  }
}

.personal-information, .convention-information {
  input[type=file] {
    //background: red;
    &::file-selector-button {
      //background: blue;
      //display: block;
      height: 100%;
      line-height: 1.5;
    }
  }
}
.convention-information {
  .field-checkbox {
    margin: 0 0 2rem 0;
  }

  .sub-text-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .sub-text {
    font-size: 1.5rem;
    margin: 0;
  }
}
