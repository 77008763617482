//
//Globals
// todo: better set variables

.body {
  padding-top: 8rem;
  @include bp('md') {
    padding-top: 10rem;
  }
}

.label {
  display: inline-block;
  padding: 0.7rem 2rem 0.3rem;
  font-weight: 700;
  background: $color-green-nature;
  color: $color-white;
}

.collapse {
  display: none;
  opacity: 0;
  &.show {
    opacity: 1;
    transition: opacity .2s ease-in-out;
    display: block;
  }
}

.collapsing {
  height: 0;
  opacity: 0;
  transition: height .2s ease-in-out;
  overflow: hidden;
  position: relative;
}

//
//.test {
//  @extend .icon;
//   @include icon-arrow-right();
//
//  &:hover {
//    @include icon-arrow-right2();
//  }
//}

// png based icon demo

//.icontoll {
//  position:relative;
//
//  @extend .icon;
//   @include icon-arrow-right();
//
//  display: block;
//  width: 4rem;
//  height: 4rem;
//  border-radius: 50%;
//  background-color: #ddd;
//
//  &:hover {
//    @include icon-arrow-right2();
//  }
//  &:before {
//    position: absolute;
//    font-size: 10px;
//    top: 0;
//    bottom: 0;
//    right: 0;
//    left: 0;
//    margin: auto auto;
//  }
//}

//
// formular elements
//

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

//
// links
//

a {
  color: $color-umbra-dark;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    text-decoration: none;
    color: $color-umbra-dark;
  }
}

.link,
p a,
.ezrichtext-field ul:not([class]) a,
.ezrichtext-field ol:not([class]) a {
  border-bottom: 1px solid $color-green-nature;
  transition: all 0.8s ease;
  background-color: transparent;
  padding: 0 0 0.3rem 0;

  &:hover {
    text-decoration: none;
    color: $color-green-nature;
  }

  .icon {
    font-size: 2.5rem;
    top: -0.3rem;
  }

}

.link--arrow {
  font-weight: 400;
  .icon--search-arrow {
    color: $color-green-nature;
    top: 0;
    font-size: 2rem;
    right: -0.4rem;
  }
}

@mixin link-context($color) {
  border-color: $color;

  .icon--search-arrow {
    color: $color;
  }

  &:hover {
    color: $color;
  }
}

.context-inspiration {
  p a,
  .ezrichtext-field ul:not([class]) a,
  .ezrichtext-field ol:not([class]) a {
    @include link-context($color-brand-inspiration);
  }
}

.context-poi {
  p a,
  .ezrichtext-field ul:not([class]) a,
  .ezrichtext-field ol:not([class]) a {
    @include link-context($color-brand-poi);
  }
}

.context-booking {
  p a,
  .ezrichtext-field ul:not([class]) a,
  .ezrichtext-field ol:not([class]) a {
    @include link-context($color-brand-booking);
  }
}

.context-highlight {
  p a,
  .ezrichtext-field ul:not([class]) a,
  .ezrichtext-field ol:not([class]) a {
    @include link-context($color-brand-highlight);
  }
}

.link--inspiration {
  @include link-context($color-brand-inspiration);
}

.link--poi {
  @include link-context($color-brand-poi);
}

.link--booking {
  @include link-context($color-brand-booking);
}

.link--highlight {
  @include link-context($color-brand-highlight);
}

.icon-link {
  margin: 2rem 0 2rem 1rem;
  display: inline-block;
  @extend .s4;

  .icon {
    font-size: 3rem;
  }

  @include bp('md') {
    margin: 2rem 0 4rem 3rem;
  }

  @include bp('lg') {
    margin: 2rem 0 4rem 5rem;
  }
}

//
// Containers
//

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;

  @include bp('sm') {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include bp('lg') {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.container-fluid--md {
  max-width: 970px;
}

.container-fluid--mc {
  max-width: 1160px;
}

.content-main {
  max-width: 1920px;
  margin: 0 auto;
}

.section {
  position: relative;
  overflow-x: hidden;
}

.section--content {
  background-color: #fff;

  padding: 2rem 0;
  @include bp('md') {
    padding: 4rem 0;
  }
}

//
// Lazyload States
//

.blur {
  @include bp('md') {
    filter: blur(10px);
  }
}

.blur.lazyloaded {
  @include bp('md') {
    filter: blur(0);
    transition: all 1000ms ease-out !important;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
  @include bp('md') {
    opacity: 1;
  }
}

.lazyloaded {
  opacity: 1;
  transition: all 500ms;
}

//
// images
//

.img-fluid,
.video-fluid {
  display: block;
  width: 100%;
  height: auto;
}

picture img {
  width: 100%;
  height: 100%;
}

//
// icons
//

.icon {
  // top: -0.25rem;
}

.icon--circular,
.icon--framed {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: $color-white;
  color: $color-umbra-dark;
  font-size: 2.5rem;
  line-height: 3.9rem;
  text-align: center;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  @include bp('md') {
    width: 5rem;
    height: 5rem;
    font-size: 3.3rem;

    &:before {
      top: 0.5rem;
    }
  }
}

.icon--framed {
  background: transparent;
  border: .2rem solid $color-umbra-dark;
}

//
// link list
//

.link-list {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.link-list__item {
  margin: 0;
  padding: 0;

  & + .link-list__item {
    &:before {
      content: '';
      display: block;
      width: 10rem;
      margin: 2rem auto;
      border-top: 2px solid $color-white;
    }
  }

  .item-tagline {
    display: block;
  }

  .item-link {
    @extend .b1;
    font-weight: 300;
    text-decoration: none;
    color: $color-white;
    display: block;
  }

}

//
// list group
//
.list-group {
  list-style: none;
  padding: 0;
  margin-bottom: 3rem;
}

.list-group__item {
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

//
// nav
//

.nav {
  list-style: none;
  padding-left: 0;
}

//
// helper classes
//

.block-bordered {
  border: 2px solid $color-umbra-bright;
  background-color: transparent;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.full-width {
  width: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.block-embedded {
  display: block;
  width: 100%;
  margin-bottom: ($spacer-small-xs) !important;

  @include bp('md') {
    margin-bottom: ($spacer-small-md) !important;
  }
}

p + .block-embedded,
p + script + .block-embedded {
  margin-top: ($spacer-small-xs) !important;

  @include bp('md') {
    margin-top: ($spacer-small-md) !important;
  }
}

.block-fullsize {
  width: 100vw !important;
  position: relative !important;
  margin-left: -50vw !important;
  left: 50% !important;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

/* ###################################### */
/* ####### Booking Process Styles ####### */
/* ###################################### */

.required-infos {
  margin: 1rem 0 2rem 0;
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
}

.sub-total__label, .sub-total__price {
  @extend .b1;
  font-weight: 700;
  display: block;

  @include bp('sm') {
    display: inline-block;
  }
}

.sub-total__price {
  float: none;

  @include bp('sm') {
    float: right;
  }
}

.options-counter {
  display: flex;
  align-items: baseline;
  margin-top: 2rem;

  .field-number-increment {
    display: flex;
    flex-direction: row;

    .button--circular {
      border-color: $color-umbra-medium;
      color: $color-umbra-medium;

      margin-left: 1rem;

      &:first-child {
        margin-right: 1rem;
      }

      &:hover, &:focus {
        background: $color-white;
        border-color: $color-umbra-dark;
        color: $color-umbra-dark;
      }
    }

    .increment-value {
      color: $color-umbra-medium;
      background-color: transparent;
      line-height: 4rem;
      @include bp('md') {
        line-height: 5rem;
      }
    }
  }
}

.field-number-increment {
  display: flex;
  float: right;
  flex-direction: row;

  .button--circular {
    border-color: $color-umbra-medium;
    color: $color-umbra-medium;

    margin-left: 1rem;

    &:first-child {
      margin-right: 1rem;
    }

    &:hover, &:focus {
      background: $color-white;
      border-color: $color-umbra-dark;
      color: $color-umbra-dark;
    }
  }

  .increment-value {
    color: $color-umbra-medium;
    background-color: transparent;
    line-height: 4rem;
    @include bp('md') {
      line-height: 5rem;
    }
  }
}

.options-counter__label {
  padding: 0 1rem 0 0;
  @extend .s2;
}

.block-stars {
  margin: 0 0 2rem -0.5rem;

  .icon {
    font-size: 3.5rem;
    margin-right: -1rem;
  }

  .category-text {
    font-weight: 300;
    font-style: 300;
    display: block;
    margin-top: -0.5rem;

    @include bp('md') {
      display: inline-block;
      margin-top: 0;
    }
  ;
  }
}

.block-details {
  .block-stars {
    margin-bottom: 3rem;
  }

  p {
    margin: 0;
  }

  .list-group {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin: 0;

    .list-group__item {
      width: 100%;
      margin: 0 0 1rem 0;

      @include bp('sm') {
        width: 25%;
        margin: 0 0 2rem 0;
      }

      @include bp('lg') {
        width: 20%;
      }

      &:last-child {
        width: 100%;
        display: block;
        margin-bottom: 0;
      }
    }

    .item-label {
      font-weight: 700;
      margin: 0;
    }
  }
}

.block-details__headline {
  @extend h3;
}

.block-entry-detail {
  margin-bottom: 2rem;
  text-align: center;

  .menu-meta {
    float: right;
  }

  .menu-meta__item {
    display: inline-block;

    & + .menu-meta__item {
      margin-left: 1rem;
    }
  }

  @include bp('md') {
    margin-bottom: 5rem;
  }

  .block-stars {
    margin-top: -1rem;
    margin-bottom: 1rem;

    .icon {
      font-size: 2.5rem;
      line-height: 2.5rem;
      height: 2.5rem;
      top: -0.25rem;
      margin: 0;
      color: $color-yellow-sun;
    }
    .category-text {
      display: block;
      margin-top: -0.5rem;
    }
  }
}

.block-entry-detail__headline {
  @extend .h2;
}

.block-entry-detail__address,
.block-entry-detail__distance {
  margin-bottom: 0;
  .icon {
    font-size: 3rem;
    margin-left: -0.5rem;
  }
}

.block-tile-gallery {
  margin-bottom: 6rem;

  figcaption {
    display: none;
  }
}

.list-rooms {
  list-style: none;
  margin: 0 0 5rem;
  padding: 0;
}

.list-rooms__item {
  margin: 0 0 3rem;
  padding: 0;

  @include bp('md') {
    margin: 0 0 4rem;
  }
}

.block-teaser-room {
  border: 2px solid $color-umbra-bright;
  padding: 1.5rem;

  @include bp('md') {
    padding: 3rem;
  }

  .col-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .price-container {
    display: block;
    margin-bottom: 1rem;
  }

  .price {
    color: $color-brand-booking;
    font-size: 2.2rem;
    font-weight: 400;
    display: block;

    @include bp('md') {
      font-size: 2.6rem;
      display: inline-block;
    }
  }
}

.block-teaser-room__headline {
  @extend .h5;
  flex-grow: 1;
}

.block-teaser-room__footer {
  text-align: right;
}

.room-details-container {
  border-top: 2px solid $color-umbra-bright;
  display: block;
  width: 100%;
  margin-top: 3rem;
  padding-top: 2rem;
}

.room-details__type {
  background-color: $color-umbra-bright;
  text-align: center;
  margin: 2rem 0 3rem;

  @include bp('md') {
    margin: 2rem 0 7rem;
  }
}

.room-type-list {
  @include make-row;
  max-width: 100%;

  @include bp('sm') {
    max-width: 70%;
    margin: 0 auto;
  }

  @include bp('md') {
    max-width: 60%;
  }

  @include bp('lg') {
    max-width: 40%;
  }
}

.room-type-list__item {
  @include make-col-ready();
  @include make-col(6);

  text-align: center;
  padding: 2rem;

  .icon {
    display: block;
    height: 5rem;
    line-height: 5rem;
    font-size: 5rem;

    @include bp('md') {
      height: 7rem;
      line-height: 7rem;
      font-size: 7rem;
    }
  }
}

.room-details__info {
  p {
    margin-bottom: 3rem;
  }
}

.list--two-col {
  padding-left: 0;

  @include bp('md') {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    @include bp('md') {
      flex-basis: 50%;
    }
  }
}

.room-details__footer {
  text-align: center;

  .button {
    min-width: 100%;

    @include bp('md') {
      min-width: 50%;
    }
  }
}

.block-hotel-details {

}

.block-hotel-details__headline {
  @extend .h4;
  margin-bottom: 1rem;
}

.hotel-details-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.button-collapse-toggle {
  border: 0;
  padding: 0.5rem 0;
  background: transparent;
  width: 100%;
  display: block;
  position: relative;
  text-align: left;
  font-weight: 300;

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    height: 2rem;
    vertical-align: middle;
    line-height: 2rem;
    font-size: 2.5rem;

    @include bp('md') {
      font-size: 3rem;
    }
    &:before {
      @extend .icon--arrow-up;
    }
  }

  &.collapsed {
    .icon:before {
      @extend .icon--arrow-down;
    }
  }
}

.hotel-details-list__item {
  border-top: 1px dashed $color-umbra-dark;
  border-bottom: 1px dashed $color-umbra-dark;

  & + .hotel-details-list__item {
    border-top: none;
  }


  .button-collapse-toggle {
    margin: 0;
  }

  .item-content {
    margin: 1rem 0 2rem;
    padding: 2rem;
    background-color: $color-umbra-bright;

    @include bp('md') {
      padding: 2rem 3rem;
    }

    #maps-embedded-container {
      margin-top: -2rem;
      margin-left: -2rem;
      margin-right: -2rem;
      width: auto;

      @include bp('md') {
        margin-top: -3rem;
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }

    > p {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 700;
  }

  .block-embedded {
    margin-bottom: 3rem !important;
  }
}

.list-services {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-services__item {
  display: inline-block;
  margin-right: 2rem;
  padding: 0;

  .icon {
    color: $color-white;
    background-color: $color-umbra-medium;
  }
}

/* ########################################## */
/* ####### END Booking Process Styles ####### */
/* ########################################## */

.block-presenter__video-controls {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: block;

  @include bp('xl') {
    left: 6rem;
  }
}

//
// Modal fix (Reservation)
//
.modal-body {
  background-color: $white;
}

#result-to-fade-in,
#result-list-to-scroll-to {
  display: none;
}

.block-search-bar {
  @include bp('md') {
    margin: 0 10rem;
  }
}

.block-search-result-count {
  @include bp('md') {
    margin: 0 10rem 10rem;
  }
}

.search-result-count {
  @extend .s2;
  color: $color-umbra-medium;

  .search-term {
    font-weight: 300;
    font-style: italic;
  }
  .suggest-term {
    border-bottom: 2px solid $color-umbra-medium;
  }
}

.search-set {
  border-bottom: 1px solid $color-umbra-dark;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  .icon--search {
    font-size: 3rem;
    left: -0.4rem;
  }
}

.search-set__input {
  @extend .b1;
  font-weight: 300;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-bottom: 0.5rem;

  border: none;
  background: transparent;
}

.search-set__button {
  border: none;
  background: none;

  .icon {
    font-size: 3rem;
    @include bp('md') {
      font-size: 4rem;
    }
  }
}

.block-search-result-category {
  margin-bottom: 5rem;
  .nav-item {
    @extend .h5;

    &.active,
    &:hover,
    &:focus {
      border-bottom: none !important;
    }

    &.active {
      font-weight: 700;
      border-bottom: 0.2rem solid $color-umbra-dark !important;
    }
  }

  .button-scroll {
    .icon {
      @include bp('sm-max') {
        top: -.5rem;
      }

      @include bp('md') {
        top: -.8rem;
      }
    }
  }
}

// special styles, if page is loaded in backend preview mode
.is-ez-backend-preview {
  .block-story {
    min-height: 600px !important;
    height: 600px !important;
  }

  .list-tile {
    opacity: 1 !important;
  }
}

.block-nav-layout {
  @extend .clearfix;

  padding-bottom: 3rem;
  border-bottom: 2px solid $color-umbra-medium;
  margin-bottom: 3rem;
  text-align: center;

  @include bp('md') {
    text-align: right;
  }
}

.nav-layout__item {
  display: inline-block;

  .icon {
    color: $color-umbra-medium;
    font-size: 3rem;

    @include bp('md') {
      font-size: 3rem;
    }
  }

  &--active {
    .icon {
      color: $color-brand-booking;
    }
  }
}

// skip to main content (screen readers)

#skippy {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 1.6rem;
  background-color: $color-umbra-medium;
  outline: 0;

  .skiplink-text {
    padding: .5em;
    outline: 1px solid;
  }
}

.block-video__container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .button-play {
    margin: auto;
    position: absolute;
    padding: 0;
    background: none;
    top: 0;
    right: 0;
    width: 7rem;
    height: 7rem;
    text-align: center;
    line-height: 6.6rem;
    font-size: 5rem;
    bottom: 0;
    left: 0;
    border: 2px solid $color-white;
    border-radius: 50%;
    color: $color-white;

    .icon {
      &:before {
        margin-right: -1rem;
      }
    }
  }

  video:not([controls]) {
    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none;
    }
  }

  &.video-external {
    .button-play {
      display: none;
    }
  }
}

.show-more-search-results {
  &.hidden {
    display: none;
  }
}

.block-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  @include bp('lg') {
    bottom: 7rem;
    left: 60px;
    right: 60px;
  }

  &.hidden {
    display: none;
  }
}

.block-notification--cookies {
  display: none;
}

.block-notification__content {
  padding: 2rem;
  margin: 0 auto;
  max-width: 1080px;

  border-top: solid 1px $color-umbra-dark;
  background-color: $color-white;
  position: relative;

  .button {
    position: absolute;
    right: 0;
    top: 0;
  }

  p,
  .notification-headline {
    font-size: 1.4rem;
    margin: 0;

    @include bp('sm') {
      font-size: 1.8rem;
    }
  }

  @include bp('md') {
    padding: 2rem 4rem;
  }

  @include bp('lg') {
    border: none;
    padding: 2rem 3rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  }
}

//catalog

#catalog-issuu-popup .modal-content {
  background-color: rgb(53, 53, 53);
}

#catalog-issuu-popup .modal-header {
  border-bottom: none;
  position: absolute;
  right: 0;
  z-index: 100;
}

#catalog-issuu-popup .modal-body {
  background-color: rgb(53, 53, 53);
  padding: 0;
}

#catalog-issuu-popup .modal-body iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

@media (min-width: 769px) {
  .block-list-teaser-catalog .block-list-teaser__content {
    -ms-flex: 0 0 46.66667%;
    flex: 0 0 44.66667%;
    max-width: 44.66667%;
  }
}

@media (min-width: 769px) {
  .block-list-teaser-catalog .block-list-teaser__info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 22%;
    max-width: 22%;
  }
}

.block-list-teaser-catalog .block-list-teaser__info .item-button {
  margin-bottom: 2rem;
  width: 100%;
}

.block-list-teaser-catalog .block-list-teaser__info .item-button .button {
  width: 100%;
}

.block-multicol {
  margin-bottom: 1.8rem;

  &__content {
    &__col {
      order: 2;

      @include bp('md') {
        order: unset;
      }
    }
  }

  &__image {
    margin-bottom: 2rem;

    @include bp('md') {
      margin-bottom: 0;
    }

    &__col {
      order: 1;

      @include bp('md') {
        order: unset;
      }
    }
  }
}


.abstract + .ezrichtext-field,
.abstract + .block-quicklinks + .ezrichtext-field {
  @extend .block-embedded;
}

.date-known-collapse {
  margin-right: -20px;
  margin-left: -20px;
}

.hidden-sm-down {
  @include bp('sm-max') {
    display: none !important;
  }
}

.block-offer-vacancy-form__headline {
  margin-bottom: 20px;
}

.block-offer-vacancy-form__content {
  background-color: $color-umbra-bright;
  padding: 15px 15px 30px;
  @include bp('md') {
    padding: 30px;
  }
}

.vacancy-calendar {
  background-color: $color-white;
  padding: 5px;
  margin-bottom: 20px;

  @include bp('md') {
    padding: 20px 40px;
    margin-bottom: 0;
  }
}

.vacancy-calendar__controls {
  position: relative;

  .btn-select-month {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 5px;
    line-height: 32px;
    border: none;
    background-color: $color-umbra-bright;
    border-radius: 50%;
  }

  .btn-select-month--next {
    right: 0;
  }

  .btn-select-month--prev {
    left: 0;
  }
}

.vacancy-calendar__table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  height: 100%;

  abbr {
    display: block;
    text-decoration: none;
    padding: 5px 0 0;
  }

  th, td {
    text-align: center;
    height: 100%;
    font-size: 16px;
    padding: 5px;
    @include bp('md') {
      font-size: 18px;
      padding: 2px 5px;
    }
  }

  .btn-select-day {
    width: 100%;
    height: 100%;
    border: none;
    color: $color-umbra-medium;
    background-color: $color-grey-bright;
    font-weight: 300;
    padding: 0;
    font-size: 16px;

    @include bp('md') {
      font-size: 18px;
    }
  }

  .btn-select-day__price {
    display: none;
    @include bp('md') {
      display: block;
      font-weight: 300;
      font-size: 10px;
      margin-top: -10px;
    }
  }

  .btn-select-day--available {
    color: $color-white;
    background-color: rgba($color-green-nature, .6);
    font-weight: 400;
  }

  .btn-select-day--selected {
    color: $color-white;
    background-color: $color-green-nature;
  }

  .vacancy-calendar-header {
    border-bottom: 1px solid $color-umbra-dark;
    padding: 8px 0 10px;
  }
}

.vacancy-form {
  .form-group {
    border: none;
  }

  label {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 40px;
    @include bp('md') {
      font-size: 1.8rem;
      line-height: inherit;
      margin-bottom: 0;
    }
  }

  select {
    padding: 6px;
    border: none;
    font-size: 1.8rem;
    background-color: $color-white;

    @include bp('md') {
      padding: 2px 6px;
    }
  }

  .field {
    margin-bottom: 1.5rem;
  }

  .button--check-vacancy {
    background-color: transparent;
    border-color: $color-red-heart;
    color: $color-red-heart;
    text-align: left;
    font-size: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .icon {
      float: right;
      font-size: 3rem;
      line-height: 2.3rem;
    }

    @include bp('md') {
      font-size: 1.8rem;
      width: 100%;
    }
  }
}

.vacancy-form__footer {
  border-top: 1px solid $color-umbra-dark;
  text-align: center;

  .button {
    margin-top: 15px;
    width: 100%;
  }
}

.single-vacancy-result-table {
  width: 100%;

  th, td {
    font-size: 1.6rem;
    font-weight: 700;

    @include bp('md') {
      font-size: 1.8rem;
      line-height: inherit;
      margin-bottom: 0;
    }
  }
  td {
    text-align: right;
    white-space: nowrap;
  }
}

.vacancy-result-table {
  td, th {
    padding: 0;
  }

  width: 100%;
  table-layout: auto;

  .offer-details-container {
    position: relative;
  }

  .button--toggle-info {
    &,
    &:hover,
    &:active {
      border: none;
      background-color: transparent;
      color: $color-umbra-medium;
      font-size: 1.4rem;
      font-weight: 300;
      padding: 0;

      .icon {
        font-size: 2.8rem;
        color: $color-umbra-dark;

        @include bp('md') {
          font-size: 3rem;
        }
      }

      span {
        display: none;

        @include bp('md') {
          display: inline-block;
          margin-right: 10px;
        }
      }

      &.collapsed {
        .icon {
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.vacancy-result-container__headline {
  margin-top: 30px;
}

.vacancy-result-table__header {
  th {
    line-height: 1;
    padding-top: 10px;
    font-size: 1.4rem;
    font-weight: 300;
    color: $color-umbra-medium;
  }
}

.vacancy-result-table__toggle {
  text-align: right;
}

.vacancy-result-table__price {
  color: $color-green-nature;
  font-weight: 700;
}

.vacancy-result-table__details {
  > td {
    border-bottom: 1px solid $color-umbra-dark;
  }
}

.offer-details-table {
  margin-top: 20px;
  table-layout: auto;
  width: 100%;

  tfoot {
    tr {
      border-top: 2px solid $color-umbra-dark;
    }
    td {
      font-size: 2.5rem;
      font-weight: 700;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-umbra-medium;
    }
  }

  td {
    text-align: right;
    padding: 7px 0 0;
  }

  th {
    padding: 7px 0 0;
  }
}

.offer-details-container__content {
  padding: 20px 10px 10px;
  margin: 20px 0 -1px;

  background-color: $color-white;
  position: relative;

  @include bp('md') {
    margin: 30px 0 -1px;
    padding: 30px 20px 20px;
  }

  .offer-details-footer {
    margin-top: 20px;
  }

  .offer-details-footer__submit {
    text-align: right;
    margin-bottom: 20px;
  }

  .offer-details-footer__links {
    line-height: 1.2;
    a {
      margin-right: 10px;
      font-size: 1.4rem;
      display: inline;
      font-weight: 300;
      color: $color-umbra-dark;
      border-bottom: $color-umbra-dark 1px solid;
    }

    p {
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 1.4rem;
      font-weight: 300;
      color: $color-umbra-dark;
    }
  }
}

.fullwidth-gallery {
  padding: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  .figure-caption {
    display: none;
  }

}

.block-fullwidth-gallery {
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 60px;

  @include bp('md') {
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
    margin-top: 0;
  }

  .badge-pattern,
  .widget-trustscore {
    overflow: hidden;
  }
}

.fullwidth-gallery--slider {
  opacity: 0;
  position: relatvie;

  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
  }

  figure {
    margin: 0;
  }
  .slick-arrow {
    background-color: transparent;
    position: absolute;
    top: 50%;
    z-index: 99;
    border: none;
    width: 3rem;
    height: 8rem;
    vertical-align: middle;
    margin-top: -6.5rem;
    cursor: pointer;
    overflow: hidden;

    .icon {
      background-color: transparent;
      font-size: 8rem;
      color: $color-white;
      margin-left: -3.0rem;
    }

    &.slick-prev {
      background-color: transparent;
      left: 20px;
    }

    &.slick-next {
      background-color: transparent;
      right: 20px;
    }

    &.slick-hidden {
      display: none;
    }

    &.slick-disabled {
      cursor: inherit;
      opacity: 0.3;
    }
  }

  &.slick-initialized {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
}

.block-offer-price {
  display: block;
  font-size: 2.2rem;
  text-align: right;
  font-weight: 700;
  margin-bottom: 1rem;

  @include bp('md') {
    font-size: 2.6rem;
  }
}

.block-collapsed-content {
  border-top: 1px dashed $color-umbra-dark;
  border-bottom: 1px dashed $color-umbra-dark;
  margin-bottom: 3rem;
}

.block-collapsed-content__toggle {
  position: relative;
  display: block;
  padding: 0.5rem 0;
  font-size: 1.8rem;
  line-height: 1.666;

  p {
    margin-bottom: 0;
  }

  @include bp('md') {
    font-size: 2rem;
    line-height: 1.8;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 0.4rem;
    font-size: 2.8rem;
    color: $color-umbra-dark;

    @include bp('md') {
      top: 1rem;
      font-size: 3rem;
    }
  }

  &.collapsed {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.block-collapsed-content__content {
  text-align: left;
  margin: 1rem 0 2rem;
  padding: 2rem;
  background-color: $color-umbra-bright;
  @include bp('md') {
    padding: 2rem 3rem;
  }
}

.badge-pattern {
  position: absolute;
  z-index: 1;
  bottom: 0;
  overflow: hidden;

  .badge-pattern__content {
    width: 160px;
    height: 160px;
    line-height: 25px;
    display: flex;
    padding: 0 15px 20px 25px;
    font-weight: 700;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 50%;
    margin-left: -20px;
    margin-bottom: -40px;
    background-image: url('../images/pattern-heart-yellow.svg');
    background-size: 30%;
    color: $color-umbra-dark;
    word-break: break-word;
  }

  &.badge-pattern--right {
    right: 0;

    .badge-pattern__content {
      padding: 0 25px 20px 15px;
      margin-left: auto;
      margin-right: -20px;
    }
  }
}

.block-munich-card-form__headline {
  margin-bottom: 20px;
}

.block-munich-card-form__content {
  background-color: $color-umbra-bright;
  padding: 15px 15px 30px;

  @include bp('md') {
    padding: 30px;
  }

  .form-group {
    border: none;
  }

  label {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.1;
    display: inline;

    @include bp('md') {
      font-size: 1.8rem;
      line-height: inherit;
      margin-bottom: 0;
    }
  }

  .label-container {
    @include bp('sm-max') {
      line-height: 1.1;
    }
  }

  select, input {
    padding: 6px;
    border-color: $color-white;
    background-color: $color-white;
    font-size: 1.8rem;

    @include bp('md') {
      padding: 2px 6px;
    }
  }

  .field {
    margin-bottom: 1.5rem;
  }

  .required-label {
    font-size: 1.2rem;
    font-weight: 300;
  }
}

.munich-card-form__error {
  font-weight: 700;
  color: $color-red-heart;
}

.munich-card-form__result {
  border-top: 1px solid $color-umbra-dark;
  padding-top: 10px;
  margin-top: 20px;
}

.munich-card-form__footer {
  text-align: center;
  margin-top: 20px;

  @include bp('md') {
    text-align: right;
  }
}

.munich-card-result-table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  height: 100%;

  td {
    text-align: right;
    vertical-align: bottom;
  }

  td, th {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.3;
  }
}

.block-offer-large {
  width: 100%;
  position: relative;

  &::before {
    @include bp('md') {
      padding-top: 46.875%;
      display: block;
      content: '';
    }
  }

  @include bp('md') {
    overflow: hidden;
  }

  @extend .t1;
}

.block-offer-large__img {
  background-color: $color-umbra-dark;
  position: relative;

  @include bp('md') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  picture {
    display: block;
  }
}

.block-offer-large__content {
  text-align: center;
  padding: 20px;
  position: relative;

  @include bp('md') {
    color: $color-white;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 60px 100px 0 60px;
    background: linear-gradient(to right, rgba($black, 0.6) 0%, rgba($black, 0) 60%);
  }

  @include bp('xl') {
    padding-right: 200px;
  }

  li {
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
    &:before {
      content: '* ';
    }
    &:after {
      content: ' *';
    }
  }

  ul {
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
  }

  .button {
    @include bp('md') {
      border-color: $color-white;
      color: $color-white;
      background-color: transparent;
    }

    &:hover,
    &:focus,
    &:active {
      @include bp('md') {
        border-color: $color-white;
        color: $color-white;
        background-color: $color-umbra-dark;
      }
    }
  }

  .badge-pattern {
    bottom: auto;
    top: -120px;

    @include bp('md') {
      bottom: 0;
      top: auto;
    }

    .badge-pattern__content {
      @include bp('lg') {
        width: 240px;
        height: 240px;
        margin-right: -40px;
        margin-bottom: -40px;
        font-size: 2.6rem;
        line-height: 1.3;
        padding: 0px 35px 20px 15px;
      }
    }
  }
}

.block-offer-large__headline {
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;

  @include bp('sm') {
    margin-bottom: 1.5;
  }

  @include bp('md') {
    font-size: 3.8rem;
    color: $color-white;
  }

  @include bp('lg') {
    font-size: 6rem;
  }
}


.block-offer-large__tagline {
  font-size: 1.8rem;
  display: none;
  font-weight: 400;
  @include bp('sm') {
    display: block;
  }
}
.block-offer-large__tagline__mobile {
  display: block;
  @include bp('sm') {
    display: none;
    color:#123456;
  }
}

.block-topic-large__tagline {
  font-size: 1.8rem;
  display: none;
  font-weight: 400;
  @include bp('sm') {
    display: block;
  }
}
.block-topic-large__tagline__mobile {
  display: block;
  @include bp('sm') {
    display: none;
    color:#123456;
  }
}

.block-offer__tagline {
  font-size: 1.8rem;
  display: none;
  font-weight: 400;
  @include bp('sm') {
    display: block;
  }
}
.block-offer__tagline__mobile {
  display: block;
  @include bp('sm') {
    display: none;
  }
}

.block-presenter__headline__dt {
  display: none;
  @include bp('sm') {
    display: block;
  }
}



.block-topic__tagline {
  font-size: 1.8rem;
  display: none;
  font-weight: 400;
  @include bp('sm') {
    display: block;
  }
}



.block-topic__tagline__mobile {
  display: block;
  @include bp('sm') {
    display: none;
  }
}


.block-teaser__desc__mobile {
  display: block;
  @include bp('sm') {
    display: none;
  }
}

.block-teaser__desc-long__mobile {
  display: block;
  @include bp('sm') {
    display: none;
  }
}

.block-teaser__headline__mobile {
  display: block;
  @include bp('sm') {
    display: none;
  }
}

.block-presenter__headline__mobile {
  color:#ffffff;
  display: block;
  @include bp('sm') {
    display: none;
  }
}

.block-presenter__headline__mb {
  color:#ffffff;
}

.block-teaser__desc {
  display: none;
  @include bp('sm') {
    display: block;
  }
}


.block-topic__headline {
  display: none;
  @include bp('sm') {
    display: block;
  }
}


.block-offer-header {
  padding: 60px 20px;
  background-image: url('../images/pattern-heart-red.svg');
  background-size: 60px;
  text-align: center;
  color: $color-white;
}

.block-offer-header__tagline {
  font-weight: 400;
}

.block-offer-header__headline {
  font-size: 3.4rem;
  font-weight: 700;
}

.modal--sort,
.modal--filter {
  .modal-header {
    border-color: transparent;
    width: 100%;
    justify-content: flex-end;
  }

  .modal-content {
    background-color: $color-white;
  }

  .modal-footer {
    background-color: $color-white;
  }
}

.block-header-notification {
  background-color: $color-umbra-bright;
  padding: 3rem 3rem 3rem 2rem;
  display: flex;
  width: auto;
  margin: -2rem -2rem 2rem;

  @include bp('md') {
    padding: 5rem 4rem 4rem 8rem;
    margin: 0 0 2rem;
  }
}

.block-header-notification__info {
  text-align: left;

  @include bp('md') {
    text-align: right;
  }
}

.block-header-notification__icon {
  margin-right: 1rem;

  @include bp('md') {
    margin-right: 5rem;
  }

  .icon {
    font-size: 8rem;
    color: $color-red-heart;

    @include bp('md') {
      font-size: 12rem;
    }
  }
}

.block-header-notification__headline {
  color: $color-red-heart;
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 1rem;

  @include bp('md') {
    font-size: 3.4rem;
  }
}

.block-header-notification__content {
  .link {
    color: $color-red-heart;
    border-bottom-color: $color-red-heart;

    .icon {
      color: $color-red-heart;
    }
  }
}

@mixin grid-child($col-start, $col-end, $row-start, $row-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-column: #{$col-start}/#{$col-end};
  grid-row: #{$row-start}/#{$row-end};
}

.block-insurance-item {
  background-color: $color-umbra-bright;
  padding: 2rem;
  margin-bottom: 2rem;

  @include bp('md') {
    padding: 2rem 4rem;
  }

  .field {
    margin: 0;
  }
}

.block-insurance-item__top {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.block-insurance-item__select {
  flex-grow: 1;

  .field-checkbox label {
    font-weight: 700;

    @include bp('md') {
      font-size: 2rem;
      padding-left: 3rem;
    }
  }
}

.block-insurance-item__price {
  flex-shrink: 0;
  font-weight: 700;
}

.block-insurance-item__quantity {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.block-insurance-item__label-quantity {
  flex-grow: 1;
  padding-top: 0.8rem;

  label {
    margin-bottom: 0;
    display: block;
    font-size: 1.8rem;
    font-weight: 700;

    @include bp('md') {
      font-size: 2rem;
    }
  }
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.block-insurance-item__select-quantity {
  flex-shrink: 0;

  label {
    @extend .sr-only;
  }

  select {
    background-color: $white;
  }
}

.block-insurance-item__content {
  margin-bottom: 1rem;
  ul {
    list-style-position: inside;
    padding: 0;
    margin: 0;
  }
}

.block-insurance-item__footer {
  a {
    font-size: 1.4rem;
    text-decoration: none;
    border-bottom: #2D2D28 1px solid;

    & + a {
      margin-left: 2rem;
    }
  }
}

.block-cart-item {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  margin: 0 -20px 20px;
  width: auto;
  position: relative;
  background-color: $color-umbra-bright;
  padding: 2rem 2rem 2rem 2rem;

  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;

  @include bp('md') {
    grid-template-columns: 40% 1fr;
    -ms-grid-columns: 42% 1fr;
    padding: 3rem;
    margin: 0 0 30px;

  }

  .widget-trustscore {
    display: none;

    @include bp('md') {
      display: block;
    }
  }
}

.block-cart-item__top {
  @include grid-child(1, 2, 1, 1);
  margin-top: -2.5rem;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.2rem;

  @include bp('md') {
    font-size: 16px;
  }
}

.block-cart-item__image {
  @include grid-child(1, 2, 2, 3);
  margin-right: 20px;

  @include bp('md') {
    @include grid-child(1, 2, 2, 4);
    margin-right: 30px;
  }

  .container-image {
    position: relative;
  }
}

.block-cart-item__header {
  @include grid-child(2, 3, 2, 3);
  padding-right: 2rem;

  .item-headline {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 0;

    @include bp('md') {
      font-size: 2.6rem;
    }
  }

  .item-subline {
    font-size: 1.6rem;
    font-weight: 300;
    font-style: italic;
    display: block;
  }
}

.block-cart-item__body {
  @include grid-child(1, 3, 3, 4);

  @include bp('md') {
    @include grid-child(2, 3, 3, 4);
  }
}

.block-cart-item__btn-delete {
  position: absolute !important;
  top: .5rem;
  right: .5rem;
  background-color: $color-white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 2.5rem;
}

.cart-details-table {
  margin-top: 2rem;
  table-layout: auto;
  width: 100%;

  @include bp('md') {
    margin-top: 0;
  }

  tfoot {
    tr {
      border-top: 1px solid $color-umbra-dark;
    }

    th {
      padding-right: 3rem;
    }

    td {
      white-space: nowrap;
    }
    td,th {
      font-size: 2.2rem;
      line-height: 1.23;
      font-weight: 700;
      vertical-align: bottom;

    }
  }

  tbody {
    tr {
      &:last-child {
        td,th {
          padding-bottom: 1rem;
        }
      }
    }

    td, th {
      font-size: 1.8rem;
      vertical-align: top;
    }
    td {
      font-weight: 300;
    }
  }

  td {
    text-align: right;
    padding: 7px 0 0;
  }

  th {
    padding: 7px 0 0;
  }

  .field {
    margin-bottom: 0;
  }
}

.widget-trustscore {
  position: absolute;
  z-index: 9;
  bottom: 0;
  padding: 5px 5px 0px;
  background-color: rgba($color-white, 0.95);

  iframe {
    display: block;
    width: 190px;
  }
}

.block-booking-footer__table {
  margin-top: 2rem;
  table-layout: auto;
  width: 100%;

  tbody {
    td,th {
      font-size: 2.5rem;
      line-height: 1.12;
      font-weight: 700;
      vertical-align: bottom;
      padding-top: 1rem;

      @include bp('md') {
        padding-top: 2rem;

      }
    }

    td {
      white-space: nowrap;
      text-align: right;
    }
  }

  .line-double {
    height: .5rem;
    border-top: 1px solid $color-umbra-dark;
    border-bottom: 1px solid $color-umbra-dark;

    td {
      padding: 0;
    }
  }

  tfoot {
    td {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

.block-booking-nav {
  margin: 2rem 0 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @include bp('md') {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.block-booking-container {
  @include bp('md') {
    padding: 0 6rem;
  }

  .block-infobox {
    margin-bottom: 3rem;
  }
}

.block-bookable-item {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  margin: 0 0 3rem;
  width: auto;
  position: relative;

  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;

  @include bp('md') {
    grid-template-columns: 40% 1fr;
    -ms-grid-columns: 42% 1fr;
  }

  .widget-trustscore {
    display: none;

    @include bp('md') {
      display: block;
    }
  }

  &:last-child {
    margin: 0;
  }
}

.block-bookable-item__image {
  @include grid-child(1, 2, 1, 2);
  margin: 0 2rem 2rem 0;

  @include bp('md') {
    @include grid-child(1, 2, 1, 3);
    margin: 0 3rem 0 0;
  }

  .container-image {
    position: relative;
  }
}

.block-bookable-item__header {
  @include grid-child(2, 3, 1, 2);

  @include bp('md') {
    display: flex;
  }

  .item-container {
    flex-grow: 1;
  }

  .item-headline,
  .item-price {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 0;

    @include bp('md') {
      font-size: 2.6rem;
    }
  }

  .item-subline {
    font-size: 1.6rem;
    font-weight: 300;
    font-style: italic;
    display: block;
  }

  .item-price {
    @include bp('md') {
      white-space: nowrap;
      text-align: right;
      margin-left: 2rem;
    }
  }
}

.block-bookable-item__body {
  @include grid-child(1, 3, 2, 3);

  @include bp('md') {
    @include grid-child(2, 3, 2, 3);
  }

  .block-collapsed-content {

    @include bp('sm-max') {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

.block-bookable-item__collapse {
  @include grid-child(1, 3, 3, 4);

  @include bp('md') {
    @include grid-child(1, 3, 3, 4);
  }

  .block-collapsed-content {
    border-top: none;
    margin-bottom: 1rem;

    @include bp('md') {
      border: none;
    }
  }
}

.block-bookable-item__footer{
  @include grid-child(1, 3, 4, 5);
  text-align: center;

  @include bp('md') {
    @include grid-child(2, 3, 4, 5);
    display: flex;
    text-align: left;
  }

  .bookable-item-conditions {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 2rem;

    p {
      margin: 0;
    }

    @include bp('md') {
      padding-right: 2rem;
      margin-bottom: 0;
      flex-grow: 1;
    }
  }

  .bookable-item-button {
    @include bp('md') {
      align-self: flex-end;
      flex-shrink: 0;
    }
  }
}

.bookable-details-table {
  margin-top: 1;
  table-layout: auto;
  width: 100%;

  tfoot {
    tr {
      border-top: 1px solid $color-umbra-dark;
    }

    th {
      padding-right: 3rem;
    }

    td {
      white-space: nowrap;
    }
    td,th {
      font-size: 2.2rem;
      line-height: 1.23;
      font-weight: 700;
      vertical-align: bottom;

    }
  }

  .price {
    font-weight: 700;
  }

  tbody {
    tr {
      &:last-child {
        td,th {
          padding-bottom: 1rem;
        }
      }
    }

    td, th {
      font-size: 1.8rem;
      vertical-align: top;
    }
    td {
      font-weight: 300;
    }
  }

  td {
    text-align: right;
    padding: 7px 0 0;
  }

  th {
    padding: 7px 0 0;
  }

  .field {
    margin-bottom: 0;
  }
}

.block-collapsed-content-list {
  .block-collapsed-content {
    margin-bottom: -1px;

  }
}


.block-cart-contact {
  margin: 0 -20px 20px;
  width: auto;
  position: relative;
  background-color: #E0E0E3;
  padding: 2rem;

  @include bp('md') {
    padding: 3rem;
    margin: 0 0 30px;
  }
}

.block-cart-contact__btn-edit {
  position: absolute !important;
  top: .5rem;
  right: .5rem;
  background-color: $color-white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 2.5rem;
}

.block-cart-contact__label {
  @extend .h5;
  font-weight: 700;
  margin-bottom: 0;
}


.block-description-list__item {
  margin-bottom: ($spacer-small-xs);

  @include bp('md') {
    margin-bottom: 4rem;
  }

  &:last-child {
    margin-bottom: none;
  }
}

.hidden {
  display: none !important;
}

.vacancy-result-error {
  font-weight: 700;
  color: $color-red-heart;
  font-size: 1.6rem;
  text-align: center;
}

.cart-item-conditions {
  font-size: 1.2rem;
  line-height: 1.6;

  p {
    margin-bottom: 0;
  }
}

.block-link {
  text-align: center;

  @include bp('md') {
    text-align: right;
    padding-right: 4rem;
  }
}

.block-insurance-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.block-insurance-description__image {
  margin-right: 2rem;

  @include bp('md') {
    margin-right: 5rem;
  }


  img {
    width: 6rem;
    height: auto;

    @include bp('md') {
      width: 12rem;
    }
  }
}


.block-insurance-description__text {
  p {
    margin: 0;
    font-size: 1.8rem;
  }
}

.info-tooltip {
  position: relative;

  &:hover {
    .info-tooltip__content {
      opacity: 1;
      display: block;
      animation: display-none-transition 1s both;
    }
  }
}

.info-tooltip__icon {
  color: $color-white;
  font-family: Arial;
  background-color: $color-red-heart;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  display: inline-block;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.info-tooltip__content {
  position: absolute;
  background-color: $color-red-heart;
  color: $color-white;
  opacity: 0;
  display: none;
  animation: display-none-transition 500ms both;
  z-index: 999;
  padding: 10px;
  font-size: 12px;
  line-height: 1.2;
  left: -30px;
  width: 50vw;

  @include bp('md') {
    width: 250px;
  }

  &:after {
    top: -4px;
    left: 31px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $color-red-heart;
    border-width: 8px;
    margin-top: -8px;
  }
}

@keyframes display-none-transition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.field-date {
  position: relative;
}

.disabled-overlay {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.looks-like-h1 {
  font-size: $font-size-h1;
  line-height: 1.14;

  @include bp('md') {
    font-size: 3.8rem;
    line-height: 1.2;
  }

  @include bp('xl') {
    font-size: 6rem;
    line-height: 1.2;
  }
}
.looks-like-span {
  @extend .t1;
  font-weight: $font-weight-normal;
}
