.block_matrix_entry {
  @include bp("xs-only") {
    margin-top: -5rem;
  }
}

.block-matrix-entry {
  background-color: $color-umbra-bright;
  .container-fluid {
    @include bp("xs-max") {
      position: relative;
      max-width: 100%;
      height: 15rem;
      overflow: hidden;
      padding: 0;
    }
  }
}

.list-matrix {
  list-style: none;
  padding: 3rem 0 0;
  opacity: 0.5;

  @include bp("xs-max") {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: 3rem;
    padding: 0 0 2rem 7rem;
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  @include bp("sm") {
    @include make-row();
    padding: 1rem 0;
  }

  &.visible {
    padding-left: 2rem;
    opacity: 1;
    transition: 0.5s all ease-out;

    @include bp("md") {
      padding-left: 0;
    }
  }
}

.list-matrix__item {
  vertical-align: top;

  @include bp("sm") {
    @include make-col-ready();
    @include make-col(3);
  }
}

.matrix-item {
  font-weight: 400;
  color: $color-umbra-dark;
  text-align: center;
  display: block;
  text-decoration: none;

  @include bp("xs-max") {
    max-width: 10rem;
    margin: 2rem 1.2rem;
  }

  @include bp("md") {
    margin: 1rem 0;
  }
}

.matrix-item__icon {
  height: 8rem;

  @include bp("sm") {
    height: 6rem;
    margin: 0 auto;
  }
}

.matrix-item__text {
  @include bp("sm-max") {
    font-size: 1.4rem;
  }

  display: block;
}

@media (max-width: 575px) {
  .container-fluid--matrix-height {
    height: 30rem !important;
  }
  .list-matrix {
    overflow: hidden;
    flex-wrap: wrap;
    padding-left: 0 !important;
  }
  .list-matrix__item {
    width: 25%;
  }
  .matrix-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 530px) {
  /*   .matrix-item__text {
    max-width: 100px;
    word-wrap: break-word;
    hyphens: auto;
  } */
}

@media (max-width: 400px) {
  .matrix-item__text {
    font-size: 1.2rem;
  }
}
