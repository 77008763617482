.item-price {
  strong {
    font-size: 2.5rem;
    font-weight: 700;
  }
}

.block-list-teaser {
  position: relative;
  @extend .t1;

  .item-price-headline {

  }
}

.block-list-teaser__content {
  text-align: center;

  @include bp('md') {
    text-align: left;
  }

  .block-collapsed-content {
    margin-top: 2rem;
  }
}

.block-list-teaser__image-container {
  text-align: center;
  position: relative;
  margin: 0;
  width: auto;

  @include bp('md') {
    margin: 0 8px 0 0;
  }
}

.block-list-teaser__container {
  padding-top: 2rem;

  @include bp('md') {
    padding-top: 0;
    margin-left: 8px;
  }

  .item-available {
    color: $color-brand-booking;
    display: inline-block;
  }

  .block-stars {
    margin-top: -1rem;
    margin-bottom: 1rem;

    .icon {
      font-size: 2.5rem;
      line-height: 2.5rem;
      height: 2.5rem;
      top: -0.25rem;
      margin: 0;
      color: $color-yellow-sun;
    }
  }

  .item-address {
    margin-bottom: 0.5rem;
    .icon {
      font-size: 3rem;
      margin-left: -0.5rem;
    }
    @include bp('md') {
      display: flex;
    }
  }

  .item-distance {
    margin-bottom: 2rem;
    .icon {
      font-size: 3rem;
      margin-left: -0.5rem;
    }
    @include bp('md') {
      display: flex;
    }
  }
}

.block-list-teaser__tagline {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.block-list-teaser__headline {
  @extend .h2;
  margin-bottom: 1.2rem;

  a,
  a:hover,
  a:focus {
    border: none;
  }
}

.block-list-teaser__button {
  .item-button {
    margin-bottom: 2rem;
  }
}

.list-teaser-list--package {
  .block-list-teaser__toggle {
    margin-bottom: 2rem;
    text-align: right;
    .button {
      background-color: transparent;
      border-color: $color-red-heart;
      color: $color-red-heart;
    }
  }

  .block-list-teaser__toggle-container {
    .block-offer-vacancy-form {
      margin-bottom: 2.5rem;
    }
  }

  .block-collapsed-content {
    text-align: left;
  }
}

.block-list-teaser__toggle-container {
  .block-munich-card-form {
    margin-top: 2rem;
  }
}

.list-teaser-list--booking {
  .block-collapsed-content {
    display: none;
  }
}

