.block-date-filter {
  background-color: $color-umbra-bright;
  padding: 2rem;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  width: auto;

  @include bp('md') {
    margin-left: 0;
    margin-right: 0;
  }

  .trigger {
    border: none;
    position: absolute;
    right: 0;
    top: 0;

    &.collapsed {
      .icon {
        @extend .icon--edit;
      }
    }
  }

  .text-mandatory {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 0;

    @include bp('md') {
      text-align: left;
      margin-top: 1rem;
    }
  }

  .button--circular {
    border: none;
    width: 3.4rem;
    height: 3.4rem;
    line-height: 3.4rem;
    right: .8rem;
    top: 2.3rem;
    position: absolute;

    @include bp('md') {
      display: none;
    }
  }

  .reservation {
    & > button {
      @include bp('md') {
        margin-top: 2rem;
        float: right;
      }
    }
  }

  .form-item > p {
    text-align: center;
    font-size: 1.4rem;
    @include bp('md') {
      text-align: right;
    }
  }

  .collapse {
    @include bp('md') {
      opacity: 1;
      transition: opacity .2s ease-in-out;
      display: block;
    }
  }

  input, select {
    background-color: $color-white;
    padding: 3px 6px;
    height: 3.7rem;

    @include bp('md') {
      height: 4rem;
    }
  }

  .field-select,
  .field-date {
    label {
      font-weight: 700;
      font-size: 1.8rem;
    }
  }

  .field {
    @include bp('md') {
      margin: 0;
    }
  }

  .field-checkbox {
    @include bp('md') {
      margin-bottom: 1rem;
    };
  }
}

.reservation-content {
  @include bp('md') {
    display: flex;
  }
}

.reservation-content__form {
  @include bp('md') {
    flex-grow: 1;
  }
}

.reservation-content__footer {
  display: flex;
  flex-direction: column;
  position: relative;

  @include bp('md') {
    margin-left: 20px;
    flex-shrink: 0;
  }

  .button {
    margin: 1rem 0;
    align-self: center;

    @include bp('md') {
      margin: auto;
    }
  }

  p {
    flex-grow: 1;

    @include bp('md') {
      padding-top: 3.3rem;
    }
  }
}

.date-filter-dummy {
  margin-right: 3rem;

  .row + .row {
    margin-top: 1rem;
  }

  @include bp('md') {
    display: none;
  }
}

.date-filter-dummy_label {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 40px;
}

.date-filter-dummy_date-field,
.date-filter-dummy_select-field {
  display: block;
  background-color: $color-white;
  padding: 6px;
  position: relative;
  font-weight: 300;

  .icon {
    position: absolute;
    right: 0;
    height: 2rem;
    top: 0;
    bottom: 0;
    margin: auto .5rem;
  }
}

.pik-container {
  .pika-single {
    @include bp('md') {
      margin-top: 0.5rem !important;
    }
  }
}

.children-container {
  .field {
    @include bp('md') {
      margin-top: 1rem;
    }
  }
}
