.teaser_category {
  //overflow: scroll;

  @include bp('md') {
    max-height: 65vh;
    //overflow: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__block-embedded {

    @include bp('md') {
      margin-bottom: 4.5rem!important;
    }

    &::-webkit-scrollbar {
      display: none;
      background: transparent;
      width: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::before {
      //padding-top: 150%;
      //display: block;
      //content: '';
      //

      padding-top: 100vw;

      @media only screen and (min-width: 500px) and (max-width: 768px) {
        padding-top: 50%;
      }

      @include bp('md') {
        padding-top: 30%;
      }
    }
  }


  > a {
    display: block;
  }

  &__double-arrow {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    > svg {
      width: 25px;
      height: 25px;
      display: block;

      animation-name: bounce-arrow;
      animation-timing-function: ease-in-out;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;
      transform-origin: bottom;

      z-index: 998;
      cursor: pointer ;
    }

    &.light {
      > svg {fill: #ffffff;}
    }

    &.dark {
      > svg {fill: #2D2D28;}
    }

    &__wrapper {
      display: block;
      position: absolute;
      width: 100%;
      height: 25px;
      bottom: 10px;
    }
  }

  .block-presenter {
    &__container {
      text-align: center;

      @media only screen and (min-width: 769px) {
        height: 100%;
      }

      a {
        display: block;
        width: 100%;

        @media only screen and (min-width: 769px) {
          height: 100%;
        }


        > .row {
          justify-content: center;

          @media only screen and (min-width: 769px) {
            height: 100%;
          }

          > div {
            justify-content: flex-end;
            bottom: 20%;

            @media only screen and (min-width: 769px) {
              height: 100%;
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
            }
          }

          //position: relative;
          //overflow: hidden;
          //
          //@include bp('md') {
          //  overflow: unset;
          //}
        }

        .scrollable__container {
          height: unset;
          overflow-y: scroll;
          overflow-x: hidden;
          margin-bottom: 10px;
          //max-height: 100%;

          &::-webkit-scrollbar {
            display: none;
            background: transparent;
            width: 0;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          .ezrichtext-field {

            @media only screen and (max-width: 1024px) {
              //position: absolute;

              max-height: 200px;
              width: 100%;

              bottom: 0;
              left: 0;
            }
          }
        }
      }
      .row {
        justify-content: center;

        @media only screen and (min-width: 769px) {
          height: 100%;
        }

        > div {
          justify-content: flex-end;
          bottom: 20%;

          @media only screen and (min-width: 769px) {
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
          }
        }

        //position: relative;
        //overflow: hidden;
        //
        //@include bp('md') {
        //  overflow: unset;
        //}
      }
    }
  }

  &.block-presenter__content {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 60%);
    //background: linear-gradient(to top, blue 0%, red 60%);
  }
}

.scrollable__container {
  margin-bottom: 8rem;

  .ezrichtext-field > p {
    text-align: center;
  }
}



//Animation Keyframes
@keyframes bounce-arrow {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}
