.calender {
  &--container {
    padding: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &--outer {
      display: block;
      background-image: url('https://www.muenchen.travel/var/ger_muc/storage/images/4/0/8/5/1665804-1-ger-DE/Christkindlmarkt-auf-dem-Marienplatz-1746s-Foto-Sigi-Mueller.jpg');
      background-size: cover;
      background-position: center center;
    }
  }

  &--entry {
    //padding: .5rem;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative;

    &.inactive {
      cursor: not-allowed;
      border: 1px solid #fff;
      background-color: rgba(0,0,0,.3);
      pointer-events: none;
    }
    &.active {
      color: white;
      cursor: pointer;
      border: 1px solid #fff;
      background: rgba(255,255,255,.45);
      pointer-events: all;
      transition: background .35s ease-in;
      span {
        filter: drop-shadow(2px 4px 6px black);
      }

      &:hover {
        background: rgba(255,255,255,.75);
      }
    }

    &--wrapper {
      height:0;
      width: calc(25% - 20px);
      padding-bottom: calc(25% - 20px);
      margin: 10px;

      &.inactive {
        cursor: not-allowed;
      }
      &.active {
        cursor: pointer;
      }
    }

    span {
      font-weight: bold;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 5vw;
    }
  }
}
