.menu-share {
  &.dropdown {
    position: relative;
    .dropdown-menu {
      padding: 1rem;
      background: #fff;
      min-width: 30rem;
      border: 2px $color-umbra-bright solid;
      position: absolute;
      display: none;
      z-index: 99;
      right: 0;
      transition: all 0.2s ease;
      > * {
        transition: all 0.2s ease;
        opacity: 0;
        transform: translateY(-20px);
      }
    }
    &.show {
      .dropdown-menu {
        //visibility: visible;
        //opacity: 1;
        display: block;

        > * {
          transition-delay: 2s;
          opacity: 1;
          transition: all 0.2s ease;
          transform: translateY(0px);
        }
      }
    }
    &.dropup {
      .dropdown-menu {
        bottom: 5.2rem;
        top: auto;
        > * {
          transition-delay: 2s;
          opacity: 0;
          transition: all 0.2s ease;
          transform: translateY(-20);
        }
      }
      &.show {
        .dropdown-menu {
          //visibility: visible;
          //opacity: 1;
          display: block;

          > * {
            transition-delay: 2s;
            opacity: 1;
            transition: all 0.2s ease;
            transform: translateY(0px);
          }
        }
      }
    }
  }
}

.list-share {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.col-4 {
      display: block;
      max-width: 100%;
    }
  }

  .shariff-button {
    white-space: nowrap;
    margin: 0;
    padding: 0;

    a {
      padding: 0.5rem;
      display: block;
    }
  }

  .fa {
    @extend .icon;
    @extend .icon--framed;

    display: inline-block;
    margin-right: 2rem;
  }

  .fa-facebook {
    @extend .icon--s-facebook;
  }

  .fa-twitter {
    @extend .icon--s-twitter;
  }

  .fa-whatsapp {
    @extend .icon--s-whatsapp;
  }

  .fa-envelope {
    @extend .icon--s-mail;
  }

}

li.shariff-button.whatsapp {
  @include bp('md') {
    display: none;
  }
}
