.section--interaction {
  //background-color: lightgrey;
  padding: 0;
  //margin-top: -3rem;
  background-color: unset!important;

  &.accomodation-share {
    margin-top: 3rem;
  }

  .interaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: background-color .2s linear;

    &:hover {
      background-color: #F4F4F4;
    }

    @include bp('md') {
      margin: 0 2rem;
    }

    &.bookmark {
      //background-color: red;
    }
    &.print {
      //background-color: blue;
    }
    &.share {
      //background-color: green;
    }

    // Layout
    &--container {}
    &--row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }

    &--text {
      font-size: 1rem;
      text-align: center;
    }

    .icon {
      font-size: 3.5rem;
    }

    // Layer
    &--layer {

      &--container {
        display: none;
        background-color: #fff;
        box-shadow: 1px 1px 100px black;
        position: fixed;
        top: 50%;
        left: 50%;
        width: 75%;
        max-width: 600px;
        padding: 2rem;
        padding-bottom: calc(2rem + 20px);
        transform: translateX(-50%);
        z-index: 1000;
      }
    }

    &--close {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      z-index: 1001;

      &--container {
        width: 100%;
      }

      .icon {
        cursor: pointer;
      }
    }

    &--share {
      width: 90px;

      &--link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin: 0 1rem;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          background-color: #F4F4F4;
        }
      }

      &.facebook {
        //background-color: magenta;
      }
      &.twitter {
        //background-color: cyan;
      }
      &.whatsapp {
        //background-color: yellow;
      }

      &.copyUrl {
        width: unset;

        .interaction--share--link {
          flex-direction: row;
          border: 1px solid $color-white;

          i {
            margin-right: 15px;
          }

          &.copied {
            border-color: $color-brand-booking;
            padding-right: 40px;
            position: relative;

            &:after {
              content: '\E015';
              font-family: 'lhm-icons';
              display: block;
              width: 20px;
              height: 20px;
              line-height: 20px;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              color: $color-brand-booking;
            }
          }
        }
      }
    }
  }
}
