@mixin bp($breakpoint) {
  @if $breakpoint == "xs" {
    @media (min-width: map-get($grid-breakpoints, xs)) {
      @content;
    }
  }

  @if $breakpoint == "xs-only" {
    @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm) - 1) {
      @content;
    }
  }

  @if $breakpoint == "xs-max" {
    @media (max-width: map-get($grid-breakpoints, sm) - 1) {
      @content;
    }
  }

  @if $breakpoint == "sm" {
    @media (min-width: map-get($grid-breakpoints, sm)) {
      @content;
    }
  }

  @if $breakpoint == "sm-only" {
    @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
      @content;
    }
  }

  @if $breakpoint == "sm-max" {
    @media (max-width: map-get($grid-breakpoints, md) - 1) {
      @content;
    }
  }

  @if $breakpoint == "md" {
    @media (min-width: map-get($grid-breakpoints, md)) {
      @content;
    }
  }

  @if $breakpoint == "md-only" {
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
      @content;
    }
  }

  @if $breakpoint == "md-max" {
    @media (max-width: map-get($grid-breakpoints, lg) - 1) {
      @content;
    }
  }

  @if $breakpoint == "lg" {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      @content;
    }
  }

  @if $breakpoint == "lg-only" {
    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
      @content;
    }
  }

  @if $breakpoint == "lg-max" {
    @media (max-width: map-get($grid-breakpoints, xl) - 1) {
      @content;
    }
  }

  @if $breakpoint == "xl" {
    @media (min-width: map-get($grid-breakpoints, xl)) {
      @content;
    }
  }
}
