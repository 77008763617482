.show-more {
  text-align: left;
  margin-bottom: 0rem;
  margin-top: 1.5rem;

  a {
    padding: 0.5rem 0;
    border-bottom: 1px solid $color-umbra-dark;

    .icon {
      margin-left: 0rem;
      font-size: 2.2rem;
    }

    &.active {
      .icon {
        @extend .icon--show-less;
      }
    }
  }
}

.show-more--inspiration, .show-more--poi,
.show-more--booking, .show-more--highlight {
  a {
    &:hover {
      color: inherit;
    }
  }
}

.show-more--inspiration {
  a {
    color: $color-brand-inspiration;
  }
}

.show-more--poi {
  a {
    color: $color-brand-poi;
  }
}

.show-more--booking {
  a {
  }
}

.show-more--highlight {
  a {
    color: $color-brand-highlight;
  }
}

.show-more-container {
  text-align: center;

  .icon {
    margin-right: .5rem;
    font-size: 2.0rem;
    margin-left: -0.2rem;
  }

  .show-more-results-link {
    font-weight: 400;
    font-size: 1.8rem;
    color: $color-umbra-dark;
    background-color: transparent;
    border-bottom: 1px solid $color-umbra-dark;
    margin: 0;
    padding: 0.4rem 0;

    &:hover {
      color: inherit;
    }

    &:focus {
      border: none;
      outline: none;
    }

  }
}
