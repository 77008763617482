.reservation {
  .form-item__inline {
    label,
    .input-group {
      display: inline-block;
    }
    .input-group {
      float: right;
    }
    label {
      vertical-align: middle;
    }
    button,
    input {
      display: inline-block;
    }
    input {
      color: $color-umbra-medium;
      @extend .s2;
      //max-width: 30px;
      width: 2.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0 1px;
      text-align: center;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      &:focus{
        outline: none;
      }
    }

    .button--circular {
      border-color: $color-umbra-medium;
      color: $color-umbra-medium;

      &:hover, &:focus {
        background: $color-white;
      }

      &:hover, &:focus {
        border-color: $color-umbra-dark;
        color: $color-umbra-dark;
      }
    }
  }

  #date2 {
    cursor: pointer;
  }

  .button--booking {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;

    @include bp('md') {
      float: right;
      display: inline-block;
    }
  }

}

#date2,
#date1 {
  cursor: pointer;
}

.ageselector-element {
  margin: 1rem 0;
  span {
    position: relative;
    i {
      color: $color-umbra-medium;
      position: absolute;
      right: 1rem;
      top: -0.5rem;
      font-size: 3rem;
      pointer-events: none;
    }
  }
}

.reservation--direct-access {
  .button--submit {
    margin-top: 8rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    float: none;
  }

  .divider {
    padding: 1rem 0;
  }

  .label-inline {
    padding: 0.9rem 0;
    margin: 0;
  }

  .form-group {
    border-bottom: none;
  }
}
