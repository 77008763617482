
.breadcrumb {

  @include bp('sm-max') {
    display: none;
  }

  position: relative;

  &:before, &:after {
    position: absolute;
    top: 0;
    height: 2rem;
    width: 2rem;
    content: " ";
    z-index: 1;
  }

  &:before {
    background: linear-gradient(to left, rgba($color-white,0), rgba($color-white,1));
    left: 0;
  }

  &:after {
    background: linear-gradient(to right, rgba($color-white,0), rgba($color-white,1));
    right: 0;
  }
}

.breadcrumb__list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  list-style: none;
  padding-left: 2rem;
  margin: 1.5rem -2rem 1.5rem -2rem;
}

.breadcrumb__item {
  display: inline-block;
  @extend .s4;
  white-space: nowrap;


  .icon {
    margin-left: 0.2rem;
  }

  &.active {
    padding-right: 2rem;

    @include bp('md') {
      padding-right: 0;
    }

    span {
      color: $color-umbra-medium;
    }
  }

  &:first-child .sr-only,
  &:first-child .icon {
    display: none;
  }
}

.breadcrumb__link {
  text-decoration: none;
}

[aria-label="breadcrumb"] .sr-only {
  content: "/";
}

.breadcrumb--booking {
  .icon {
    color: $color-brand-booking;
  }
}

.breadcrumb--inspiration {
  .icon {
    color: $color-brand-inspiration;
  }
}

.breadcrumb--poi {
  .icon {
    color: $color-brand-poi;
  }
}

.breadcrumb--highlight {
  .icon {
    color: $color-brand-highlight;
  }
}
