.list-teaser-list {
  list-style: none;
  padding: 0;

  .block-embedded {
    margin: 0;
  }
}

.list-teaser-list--results {
  .list-teaser-list__item {
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;

    @include bp('md') {
      padding-bottom: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
