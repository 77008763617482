.header-global {
  position: fixed;
  background-color: $color-white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: solid 1px $color-umbra-dark;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  max-width: 1920px;
  margin: auto;
  height: 8rem;
  @include bp('md') {
    height: 10rem;
  };

  > .container-fluid {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  &.hide {
    top: -10rem;
    opacity: 0;
  }
}

.dropdown-open {
  .header-global.hide {
    top: 0;
    opacity: 1;
  }
  .nav-direct-access.hide {
    bottom: 0;
    opacity: 1;
  }
}

.nav-main-header {
  display: flex;
  justify-content: space-between;
  font-size: 2.5rem;
  line-height: 1;
  padding: 2rem;
  margin-bottom: 1rem;

  @include bp('md') {
    display: none;
  }
}

.nav-main-header__weather {
  font-weight: 300;
  .icon {
    font-size: 3.8rem;
    line-height: 2.5rem;
    margin-left: 0.5rem;
  }
}
.nav-main-header__language {
  margin-left: auto;
  display: flex;

  .item-language {
    display: flex;
    margin: 0;
    padding: 0;

    &:after {
      content: "\002F";
      margin: 0 .5rem;
    }

    &:last-child:after {
      content: "";
      margin: 0;
    }
  }

  .link-language {
    font-weight: 300;
    display: block !important;
    padding: 0;

    &.active {
      font-weight: 700;
    }
  }
}

.nav-bar {
  .button--icon-only {
    color: $color-umbra-dark;
    text-align: center;
    border: none;
    line-height: 6rem;
    padding: 1.5rem 0.5rem 0.5rem;
    margin: 0;

    @include bp('md') {
      font-size: 2.0rem;
      line-height: 5rem;
      padding: 0;
      margin: 2.5rem 1rem;
    }

    .icon {
      display: inline-block;
      font-size: 4rem;
      line-height: 1;

      @include bp('md') {
        font-size: 3rem;
      }

      &.icon--webcam-2 {
        padding-bottom: 2px;
      }
    }
  }

  .button-cart {
    position: relative;

    &.has-cart-items {
      &:before {
        content: attr(data-count);
        background-color: $color-umbra-dark;
        font-weight: 700;
        color: $color-white;
        display: block;
        position: absolute;
        border-radius: 50%;
        font-size: 0.9rem;
        line-height: 1.4;
        padding: 0.15rem;
        width: 1.4rem;
        text-align: center;
        height: 1.4rem;
        top: 2.6rem;
        left: 0;
        right: 0;
        margin: auto;

        @include bp('md') {
          font-size: 1rem;
          width: 1.6rem;
          height: 1.6rem;
          top: 0.5rem;
        }
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      background: #fff;
      position: absolute;
      visibility: hidden;
      left: 0;
      right: 0;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0px);
      -webkit-transform: translate3d(0,0,0);
      -webkit-perspective: 1000;
      bottom: 0;
      z-index: 9999999999;
      opacity: 0;
      transition: all 0.2s ease-out;
      height: calc(100vh - 8rem);
      min-height: 101%;
      top: 8rem;

      > .container-fluid,
      > li {
        opacity: 0;
        transform: translateY(-30px);
        transition: none;
      }

      @include bp('md') {
        height: calc(100vh - 10rem);
        top: 10rem;
        padding: 10rem 0;
      }

      .button--close {
        position: absolute;
        top: 0;
        right: 1rem;

        @include bp('md') {
          display: none;
        }
      }
    }
    &.show {
      .dropdown-menu {
        visibility: visible;
        opacity: 1;
        > .container-fluid,
        > li {
          transition: all 0.4s ease-out;
          transition-delay: 0.2s;
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

.nav-bar__logo {
  order: 1;
  display: flex;
  flex: 2;
  height: 100%;
}

.nav-bar__weather {
  display: flex;
  flex: 1;
  justify-content: center;
  order: 2;
  height: 100%;

  @include bp('sm-max') {
    display: none;
  }

  .icon[class*="icon--w-"] {
    color: $color-umbra-medium;
  }
}

.nav-main__subnav {
  margin-bottom: 2rem;
  a {
    font-size: 2rem;
  }
}

.nav-bar__nav {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  order: 3;
  margin-right: -1rem;

  @include bp('md') {
   // margin-right: -2rem;
  }

  > .nav-item {

    @include bp('sm-max') {
      display: none;
    }

    &.nav-item--language {
      @include bp('sm-max') {
        display: none;
      }
    }

    &.nav-item--nav-main {
      order: 2;
    }
  }

  .nav-item--nav-main {
    .button--toggle {
      .icon {
        @extend .icon--menue;
      }
    }
    &.show {
      .button--toggle {
        .icon {
          @extend .icon--close;
        }
      }
    }
  }

  .nav-item--search {
    .block-search-bar {
      margin-top: 4rem;
      @include bp('md') {
        margin-top: 5rem;
      }
    }
  }
}

.logo-global {
  a {
    display: block;
    background: url('../images/logo-muc-color.svg') no-repeat center;
    background-size: contain;
    background-size: 100%;
    width: 10rem;
    height: 7rem;
    margin: 0.3rem 0 0.7rem;
    text-indent: -9999999px;

    @include bp('md') {
      width: 15rem;
      height: 8rem;
      margin: .7rem 0 1.3rem;
    }
  }
}

.logo-global--en{
  a {
    background: url('../images/logo-muc-color_en.svg') no-repeat center;
    background-size: contain;
  }
}

.logo-global--it {
  a {
    background: url('../images/logo-muc-color_en.svg') no-repeat center;
    background-size: contain;
  }
}

.logo-global--es {
  a {
    background: url('../images/logo-muc-color_en.svg') no-repeat center;
    background-size: contain;
  }
}

.logo-global--fr {
  a {
    background: url('../images/logo-muc-color_en.svg') no-repeat center;
    background-size: contain;
  }
}

.logo-global--pol {
  a {
    background: url('../images/logo-muc-color_en.svg') no-repeat center;
    background-size: contain;
  }

}

.nav-container--weather {
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.container-nav-main {
  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  a {
    font-weight: 300;
  }

  .icon.has-subnav {
    display: inline-block;
    float: right;

    @extend .icon--arrow-left;

    @include bp('md') {
      float: none;
      transform: rotate(90deg);
    }
  }
  .nav-item {
    padding-bottom: 2rem;

    > a,
    > span {
      font-weight: 400;
      font-size: 2.8rem;
      line-height: 1;
      display: inline-block;
      text-decoration: none;
      padding: 2rem 3rem 1.2rem;
      color: $color-umbra-dark;
      position: relative;

      .icon-slash {
        margin-right: 5px;
        margin-top: 0.5rem;
      }

      @include bp('md') {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        padding: 0;
        display: block;
        line-height: $line-height-base;

        .icon--has-subnav {
          display: none;
        }
      }
    }

    > .nav-main__subnav {

      @include bp('md') {
        padding-top: 1rem;
      }

      > li > .nav-main__subnav > li > a {
        @include bp('md') {
          font-weight: 300;
          padding-left: 2rem;
        }
      }
    }
  }

  .icon--category {
    @extend .icon--double-slash;

    @include bp('xs-max') {
      line-height: 2.8rem;
      font-size: 2.8rem;
      top: 1.8rem;
      width: 3rem;
      margin-left: -3.5rem;
      position: absolute;
      height: 2.8rem;
    }

    @include bp('md') {
      top: -0.3rem;
    }
  }

  .icon--back {
    @extend .icon--arrow-left;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .icon--has-subnav {
    @extend .icon--arrow-right;
    float: right;
    display: inline-block;
    font-size: 2rem;
    top: 0;
    &:before {
      vertical-align: text-bottom;
    }

    @include bp('md') {
      float: none;
      transform: rotate(90deg);
      margin-left: 0.5rem;
      margin-right: -2.3rem;
    }
  }

  a[aria-expanded="true"] {
    .icon--has-subnav {
      @include bp('md') {
        transform: rotate(-90deg);
        margin-left: 0.5rem;
        margin-right: -2.3rem;
      }
    }
  }

  .nav-item--booking {
    .icon--category {
      color: $color-brand-booking;
    }

    .nav-main__title {
      border-bottom: 1px solid $color-brand-booking !important;
    }
  }

  .nav-item--inspiration {
    .icon--category {
      color: $color-brand-inspiration;
    }

    .nav-main__title {
      border-bottom: 1px solid $color-brand-inspiration !important;
    }
  }

  .nav-item--poi {
    .icon--category {
      color: $color-brand-poi;
    }

    .nav-main__title {
      border-bottom: 1px solid $color-brand-poi !important;
    }
  }

  .nav-item--highlight {
    .icon--category {
      color: $color-brand-highlight !important;
    }

    .nav-main__title {
      border-bottom: 1px solid $color-brand-highlight !important;
    }
  }

  .nav-item--language,
  .nav-item--weather {
    @include bp('md') {
      display: none;
    }
  }

  a {
    display: block;
    text-decoration: none;
    padding: 1rem 3rem;
    color: $color-umbra-dark;

    @include bp('md') {
      padding: 1.3rem 0;
      font-size: 1.8rem;
      line-height: $line-height-base;
    }
  }

  .nav-main__title {
    display: block;
    text-decoration: none;
    padding: 2rem 3rem;
    line-height: 2.4rem;
    color: $color-umbra-dark;

    @include bp('md') {
      padding: 1rem 0;
      font-size: 1.8rem;
      line-height: $line-height-base;
    }
  }

  li {
    margin: 0;
    padding: 0;

    &.nav-main__back:after,
    > a:not(:only-child):after {
      @extend .icon--close;
    }
  }

  .nav-item__content {
    float: right;
    color: $color-umbra-medium;
    font-weight: 400;
  }

  .nav-main__title {
    font-weight: 700;
    text-align: center;

    .nav-item__content {
      display: none;
    }

    .icon--has-subnav {
      display: none;
    }
  }

  .icon-color-booking {
    color: $color-brand-booking;
  }

  .icon-color-highlight {
    color: $color-brand-highlight;
  }

  .icon-color-inspiration {
    color: $color-brand-inspiration;
  }

  @include bp('sm-max') {
    padding: 0;
  }

  .nav-main {
    text-align: center;
    margin-bottom: 8rem;

    @include bp('md') {
      @include make-row();
      list-style: none;
      padding: 0;
      text-align: left;

      > li {
        @include make-col-ready();
        @include make-col(3);
      }
    }
  }
}

.block-weather {
  &__headline {
    text-align: center;
    margin-bottom: 2rem;
    display: none;
    @extend .b1;

    @include bp('md') {
      display: block;
    }
  }

  .icon[class*="icon--w-"] {
    color: $color-umbra-medium;
  }

  .nav-main__subnav {
    padding-left: 0;
  }
}

.list-weather {
  list-style: none;

  li {
    list-style: none;
    @extend .b1;

    > span {
      @include clearfix;
      display: block;

      @include bp('md') {
        padding: 1rem 0;
      }
    }
  }

  .dl-back {
    > a {
      font-size: $font-size-s2;
      font-weight: 700;
    }
  }

  .list-weather__current {
    text-align: center;

    > span {
      @include bp('sm-max') {
        font-weight: 400;
        border-bottom: 1px solid $color-umbra-dark;
      }
    }

    .nav-item__content {
      margin: 2rem 0 2rem 0;
      display: block;
      text-align: center;
      font-size: 4rem;
      color: $color-umbra-dark;
      float: none;
      font-weight: 700;

      @include bp('md') {
        margin: 2rem 0 1rem 0;
      }

      .icon {
        font-size: 5rem;
      }
    }

    @include bp('md') {
      //padding: 10rem 0 0 0;
    }
  }

  .list-weather__forecast {
    > span {
      padding: 1.5rem 0;
      text-align: center;
      font-weight: 400;

      @include bp('md') {
        padding: 0;
      }
    }

    .nav-item__content {
      .icon {
        font-size: 4rem;
        line-height: 0;
      }

      display: inline-block;
      float: right;
    }
  }
  ul, li {
    display: block !important;
    margin: 0;
    padding: 0;
  }

  @include bp('md') {
    @include make-row();
    list-style: none;
    padding: 0;
  }

  .list-weather__current {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include bp('md') {
      @include make-col-ready();
      @include make-col(5);
    }
  }

  .list-weather__forecast {
    ul {
      @include bp('md') {
        padding-left: 5rem;
        margin: 0;
        border-left: 1px solid $color-umbra-dark;
      }

      li {
        margin-bottom: 1rem;
      }
    }

    @include bp('md') {
      @include make-col-ready();
      @include make-col(7);
    }
  }

  .icon {

    &--w-clear-sky, &--w-few-clouds {
      &:before {
        color: $color-yellow-sun;
      }
    }


    &--w-rain, &--w-snow, &--w-shower-rain {
      &:before {
        color: $color-blue-isar;
      }
    }

    &--w-thunderstorm, &--w-broken-clouds, &--w-mist, &--w-scattered-clouds  {
      &:before {
        color: $color-umbra-medium;
      }
    }
  }
}

.dropdown-menu__header {
  padding: 1.2rem 0;
  border-bottom: 2px solid $color-umbra-bright;

  @include bp('md') {
    display: none;
  }
}

@-webkit-keyframes FadeSlide {
  0% {
    opacity: 0;
    margin-top: -2rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.show > .container-fluid {
  > .nav-main {
    .nav-item {
      animation: FadeSlide .3s linear;
      animation-fill-mode: both;

      &:nth-child(1) {
        animation-delay: .2s
      }

      &:nth-child(2) {
        animation-delay: .4s
      }

      &:nth-child(3) {
        animation-delay: .6s
      }

      &:nth-child(4) {
        animation-delay: .8s
      }
    }
  }
  > .nav-main-header {
    animation: FadeIn .3s linear;
    animation-fill-mode: both;
    animation-delay: 1s;
  }
}

.nav-mobile {
  visibility: hidden;
  opacity: 0;
  background: $color-white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transform: translateY(-100vh);
  transition: all 0.5s ease-out;

  @include bp('md') {
    display: none;
  }

  &--open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &__header {
    display: flex;
  }

  .button--nav-mobile-back {
    transform: translateX(-100%);
    transition: all 300ms ease-out;

    .icon {
      transform: rotate(180deg);
    }

    &.button--show {
      transform: translateX(0);
    }
  }

  .button--nav-mobile-close {
    margin-left: auto;
  }

  .button {
    outline: none;
  }

  &__header {
    .button {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__footer {
    position: absolute;
    background: $color-white;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    transform: translateX(-100vw);
    opacity: 0;

    .button {
      flex-grow: 1;
      flex-basis: 0;
    }

    .nav-mobile--open & {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.5s ease-out 0.3s;
    }

    .icon[class*="icon--w-"] {
      color: $color-umbra-dark;
    }
  }

  &__body {
    position: relative;
    transform: translateY(100vh);
    opacity: 0;
    height: calc(100% - 145px);

    .nav-mobile--open & {
      transform: translateY(0);
      opacity: 1;
      transition: all 0.5s ease-out 0.3s;
    }
  }

  &__tab {
    position: absolute;
    background: $color-white;
    top: 100vh;
    padding: 20px;
    overflow-y: auto;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;

    &--active {
      visibility: visible;
      opacity: 1;
      top: 0;
      transition: all 0.5s ease-out;

      .nav-mobile:not(.nav-mobile--open) & {
        opacity: 0;
        visibility: hidden;
      }
    }

    &--navigation {
      .nav-main__subnav {
        opacity: 0;
        display: none;
      }
    }
  }

  .nav-main {
    list-style: none;
    margin: 0 0 0 35px;
    padding: 0;

    &__subnav {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 0;
      }

      a {
        font-size: 1.8rem;
        font-weight: 300;
      }
    }
  }

  .nav-item {
    display: block;
    margin: 0;
    padding: 0;

    span {
      position: relative;
      font-size: 2.8rem;
      display: block;
      padding: 10px 0;
      font-weight: 400;

      .icon {
        position: absolute;
        top: 17px;
        left: -6px;
        transform: translateX(-100%);
      }
    }

    &--booking {
      .icon--category {
        color: $color-brand-booking;
      }
    }

    &--inspiration {
      .icon--category {
        color: $color-brand-inspiration;
      }
    }

    &--poi {
      .icon--category {
        color: $color-brand-poi;
      }
    }

    &--highlight {
      .icon--category {
        color: $color-brand-highlight !important;
      }
    }
  }

  .icon--category {
    @extend .icon--double-slash;
  }

  .nav-item--booking {
    .icon--category {
      color: $color-brand-booking;
    }
  }

  .nav-item--inspiration {
    .icon--category {
      color: $color-brand-inspiration;
    }
  }

  .nav-item--poi {
    .icon--category {
      color: $color-brand-poi;
    }
  }

  .nav-item--highlight {
    .icon--category {
      color: $color-brand-highlight !important;
    }
  }
}

.button--nav-mobile-open {
  @include bp(md) {
    display: none;
  }
}

.mobile-menu-open {
  overflow: hidden;
}

.block-language {
  display: flex;
  justify-content: center;
}

.nav-language {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;

  .icon {
    position: absolute;
    font-size: 3.2rem;
    top: -5px;
    left: -5px;
    transform: translateX(-100%);
  }

  a {
    position: relative;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 1;
    margin: 2rem 0;
    display: inline-block;
    transition: all .5ms ease-out;

    &::hover {
      font-size: 2.8rem;
    }

    &::focus {
      font-size: 3.2rem;
    }
  }
}

 /* menu Dropdown */
.sub-sub-menu {
  display: none;
  position: absolute;
  left: 240px;
  top: 0;
  padding: 0 16px !important;
  background-color: white;
  z-index: 1200;
  box-shadow: 1px 1px 10px 2px rgba(100, 100, 100, 0.5);
  width: 267px !important;
}
.has-subsubmenu {
  padding: 1.3rem 0 !important;
  font-size: 1.8rem !important;
  line-height: 1.666 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.vvisible {
  display: unset;
}
.menu-item {
  display: flex;
  position: relative;
  align-items: center;
}
.icon-sub-sub {
  transition: 0.1s ease-in-out;
  @extend .icon--arrow-right;
  float: none;
  transform: rotate(0deg) !important;
  margin-left: 1rem !important;
  margin-right: -2.3rem;

  &:before {
    vertical-align: text-bottom;
  }
}

.menu-item.is-open ul.sub-sub-menu-mobile {
  display: block;
}

.menu-item:hover .sub-sub-menu {
  width: 220px;
}

.sub-sub-menu::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 25px);
  height: calc(100% + 25px);
  background-color: transparent;
}

.sub-sub-menu:hover::before {
  background-color: rgba(0, 0, 0, 0);
}

/*Breackpoints responsive*/
@media (max-width: 980px) {
  .sub-sub-menu {
    width: 235px !important;
  }
}
@media (max-width: 900px) {
  .sub-sub-menu {
    width: 200px !important;
  }
}
@media (max-width: 820px) {
  .sub-sub-menu {
    width: 190px !important;
    left: 150px;
  }
}
@media (max-width: 769px) {
  .sub-sub-menu-mobile {
    position: relative;
    top: 0;
    left: 10px;
    display: none;
    width: 101% !important;
    list-style: none;
    box-shadow: unset;
    overflow: hidden;
  }

  .menu-item-underline {
    border-bottom: 1px solid lightgray;
  }
  .sub-sub-menu-li {
    border-bottom: 1px solid #eeeeee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon-sub-sub {
    position: absolute;
    left: 520px;
    top: 15px;
    font-size: 2.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 45px;
  }
  .nav-item--inspiration {
    transition: unset !important;
  }
  .nav-item--poi {
    transition: unset !important;
  }
  .nav-item--booking {
    transition: unset !important;
  }
  .nav-item--highlight {
    transition: unset !important;
  }
}

@media (max-width: 640px) {
  .icon-sub-sub {
    left: 430px;
  }
}

@media (max-width: 530px) {
  .icon-sub-sub {
    left: 220px;
  }
}

.nav-item--inspiration {
  transition: 0.125s ease-in-out;
  a:hover {
    color: #fab900 !important;
  }
  .has-subsubmenu:hover {
    color: #fab900 !important;
  }
}
.nav-item--poi {
  transition: 0.125s ease-in-out;
  a:hover {
    color: #0096c8 !important;
  }
  .has-subsubmenu:hover {
    color: #0096c8 !important;
  }
}

.nav-item--booking {
  transition: 0.125s ease-in-out;
  a:hover {
    color: #14a537 !important;
  }
  .has-subsubmenu:hover {
    color: #14a537 !important;
  }
}
.nav-item--highlight {
  transition: 0.125s ease-in-out;
  a:hover {
    color: #dc0a32 !important;
  }
  .has-subsubmenu:hover {
    color: #dc0a32 !important;
  }
}

.menu-item:hover .sub-sub-menu {
  width: 220px;
}

.sub-sub-menu::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 25px);
  height: calc(100% + 25px);
  background-color: transparent;
}

.sub-sub-menu:hover::before {
  background-color: rgba(0, 0, 0, 0);
}

.opacity-50 {
  opacity: 0.4;
}

.open-dropdown-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rotate-90 {
  rotate: 90deg;
  transition: 0.1s ease-in-out;
}

.d-none {
  display: none;
}

.opacity-100 {
  opacity: 1 !important;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}