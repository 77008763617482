.list-infoboxes {
  @include make-row();
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-infoboxes__item {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: 5rem;

  @include bp('md') {
    @include make-col(6);
    margin-bottom: 3rem;
  }
}