.block-image-gallery-container {
  .block-embedded {
    margin: 0;
  }

  p {
    font-weight: 300;
    font-style: italic;  }


}

.block-image-gallery {
  @include make-row();
  position: relative;

  .col-main {
    @include make-col-ready();
    @include make-col(8);
  }

  .col-thumbnails {
    @include make-col-ready();
    @include make-col(4);
    display: flex;
    flex-direction: column;
  }

  .image-main {
    display: block;
    img {
      width:100%;
      height: auto;
      border-radius: 4px;
    }
  }

  .image-thumbnail {
    overflow: hidden;
    position: relative;
    background-color: #ddd;
    width: 100%;
    height: 100%;
    display: block;

    img {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      height: auto;
      width: 100%;
      transform: translate(-50%,-50%);

      &.img-portrait {
        width: 100%;
        height: auto;
      }
    }
  }

  .image-count {
    position: absolute;
    bottom: 20px;
    right: 20px;
    min-width: 4rem;
    text-decoration: none;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    border-radius: 0.3rem;
    background-color: $color-umbra-dark;
    color: $color-white;
    display: block;
    padding: 0 .5rem;
    font-weight: 700;
  }

  .image-row {
    flex: 1;
    padding-bottom: map-get($grid-gutter-widths, xs) / 2;

    @include bp('lg') {
      padding-bottom: $grid-gutter-width-base / 2;
    }

    & + .image-row {
      padding-top: map-get($grid-gutter-widths, xs) / 2;
      padding-bottom: 0;

      @include bp('lg') {
        padding-top: $grid-gutter-width-base / 2;
      }
    }
  }
  figure {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }
  figcaption {
    display: none;
  }
}

.gallery--inspiration {
  .divider {
    .icon {
      color: $color-brand-inspiration;
    }
    &:before,
    &:after {
      border-color: $color-brand-inspiration;
    }
  }

  .image-count {
    background-color: $color-brand-inspiration;
  }
}

.gallery--booking {
  .divider {
    .icon {
      color: $color-brand-booking;
    }
    &:before,
    &:after {
      border-color: $color-brand-booking;
    }
  }

  .image-count {
    background-color: $color-brand-booking;
  }
}

.gallery--poi {
  .divider {
    .icon {
      color: $color-brand-poi;
    }
    &:before,
    &:after {
      border-color: $color-brand-poi;
    }
  }

  .image-count {
    background-color: $color-brand-poi;
  }
}

.gallery--highlight {
  .divider {
    .icon {
      color: $color-brand-highlight;
    }
    &:before,
    &:after {
      border-color: $color-brand-highlight;
    }
  }

  .image-count {
    background-color: $color-brand-highlight;
  }
}
