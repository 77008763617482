/*! nouislider - 10.0.0 - 2017-05-28 14:52:48 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}
.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 5px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}
/* Styling;
 */
.noUi-target {
  background: $color-umbra-bright;
  border-radius: 10px;
}
.noUi-connect {
  background: $color-red-heart;
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid $color-brand-booking;
  border-radius: 3px;
  background: $color-white;
  cursor: default;

}
.noUi-active {

}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: $color-brand-booking;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: $color-umbra-medium;
  font-size: 1.5rem;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  font-size: $font-size-base;
}
.noUi-value-sub {
  color: $color-umbra-medium;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  display: none;
  position: absolute;
  background: $color-umbra-medium;
}
.noUi-marker-sub {
  background: $color-umbra-medium;
}
.noUi-marker-large {
  background: $color-umbra-medium;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 2rem 0;
  height: 80px;
  top: 0;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  /*-webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);*/

  @include bp('md-max') {
    left: -2rem !important;
  }

  &:last-of-type {
    left: auto!important;
    right: 0!important;
    @include bp('md-max') {
      right: -2rem!important;
    }
  }
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: none;
  border-radius: 3px;
  background: $color-umbra-bright;
  color: $color-umbra-dark;
  padding: .5rem 1rem;
  text-align: center;
  white-space: nowrap;
  font-size: $font-size-base;
}

.noUi-tooltip:after, .noUi-tooltip:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.noUi-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $color-umbra-bright;
  border-width: 4px;
  margin-left: -4px;
}
.noUi-tooltip:before {
  border-color: rgba(20, 165, 55, 0);
  border-top-color: $color-umbra-bright;
  border-width: 7px;
  margin-left: -7px;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 170%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 170%;
}


/*
Custom
 */
.noUi-target {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  direction: ltr;
}
.noUi-target.noUi-connect {
  box-shadow: none;
}
.noUi-handle {
  border-radius: 999px;
  box-shadow: none;
}
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}
.noUi-horizontal {
  height: 5px;
  @include bp('md-max') {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  left: -9px;
  border: 2px solid $color-red-heart;
  top: -7px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.noUi-horizontal .noUi-handle:hover {
  border: 2px solid $color-brand-booking;
  color: red;
}
.noUi-horizontal .noUi-handle:active {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 2px solid $color-brand-booking;
  z-index: 10 !important;
}
.noUi-horizontal .noUi-handle:focus {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 2px solid $color-brand-booking;
  outline: 0;
  z-index: 10 !important;
}
.disable-hover-scale .noUi-handle:active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  z-index: 10 !important;
}
.disable-hover-scale .noUi-handle:focus {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  z-index: 10 !important;
}
.vertical-slider {
  height: 150px;
}
.noUi-vertical {
  width: 4px;
}
.noUi-vertical .noUi-handle {
  width: 18px;
  height: 18px;
  border: 2px solid $color-brand-booking;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.noUi-vertical .noUi-handle:hover {
  border: 1px solid #c0c0c0;
}
.noUi-vertical .noUi-handle:active {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 2px solid $color-brand-booking;
}
.noUi-vertical .noUi-handle:focus {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 2px solid $color-brand-booking;
}
.noUi-base {
  padding: 3px 0;
}
.noUi-origin {
  border-radius: 0;
  bottom: 5px;
}
.noUi-connect {
  box-shadow: none;
}
.noUi-background {
  background: #ececec;
  box-shadow: none;
}
