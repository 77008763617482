/**
 * @license
 * MyFonts Webfont Build ID 3413697, 2017-06-29T03:50:47-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: UniversLTStd-LightObl by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/45-light-oblique/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-Black by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/75-black/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1999, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1999, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-BoldObl by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/65-bold-oblique/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-Bold by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/65-bold/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/55-roman/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-BlackObl by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/76-black-oblique/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1999, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1999, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-Obl by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/55-oblique/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: UniversLTStd-Light by Linotype
 * URL: https://www.myfonts.c/bundles/lhmdesign/fonts/linotype/univers/45-light/
 * Copyright: Copyright &#x00A9; 1987, 1991, 1993, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3413697
 * Licensed pageviews: 10,000,000
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
/* @import url("//hello.myfonts.net/count/3416c1"); */

// light oblique
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 300;
  font-style: italic;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_0_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_0_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_0_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_0_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_0_0.ttf') format('truetype');
}

// black
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 900;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_1_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_1_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_1_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_1_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_1_0.ttf') format('truetype');
}

// bold oblique
@font-face {
  font-family: 'UniversLTStd';
  font-style: italic;
  font-weight: 700;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_2_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_2_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_2_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_2_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_2_0.ttf') format('truetype');
}

// bold
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 700;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_3_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_3_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_3_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_3_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_3_0.ttf') format('truetype');
}

// roman
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 400;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_4_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_4_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_4_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_4_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_4_0.ttf') format('truetype');
}

// black
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 900;
  font-style: italic;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_5_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_5_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_5_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_5_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_5_0.ttf') format('truetype');
}

// roman oblique
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 400;
  font-style: italic;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_6_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_6_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_6_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_6_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_6_0.ttf') format('truetype');
}

// light
@font-face {
  font-family: 'UniversLTStd';
  font-weight: 300;
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_7_0.eot');
  src: url('/bundles/lhmdesign/fonts/webfonts/3416C1_7_0.eot?#iefix') format('embedded-opentype'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_7_0.woff2') format('woff2'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_7_0.woff') format('woff'), url('/bundles/lhmdesign/fonts/webfonts/3416C1_7_0.ttf') format('truetype');
}
