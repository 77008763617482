.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2rem;

  @include bp('md') {
    padding-bottom: 2.5rem;
  }

  &:before,
  &:after {
    content: "";
    border-top: solid 1px $color-umbra-dark;
    flex-grow: 1;
    height: 0;
    display: block;
  }

  .icon {
    color: $color-umbra-dark;
  }
}

.divider--inspiration {
  .icon {
    color: $color-brand-inspiration;
  }
  &:before,
  &:after {
    border-color: $color-brand-inspiration;
  }
}

.divider--highlight {
  .icon {
    color: $color-brand-highlight;
  }
  &:before,
  &:after {
    border-color: $color-brand-highlight;
  }
}

.divider--poi {
  .icon {
    color: $color-brand-poi;
  }
  &:before,
  &:after {
    border-color: $color-brand-poi;
  }
}

.divider--booking {
  .icon {
    color: $color-brand-booking;
  }
  &:before,
  &:after {
    border-color: $color-brand-booking;
  }
}
.divider--negative {
  .icon {
    color: $color-white;
  }
  &:before,
  &:after {
    border-color: $color-white;
  }
}

.divider--numeration {
  .divider__text {
    background: $color-umbra-medium;
    border-radius: 50%;
    color: $color-white;
    display: inline-block;
    width: 4.1rem;
    height: 4.1rem;
    padding-left: 0.05rem;
    line-height: 4.25rem;
    text-align: center;
  }

  &.divider--inspiration {
    .divider__text {
      background: $color-brand-inspiration;
    }
  }

  &.divider--highlight {
    .divider__text {
      background: $color-brand-highlight;
    }
  }

  &.divider--poi {
    .divider__text {
      background: $color-brand-poi;
    }
  }

  &.divider--booking {
    .divider__text {
      background: $color-brand-booking;
    }
  }

  &:before,
  &:after {
    border-color: $color-umbra-medium !important;
  }
}

.divider--icon {
  .icon {
    font-size: 3rem;
  }
}

.divider--headline {
  .divider__text {
    .icon {
      margin-right: 0.5rem;

      @include bp('md') {
        margin-right: 1rem;
      }
    }
  }
}

.divider__content {
  margin: 0 1rem;
  @include bp('md') {
    margin: 0 3rem;
  }
}

.divider__text {
  @extend .b1;
  font-weight: 400;
  margin: 0;
  padding: 0;

  @include bp('xs-only') {
    font-size: 1.6rem;
  }
}

.divider__form {
  &:before,
  &:after {
    border-color: $color-green-nature;
  }

  span {
    display: inline-block;
    color: $color-umbra-medium;
    padding-right: 10px;
    &:before {
      color: $color-green-nature;
      content: '//';
      display: inline-block;
      padding: 0 10px;
    }
  }
}

.small-divider {
  display: block;
  border: 0;
  border-top: 2px solid $color-umbra-bright;
  margin-bottom: 2.2rem;
}

// overwrite video-embedded divider with own context
.context-inspiration {
  .block-figure--video {
    .divider {
      &:before, &:after {
        border-color: $color-brand-inspiration!important;
      }
    }
    .icon {
      color: $color-brand-inspiration!important;
    }
  }
}
.context-poi {
  .block-figure--video {
    .divider {
      &:before, &:after {
        border-color: $color-brand-poi!important;
      }
    }
    .icon {
      color: $color-brand-poi!important;
    }
  }
}
.context-booking {
  .block-figure--video {
    .divider {
      &:before, &:after {
        border-color: $color-brand-booking!important;
      }
    }
    .icon {
      color: $color-brand-booking!important;
    }
  }
}
.context-highlight {
  .block-figure--video {
    .divider {
      &:before, &:after {
        border-color: $color-brand-highlight!important;
      }
    }
    .icon {
      color: $color-brand-highlight!important;
    }
  }
}
