.block-information {
  text-align: center;
  padding: 2rem;
  background-image: url('../images/bg-dots-inspiration.svg');
  background-repeat: repeat;
  background-size: 10rem 10rem;
  margin: 0 -2rem;
  width: auto;

  @extend .t1;

  @include bp('md') {
    margin: 0;
    padding: 2rem 10rem;
    overflow: hidden;
    border-radius: 4px;
  }

  a {
    color: $color-white !important;
    border-bottom-color: $color-white !important;
  }

  //@include bp('sm') {
  //  background-size: 15rem 15rem;
  //}

  &.block-information--inspiration {
    color: $color-black;
  }
}

.block-information__icon {
  height: 8rem;

  @include bp('md') {
    height: 10rem;
  }
}

.block-information--inspiration {
  background-image: url('../images/bg-dots-inspiration.svg');
  color: $color-black;

  a {
    color: $color-black !important;
    border-color: $color-black !important;
  }
}

.block-information--poi {
  background-image: url('../images/bg-dots-poi.svg');
  color: $color-white;
}

.block-information--booking {
  background-image: url('../images/bg-dots-booking.svg');
  color: $color-white;
}

.block-information--highlight {
  background-image: url('../images/bg-dots-highlight.svg');
  color: $color-white;
}

.block-information--dot-blue {
  background-image: url('../images/pattern-dot-blue.svg');
  color: $color-white;
}

.block-information--dot-yellow {
  background-image: url('../images/pattern-dot-yellow.svg');
  color: $color-black;

  a {
    color: $color-black !important;
    border-color: $color-black !important;
  }
}

.block-information--dot-green {
  background-image: url('../images/pattern-dot-green.svg');
  color: $color-white;
}

.block-information--dot-red {
  background-image: url('../images/pattern-dot-red.svg');
  color: $color-white;
}

.block-information--heart-blue {
  background-image: url('../images/pattern-heart-blue.svg');
  color: $color-white;
}

.block-information--heart-yellow {
  background-image: url('../images/pattern-heart-yellow.svg');
  color: $color-black;

  a {
    color: $color-black !important;
    border-color: $color-black !important;
  }
}

.block-information--heart-green {
  background-image: url('../images/pattern-heart-green.svg');
  color: $color-white;
}

.block-information--heart-red {
  background-image: url('../images/pattern-heart-red.svg');
  color: $color-white;
}

.block-information--line-blue {
  background-image: url('../images/pattern-line-blue.svg');
  color: $color-white;
}

.block-information--line-yellow {
  background-image: url('../images/pattern-line-yellow.svg');
  color: $color-black;

  a {
    color: $color-black !important;
    border-color: $color-black !important;
  }
}

.block-information--line-green {
  background-image: url('../images/pattern-line-green.svg');
  color: $color-white;
}

.block-information--line-red {
  background-image: url('../images/pattern-line-red.svg');
  color: $color-white;
}

.block-information__headline {
  @extend .h2;
}
