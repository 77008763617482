/*!
 * Krajee SVG Theme styling for bootstrap-star-rating.
 * This file must be loaded after 'star-rating.css'.
 *
 * @see http://github.com/kartik-v/bootstrap-star-rating
 * @author Kartik Visweswaran <kartikv2@gmail.com>
 */
.theme-krajee-svg {
  .krajee-icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .filled-stars {
    .krajee-icon-star {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODUwLjQgODUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDg1MC40IDg1MC40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1MzkuNiw0OTIuNiA3MjUuMiwzNTcuNyA0OTUuOCwzNTcuNyA0MjUuMiwxNDAuMiAzNTQuNiwzNTcuNyAxMjUuMiwzNTcuNyAzMTAuOCw0OTIuNiAyNDAuMiw3MTAuMiA0MjUuMiw1NzUuNyA2MTAuMiw3MTAuMiAiLz48L3N2Zz4=);
    }
  }
  .krajee-icon-clear {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODUwLjQgODUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDg1MC40IDg1MC40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6NTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI1MzkuNiw0OTIuNiA3MjUuMiwzNTcuNyA0OTUuOCwzNTcuNyA0MjUuMiwxNDAuMiAzNTQuNiwzNTcuNyAxMjUuMiwzNTcuNyAzMTAuOCw0OTIuNiAyNDAuMiw3MTAuMiA0MjUuMiw1NzUuNyA2MTAuMiw3MTAuMiAiLz48L3N2Zz4=);
    &:hover {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODUwLjQgODUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDg1MC40IDg1MC40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1MzkuNiw0OTIuNiA3MjUuMiwzNTcuNyA0OTUuOCwzNTcuNyA0MjUuMiwxNDAuMiAzNTQuNiwzNTcuNyAxMjUuMiwzNTcuNyAzMTAuOCw0OTIuNiAyNDAuMiw3MTAuMiA0MjUuMiw1NzUuNyA2MTAuMiw3MTAuMiAiLz48L3N2Zz4=);
    }
  }
  .empty-stars {
    .krajee-icon-star {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODUwLjQgODUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDg1MC40IDg1MC40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6NTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI1MzkuNiw0OTIuNiA3MjUuMiwzNTcuNyA0OTUuOCwzNTcuNyA0MjUuMiwxNDAuMiAzNTQuNiwzNTcuNyAxMjUuMiwzNTcuNyAzMTAuOCw0OTIuNiAyNDAuMiw3MTAuMiA0MjUuMiw1NzUuNyA2MTAuMiw3MTAuMiAiLz48L3N2Zz4=);
    }
  }

}
