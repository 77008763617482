// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
//
// Start with assigning color names to specific hex values.
$color-white:                  #fff;
$color-black:                  #000;
$color-green-nature:           #14A537;
$color-green-nature-structure: #96C34B;
$color-blue-isar:              #0096C8;
$color-blue-isar-structure:    #46BEE1;
$color-yellow-sun:             #FAB900;
$color-yellow-sun-structure:   #FAC332;
$color-red-heart:              #DC0A32;
$color-red-heart-structure:    #EB5F5F;
$color-umbra-bright:           #f4f4f4;
$color-umbra-medium:           #81817E;
$color-umbra-dark:             #2D2D28;
$color-grey-bright:            #F2F2F2;


// Brand color mapping

$color-brand-inspiration:  $color-yellow-sun;
$color-brand-poi:          $color-blue-isar;
$color-brand-booking:      $color-green-nature;
$color-brand-highlight:    $color-red-heart;



// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
);
$border-width: 1px;


// Spacer for modules

$spacer-small-xs: 5rem;
$spacer-small-md: 8rem;

$spacer-large-xs: 10rem;
$spacer-large-md: 15rem;


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);

// Body
//
// Settings for the `<body>` element.

$body-bg:       $color-white;
$body-color:    $color-umbra-dark;



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width-base:     24px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base / 2,
  sm: $grid-gutter-width-base / 2,
  md: $grid-gutter-width-base / 2,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-brand: UniversLTStd;

$font-family-sans-serif: $font-family-brand, Univers, Helvetica, Arial;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-size-base: 1.8rem;
$font-size-lg:   2.2rem;
$font-size-md:   1.8rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.666;


$font-size-h1: 3.4rem;
$font-size-h2: 3.0rem;
$font-size-h3: 2.6rem;
$font-size-h4: 2.2rem;
$font-size-h5: 1.8rem;
$font-size-h6: 1.2rem;

$font-size-a1: $font-size-md;
$font-size-s2: 1.4rem;
$font-size-s4: 1.5rem;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   inherit;
$headings-font-weight:   700;
$headings-line-height:   1.3;
$headings-color:         inherit;
