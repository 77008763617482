.nav-direct-access, .nav-direct-totop {
  position: fixed;
  bottom: calc(3% + 20px);
  right: calc(3% + 20px);
  opacity: 1;
  z-index: 99;
  transition: all 0.3s ease-in-out;

  &.hide {
    opacity: 0;
    bottom: -5rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    transform: translate(-12px, 40px);

    li {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__icon {
    display: block;
    background-color: $color-white;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    z-index: 9;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .4);
    text-align: center;
  }

  &__trigger {
    .nav-direct-access__icon {
      background-color: $color-green-nature;
      color: $color-white;
    }

    .icon {
      font-size: 3rem;
      display: block;
      transform: rotate(0deg);
      transition: .5s all ease-out;
    }
  }

  &__label {
    white-space: nowrap;
    background-color: rgba($color-white, 0.9);
    height: 40px;
    line-height: 35px;
    display: block;
    position: absolute;
    border-radius: 20px;
    padding: 5px 50px 0 15px;
    opacity: 0;
    right: 0;
    overflow: hidden;
    //transition: all 300ms 500ms ease-out;
  }

  &__button,
  &__trigger {
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    &:hover, &:focus {
      outline: none;
    }

    .icon {
      top: 0;
      font-size: 4rem;
      line-height: 64px;
    }

    &-hotspots, &-excursion, &-munich_card, &-guided_tours, &-booking, &-contact {
      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms ease-out;
    outline: none;
  }

  &__button-hotspots, &__button-munich_card {
    .icon {
      color: $color-red-heart;
    }
  }

  &__button-excursion {
    .icon {
      color: $color-yellow-sun;
    }
  }


  &__button-guided_tours {
    .icon {
      color: $color-blue-isar;
    }
  }

  &__button-booking {
    .icon {
      font-size: 2.4rem;
      color: $color-brand-booking;
    }
  }

  &__button-contact {
  }

  &--show {
    .nav-direct-access__trigger {
      .nav-direct-access__icon {
        color: $color-umbra-dark;
        background-color: $color-white;
      }

      .icon {
        transform: rotate(45deg);
      }
    }

    .nav-direct-access__button {
      opacity: 1;
      visibility: visible;
      font-weight: 400;
      color: $color-black;
    }

    .nav-direct-access__button-hotspots {
      bottom: 330px;

      .nav-direct-access__label {
        animation-delay: .7s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__button-excursion {
      bottom: 275px;

      .nav-direct-access__label {
        animation-delay: .6s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__button-munich_card {
      bottom: 220px;

      .nav-direct-access__label {
        animation-delay: .5s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__button-guided_tours {
      bottom: 165px;

      .nav-direct-access__label {
        animation-delay: .4s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__button-booking {
      bottom: 110px;

      .nav-direct-access__label {
        animation-delay: .3s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__button-contact {
      bottom: 55px;

      .nav-direct-access__label {
        animation-delay: .2s;
      }

      .nav-direct-access__icon {
        width: 40px;
        height: 40px;

        .icon {
          line-height: 40px;
          font-size: 3rem;
        }
      }
    }

    .nav-direct-access__label {
      opacity: 0;
      max-width: 300px;
      animation: in 1s 2s forwards ease-out;
    }
  }
}

.nav-direct-totop {
  &__icon {
    background-color: $color-brand-booking;
    color: $color-white;

    .icon {
      color: $color-white;
    }
  }
}


@keyframes in {
  0% {
    //max-width: 0px;
    opacity: 0;
  }
  100% {
    //max-width: 300px;
    opacity: 1;
  }
}

@keyframes out {
  0%   { max-width: 300px; opacity: 1;}
  100% { max-width: 0px; opacity: 0;}
}
