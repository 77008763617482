.block-offer {
  overflow: hidden;
  width: 100%;
  position: relative;

  &::before {
    padding-top: 100%;
    display: block;
    content: '';

    @include bp('sm') {
      padding-top: 46.875%;
    }
  }

  .label {
    right: 0;
    position: absolute;
    top: 2.4rem;
  }
}

.block-offer__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.block-offer__container {
  .img-fluid {
    margin-bottom: 1.8rem;

    @include bp('sm-max') {
      margin-bottom: 2.5rem;
    }
  }
}

.block-offer__content {
  padding-top: 7.5rem;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  color: $white;
  text-shadow: 0 0 8 0px rgba($color-black, 0.68);
  background-color: rgba($color-black, 0.05);

  @include bp('md') {
    padding-top: 9rem;
  }
}

.block-offer__headline {
  margin-bottom: 1rem;
}

.block-offer__lead {
  display: none;

  @include bp('lg') {
    display: block;
    margin-bottom: 2rem;
  }
}



/* Full picture */
.block-offer--full-picture {
  .img-fluid {
    @include bp('xs-max') {
      width: 90%;
      margin: 0 auto 2.2rem auto;
    }

    @include bp('sm-only') {
      width: 70%;
      margin: 0 auto 2.2rem auto;
    }
  }

  .block-offer__container {
    @include bp('sm-max') {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 3rem;
      background: linear-gradient(rgba($black,0), rgba($black,0.8));
    }
  }
}


/* Half picture */
.block-offer--half-picture {
  &::before {
    padding-top: 0;
  }

  .block-offer__content {
    background: transparent;
    padding-top: 0;
    position: relative;
  }

  .block-offer__container {
    background: transparent;
    padding: 6rem 0 0 0;
    position: relative;

    @include bp('lg') {
      padding: 8rem 0 0 0;
    }

    &.text-center {
      @include bp('xs-max') {
        padding: 8rem 2rem 0 2rem;
      }

      .img-fluid {
        @include bp('sm') {
          width: 50%;
          display: block;
          margin: 0 auto 2.2rem auto;
        }
      }
    }

    @include bp('xs-max') {
      padding: 3rem 2rem 0 2rem;
      text-align: center;
    }
  }

  .label {
    top: 0;
    @include bp('xs-max') {
      top: 2.4rem;
    }
  }

  .block-offer__headline {
    color: $color-black;
  }

  .block-offer__lead {
    color: $color-black;
  }
}
