 //remove margin in page template
.landing-page__block {
  .block-figure--video {
    margin-top: 0;

    &.block-topic {

      &--inpiration {
        .divider--headline {
          &:after, &:before {
            border-color: $color-brand-inspiration;
          }
        }
      }
      &--booking {
        &:after, &:before {
          border-color: $color-brand-booking;
        }
      }
      &--poi {
        &:after, &:before {
          border-color: $color-brand-poi;
        }
      }
      &--highlight {
        &:after, &:before {
          border-color: $color-brand-highlight;
        }
      }
    }
  }
}
