.block-entry-meta {
  display: flex;
  margin-bottom: 2rem;

  @include bp('md') {
    margin-bottom: 3rem;
  }
}

.block-entry-meta__media {
  width: 7rem;
  height: 7rem;
  margin-right: 30px;

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.block-entry-meta__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
