form {
  i {
    pointer-events: none;
    cursor: pointer;
  }
}

input, textarea {
  border: 1px solid transparent;
  background-color: $color-umbra-bright;
  padding: 0 1.5rem;
  width: 100%;

  @include placeholder {
    @include placeholder-style;
  }
}

button, input, select, textarea {
  font-family: inherit;
  margin: 0;
  transition: color 0.2s ease;

  &:focus {
    outline: 2px solid $color-umbra-medium;
  }

  &:disabled {
    pointer-events: none;
  }
}

input, select, textarea {
  &:focus {
    outline: none;
    border-color: $color-umbra-dark;
  }
}

label {
  color: $color-umbra-dark;
  font-weight: 300;
  display: block;
  font-size: 1.4rem;
}

input[type="date"], {
  text-transform: uppercase;
}

/* Select */
select {
  width: 100%;
  border: 1px solid transparent;

  background-color: $color-umbra-bright;
  appearance: none;
  border-radius: 0;
  color: $color-umbra-medium;
  cursor: pointer;
}

input,
select,
textarea {
  padding: 0.6rem;
  font-size: 1.8rem;
  font-weight: 300;
}

input,
select {
  height: 4.3rem;

  @include bp('md') {
    height: 4.6rem;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group {
  //margin: 2rem 0;
  //padding-bottom: 4rem;
  border-bottom: 2px solid $color-umbra-bright;
}

.radio-group {
  margin: 1rem 0;

  &.radio-group-inner {
    padding-left: 4.5rem;
  }
}

.field {
  color: $color-umbra-dark;
  margin-bottom: 1.5rem;
  label {
    vertical-align: middle;
    margin-bottom: 0;
  }
  &.has-icon {
    > div {
      position: relative;
      input {
        width: 100%;
        box-sizing: border-box;
        &:focus {
          outline: none;
        }
      }
      .field__icon {
        position: absolute;
        top: 0;
        right: 1rem;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .error {
    margin-top: 1rem;
    display: none;
    padding: 1.5rem 2rem;
    background-color: $color-red-heart-structure;
    color: $white;
    position: relative;
    &:before {
      position: absolute;
      top: -.7rem;
      content: '';
      width: 0px;
      height: 0px;
      -webkit-transform: rotate(360deg);
      border-style: solid;
      border-width: 0 .7rem .7rem .7rem;
      border-color: transparent transparent $color-red-heart-structure transparent;
      display: inline-block;
    }
  }
  &.has-error {
    .error {
      display: block;
    }
  }

  .icon {
    &:focus {
      color: red;
      outline: 0;
    }
  }
}

.field-date {
  input {
    &:hover {
      cursor: pointer;
    }
  }

  &.required {
    label:after {
      content: ' *';
    }
  }
}

.field-checkbox {
  input {
    opacity: 0;
    position: absolute;
    left: -99999px
  }

  label {
    font-size: 1.8rem;
    position: relative;
    display: inline-block;
    padding-left: 3.5rem;

    @include bp('md') {
      padding-left: 2.5rem;
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-block;
    }

    &:before{
      height: 2rem;
      width: 2rem;
      border: 1px solid $color-umbra-dark;
      background-color: $color-white;
      left: 0;
      top: 0.2rem;

      @include bp('md') {
        height: 1.8rem;
        width: 1.8rem;
        top: 0.6rem;
      }
    }

    &:after {
      @extend .icon;
      color: $color-umbra-dark;
      font-size: 1.8rem;
      // px here instead of rem. fix IE11 Bug
      line-height: 20px;
      height: 2rem;
      width: 2rem;
      vertical-align: middle;
      text-align: center;
      left: 0;
      top: 0.3rem;

      @include bp('md') {
        font-size: 1.6rem;
        // px here instead of rem. fix IE11 Bug
        line-height: 18px;
        height: 1.8rem;
        width: 1.8rem;
        top: 0.7rem;
      }
    }
  }

  input + label:after {
    content: icon--char(check-progress);
    opacity: 0;
    transform: scale(0.1);
    transition: all 200ms ease-in;
  }

  input:checked + label:after {
    content: icon--char(check-progress);
    transform: scale(1);
    opacity: 1;
  }

  input:focus + label:before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}


.field-radiobox {
  input {
    opacity: 0;
    position: absolute;
    left: -99999px
  }

  label {
    font-size: 1.8rem;
    position: relative;
    display: inline-block;
    padding-left: 3.5rem;

    @include bp('md') {
      padding-left: 2.5rem;
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-block;
      top: 0.2rem;

      @include bp('md') {
        top: 0.6rem;
      }
    }

    &:before {
      height: 2rem;
      width: 2rem;
      border: 1px solid $color-umbra-dark;
      background-color: $color-white;
      left: 0;
      border-radius: 50%;

      @include bp('md') {
        height: 1.8rem;
        width: 1.8rem;
      }
    }

    &:after {
      background-color: $color-umbra-dark;
      height: 1.4rem;
      width: 1.4rem;
      margin: 0.3rem;
      text-align: center;
      left: 0;
      border-radius: 50%;

      @include bp('md') {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  input + label:after {
    content: "";
    opacity: 0;
    transform: scale(0.1);
    transition: all 200ms ease-in;
  }

  input:checked + label:after {
    content: "";
    transform: scale(1);
    opacity: 1;
  }

  input:focus + label:before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}







//.field-checkbox,
//.field-radiobox {
//  margin-bottom: 1rem;
//  input {
//    position: absolute;
//    text-indent: -9999px;
//    width: .1px;
//    height: .1px;
//    opacity:0;
//  }
//  label {
//    padding-left: 5rem;
//
//    &:focus {
//      outline: 1px dotted $color-umbra-medium;
//    }
//  }
//  label i {
//    display: inline-block;
//    margin-right: 1.5rem;
//    text-align: center;
//    margin-left: -5rem;
//
//    @include bp('md') {
//      margin-right: 1rem;
//    }
//  }
//
//  label i:before {
//    border: 2px solid $color-umbra-medium;
//    background-color: $white;
//    padding: .5rem;
//    color: transparent;
//  }
//
//  input[type=checkbox]:checked + label i:before,
//  input[type=radio]:checked + label i:before {
//    color: $color-green-nature;
//  }
//
//  strong {
//    font-family: $font-family-brand-bold;
//  }
//}
//
//.field-checkbox {
//  margin-bottom: 3rem;
//  &[aria-checked='true'] {
//    label i:before {
//      color: $color-umbra-medium;
//    }
//  }
//}
//
//.field-radiobox {
//  label i:before {
//    border-radius: 50%;
//  }
//}

.field-select,
.field-date,
.field {
  span {
    position: relative;
    display: block;
    .icon {
      margin: auto 0;
      top: 0;
      bottom: 0;
      position: absolute;
      right: 1rem;
      font-size: 2rem;
      height: 2rem;
      line-height: 1;
    }
  }
}

.field-select {
  select {
    padding: 0.6rem 2.2rem 0.6rem 0.6rem;
  }
}

.field-input-button--booking {
  button {
    @extend .button--ghost;
  }
}


.field-input-button__container {
  flex-direction: column;
  display: flex;

  @include bp('sm') {
    flex-direction: row;
  }

  input {
    margin: 0 0 1rem 0;

    @include bp('sm') {
      margin: 0 2rem 0 0;
    }
  }
}

.field--inline {
  * {
    display: inline-block;
  }
  input[type=number] {
    width: 1rem;
  }
}

input.error-label,
textarea.error-label,
select.error-label{
  background-color: #FFF;
  color: $color-red-heart-structure;
  border-color: $color-red-heart-structure;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
}

.error-label {
  padding: 1.5rem 2rem;
  background-color: $color-red-heart-structure;
  color: $white;
  position: relative;
  &:before {
    position: absolute;
    top: -.7rem;
    content: '';
    width: 0px;
    height: 0px;
    -webkit-transform: rotate(360deg);
    border-style: solid;
    border-width: 0 .7rem .7rem .7rem;
    border-color: transparent transparent $color-red-heart-structure transparent;
    display: inline-block;
  }
}

.reservation {
  .date-selector {
    &:focus {
      border-color: $color-brand-booking;
    }

    &:disabled {
      opacity: 0.5;

      & + .icon {
        opacity: 0.5;
      }
    }
  }
}

.section--content {
  .form-outer {
    form:not(.reservation):not(.cart-form):not(.vacancy-form):not(.reservation--direct-access) > div {
      border: 1px solid;
      padding: 35px 25px 25px 25px;
      margin-bottom: 3rem;

      > .field {
        margin-bottom: 0;

        > label.required {
          display: none;
        }

        .field-select {

          label {
            font-size: 1.8rem;
            @include bp('md') {
              font-size: 2rem
            }
          }

          .block-options__item {
            margin: 0;
          }
        }

        .field:last-child {
          margin-bottom: 0;
        }
      }

      button {
        background-color: transparent;
        border: 1px solid;
        line-height: 40px;
        padding: 0 15px;
        height: 40px;
        transition: all .4s linear;
      }
    }

    .bordericon {
      background-color: #fff;
      display: flex;
      height: 6rem;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
      top: 3rem;
    }
  }

  .form-default-submit-message {
    line-height: 1;
    margin: 0;

    &__container {
      margin: 5rem 0;
      padding: 30px 20px;
      border: 1px solid;
    }
  }
}

.context-inspiration {
  .form-outer {
    form:not(.reservation, .cart-form, .vacancy-form, .reservation--direct-access) > div {
      border-color: $color-brand-inspiration !important;

      button {
        border-color: $color-brand-inspiration !important;

        &:hover {
          color: $color-black;
          background-color: $color-brand-inspiration !important;
        }
      }
    }
  }
  .form-default-submit-message__container {
    border-color: $color-brand-inspiration;
  }
}
.context-poi {
  .form-outer {
    form:not(.reservation, .cart-form, .vacancy-form, .reservation--direct-access) > div {
      border-color: $color-brand-poi !important;

      button {
        border-color: $color-brand-poi !important;

        &:hover {
          color: $color-white;
          background-color: $color-brand-poi !important;
        }
      }
    }
  }
  .form-default-submit-message__container {
    border-color: $color-brand-poi;
  }
}
.context-booking {
  .form-outer {
    form:not(.reservation, .cart-form, .vacancy-form, .reservation--direct-access) > div {
      border-color: $color-brand-booking !important;

      button {
        border-color: $color-brand-booking !important;

        &:hover {
          color: $color-white;
          background-color: $color-brand-booking !important;
        }
      }
    }
  }
  .form-default-submit-message__container {
    border-color: $color-brand-booking;
  }
}
.context-highlight {
  .form-outer {
    form:not(.reservation, .cart-form, .vacancy-form, .reservation--direct-access) > div {
      border-color: $color-brand-highlight !important;

      button {
        border-color: $color-brand-highlight !important;

        &:hover {
          color: $color-white;
          background-color: $color-brand-highlight !important;
        }
      }
    }
  }
  .form-default-submit-message__container {
    border-color: $color-brand-highlight;
  }
}
