.embed-teaser {

  &--container {
    padding: 15px;
    margin: 3.6rem 0;
    border: 1px solid;

    @include bp('sm') {
      padding: 20px;
    }

    @include bp('lg') {
      padding: 40px;
    }

    .block-embedded {
      margin-bottom: 0!important;
    }

    .block-topic__pic {
      margin: 0;
    }
  }
}


.context-inspiration {
  .embed-teaser--container {
    border:none;
  }
}
.context-poi {
  .embed-teaser--container {
    border:none;
  }
}
.context-booking {
  .embed-teaser--container {
    border:none;
  }
}
.context-highlight {
  .embed-teaser--container {
    border:none;
  }
}


@media only screen and (max-width: 768px) {
  .context-inspiration {
    .embed-teaser--container {
      border: 1px solid;
      border-color: $color-brand-inspiration;
    }
  }
  .context-poi {
    .embed-teaser--container {
      border: 1px solid;
      border-color: $color-brand-poi;
    }
  }
  .context-booking {
    .embed-teaser--container {
      border: 1px solid;
      border-color: $color-brand-booking;
    }
  }
  .context-highlight {
    .embed-teaser--container {
      border: 1px solid;
      border-color: $color-brand-highlight;
    }
  }
}
