.block-story {
  position: relative;
  background-color: $color-umbra-dark;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  color: $color-white;
  overflow: hidden;
  height: auto;

  &::before {
    padding-top: 150%;
    display: block;
    content: '';

    @include bp('sm') {
      padding-top: 66.666%;
    }

    @include bp('md') {
      padding-top: 46.875%;
    }
  }

  & + .block-story {
    margin-top: -($spacer-small-xs) !important;

    @include bp('md') {
      margin-top: -($spacer-small-md) !important;
    }
  }
}

.no-touchevents {
  .block-story {
    background-size: cover;
    background-attachment: fixed;
    backface-visibility: hidden;

    @include bp('sm') {
      height: 100vh;
    }

    &::before {
      @include bp('sm') {
        padding-top: 0;
      }
    }
  }
}

.block-story__content {
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 2rem;
  position: absolute;
  text-align: center;

  @include bp('md') {
    padding-bottom: 6rem;

  }

  p {
    @extend .b1;
    font-weight: 300;
    font-style: italic;  }

  .button {
    margin-top: 1rem;
  }
}
